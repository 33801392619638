import React from 'react';
import { useCreativesBanners } from '../CreativesBanners.js';
import { isSelectCreativesTabs, isTrendingCreativesTab } from './values.js';
import { Gtm } from '../../../../utils/gtm.js';
import { BannerRating } from './BannerRating.js';

const TriggerButton = React.memo(({ children, item }) => (
  <>
    {item.action ? (
      <div className="download-button" onClick={item.action}>
        {children}
      </div>
    ) : (
      <a className="download-button" {...item.link} target="_blank">
        {children}
      </a>
    )}
  </>
));

const BannerCard = React.memo((props) => {
  const { creative, displayUrl, displayLink } = props;

  const creativeId = creative.id;
  const rating = creative.rating;

  const { width, height } = creative;

  const { isSelected, handleSelectCreative, activeTab } = useCreativesBanners();

  const selectCreative = () => {
    handleSelectCreative(creative, isSelected(creativeId));
    Gtm.event('offers', 'Click', `banner size - ${width}x${height}`);
  };

  const bannerName = creative?.display?.split('.')?.[0] || '';

  const menu = [
    {
      key: 'copyCode',
      action: () => displayLink(creative),
      label: 'Get Code',
    },
    {
      key: 'copyUrl',
      action: () => displayUrl(creative),
      label: 'Get URL',
    },
    {
      key: 'downloadFile',
      label: 'Download',
      link: {
        download: true,
        href: creative.url,
      },
    },
  ];

  return (
    <div className={`banner-card ${isSelected(creativeId) ? 'active' : ''}`} id={`creative-card-${creative.id}`}>
      <div className="img-area" onClick={selectCreative}>
        <img src={creative.url} />
        <div className="active-cover">
          {!isSelectCreativesTabs(activeTab) ? (
            <svg viewBox="0 0 23.059 23.059" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.876,11.872l-5.6,5.6a.91.91,0,0,1-1.288,0l-2.8-2.8a.91.91,0,1,1,1.286-1.288l2.16,2.159,4.96-4.961a.911.911,0,1,1,1.288,1.288ZM14.03,2.5a11.53,11.53,0,1,0,11.53,11.53A11.543,11.543,0,0,0,14.03,2.5Z"
                fill="#ffffff"
                fillRule="evenodd"
                id="check-circle-grey"
                transform="translate(-2.5 -2.5)"
              />
            </svg>
          ) : (
            <svg height="35.173" viewBox="0 0 29.509 35.173" width="29.509" xmlns="http://www.w3.org/2000/svg">
              <g id="delete-white" transform="translate(-3.86 -2)">
                <path
                  d="M13.155,16.549a1.319,1.319,0,0,1,2.638,0v7.144a1.319,1.319,0,0,1-2.638,0Zm5.946,0a1.319,1.319,0,1,1,2.638,0v7.144a1.319,1.319,0,1,1-2.638,0ZM5.4,24.374a6.153,6.153,0,0,0,6.113,6.18H23.38a6.154,6.154,0,0,0,6.113-6.18V11.252a.528.528,0,0,0-.528-.528H5.929a.528.528,0,0,0-.528.528Z"
                  data-name="Tracé 1568"
                  fill="#fff"
                  fillRule="evenodd"
                  id="Tracé_1568"
                  transform="translate(1.169 6.619)"
                />
                <path
                  d="M29.907,6.685H25.83L24.623,4.058A3.534,3.534,0,0,0,21.416,2h-5.6a3.536,3.536,0,0,0-3.208,2.059L11.4,6.685H7.325A3.468,3.468,0,0,0,3.86,10.148v1.913a2.649,2.649,0,0,0,2.647,2.647H30.724a2.649,2.649,0,0,0,2.645-2.647V10.148A3.466,3.466,0,0,0,29.907,6.685Z"
                  data-name="Tracé 1569"
                  fill="#fff"
                  fillRule="evenodd"
                  id="Tracé_1569"
                  transform="translate(0)"
                />
              </g>
            </svg>
          )}
        </div>
      </div>
      <div className="card-bottom-box">
        <div className="top-title">
          <div className="text" onClick={selectCreative}>
            {bannerName}
          </div>
          <div className={`format ${creative.format || ''}`}>{creative.format}</div>
        </div>
        <div className="id-row">ID #{creativeId}</div>
        {isTrendingCreativesTab(activeTab) && (
          <div className="bannerRatingBox">
            <div className="bannerImpressions" />
            <BannerRating rating={rating} />
          </div>
        )}
        <div className="bannerRating" />
        <div className={`download-buttons totalButtons${menu.length}`}>
          {menu.map((item, index) => (
            <TriggerButton item={item} key={index}>
              <>
                {item.key === 'copyCode' ? (
                  <svg viewBox="0 0 14.938 12.059" xmlns="http://www.w3.org/2000/svg">
                    <g id="code-white" transform="translate(-3.25 -4.965)">
                      <path
                        d="M7.84,5.715c-3.84,0,0,5.279-3.84,5.279,3.84,0,0,5.279,3.84,5.279"
                        data-name="Tracé 1557"
                        fill="none"
                        id="Tracé_1557"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M15.428,5.715c3.84,0,0,5.279,3.84,5.279-3.84,0,0,5.279-3.84,5.279"
                        data-name="Tracé 1558"
                        fill="none"
                        id="Tracé_1558"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-1.83)"
                      />
                    </g>
                  </svg>
                ) : item.key === 'copyUrl' ? (
                  <svg viewBox="0 0 15.679 11.86" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.287,9.283a3.109,3.109,0,0,0-3.232,3.261,3.262,3.262,0,0,0,1.919,2.973,3.6,3.6,0,0,0,1.311.268H14a3.644,3.644,0,0,0,1.318-.264,3.265,3.265,0,0,0,1.916-2.977A3.109,3.109,0,0,0,14,9.283a3.858,3.858,0,1,0-7.716,0Z"
                      fill="none"
                      fillRule="evenodd"
                      id="cloud-white"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      transform="translate(-2.305 -4.674)"
                    />
                  </svg>
                ) : item.key === 'downloadFile' ? (
                  <svg viewBox="0 0 14.871 14.894" xmlns="http://www.w3.org/2000/svg">
                    <g id="download-white" transform="translate(-2.98 -2.202)">
                      <path
                        d="M9.383,20.4H9.39a6.241,6.241,0,0,0,1.44.4"
                        data-name="Tracé 1559"
                        fill="none"
                        id="Tracé_1559"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-1.459 -4.56)"
                      />
                      <path
                        d="M6.631,18.6a6.049,6.049,0,0,1-.871-.879"
                        data-name="Tracé 1560"
                        fill="none"
                        id="Tracé_1560"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-0.506 -3.855)"
                      />
                      <path
                        d="M4.192,14.765a6.455,6.455,0,0,1-.358-1.335"
                        data-name="Tracé 1561"
                        fill="none"
                        id="Tracé_1561"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(0 -2.727)"
                      />
                      <path
                        d="M4.234,8.605a6.2,6.2,0,0,0-.4,1.447"
                        data-name="Tracé 1562"
                        fill="none"
                        id="Tracé_1562"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(0 -1.459)"
                      />
                      <path
                        d="M6.741,4.973a6.919,6.919,0,0,0-.886.871"
                        data-name="Tracé 1563"
                        fill="none"
                        id="Tracé_1563"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-0.532 -0.503)"
                      />
                      <path
                        d="M10.87,3.059a6.3,6.3,0,0,0-1.335.358"
                        data-name="Tracé 1564"
                        fill="none"
                        id="Tracé_1564"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-1.499 0)"
                      />
                      <path
                        d="M14.207,3.059a6.284,6.284,0,0,1,1.441.4,6.391,6.391,0,0,1,1.806,1.089,6.139,6.139,0,0,1,.879.885,6.54,6.54,0,0,1,1.054,1.82A6.207,6.207,0,0,1,19.744,8.6a6.651,6.651,0,0,1,0,2.108,6.127,6.127,0,0,1-.4,1.434v.007a6.343,6.343,0,0,1-1.089,1.8,5.826,5.826,0,0,1-.885.879,6.6,6.6,0,0,1-1.827,1.061,6.7,6.7,0,0,1-1.335.358"
                        data-name="Tracé 1565"
                        fill="none"
                        id="Tracé_1565"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-2.728 0)"
                      />
                      <path
                        d="M12.12,13.644V8.531m0,5.112,1.995-2m-1.995,2-1.993-2"
                        data-name="Tracé 1566"
                        fill="none"
                        id="Tracé_1566"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        transform="translate(-1.655 -1.439)"
                      />
                    </g>
                  </svg>
                ) : (
                  ''
                )}
                <div>{item.label}</div>
              </>
            </TriggerButton>
          ))}
        </div>
      </div>
    </div>
  );
});

export default BannerCard;

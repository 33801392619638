import CloseModalIcon from '../../reusables/svg/Gamification/CloseModalIcon.react';
import { connect } from 'react-redux';
import ModalFooter from '../../reusables/svg/Gamification/ModalFooter.react';
import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Badge5 from './svg/badge5.react';
import Whale2 from './svg/whale2.react';
import Badge4 from './svg/badge4.react';
import Badge3 from './svg/badge3.react';
import Badge2 from './svg/badge2.react';
import Badge from './svg/badge.react';
import Whale from './svg/whale.react';
import { Gtm } from '../../../utils/gtm';
import { Info } from '../../reusables';
import Tooltip from '../../reusables/Tooltip.react';

const WeekZeroModal = React.memo(({ isImpression, gamificationBadges, dispatch, modalInner }) => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);
  const closeOpenModal = (isNotContinueBtn = true) => {
    dispatch(closeModalAnimation());
    if (isNotContinueBtn) {
      Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', 'close_0');
    } else {
      Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', 'continue_0');
    }
  };

  useClickOutside(modalInner, closeOpenModal);
  const today = new Date();
  const sunday = new Date(moment().startOf('week').toDate());
  const diffTime = Math.abs(sunday - today);
  const daysStreakThisWeek = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;

  const whaleClassName = (img) => {
    if (img.includes('2')) {
      return 'lvl-2';
    } else if (img.includes('3')) {
      return 'lvl-3';
    } else if (img.includes('4')) {
      return 'lvl-4';
    }
    return 'lvl-1';
  };

  const preloadImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(src);
      };
      img.onerror = (err) => {
        console.error(`Error preloading ${src}:`, err);
        reject(new Error(`Failed to preload ${src}`));
      };
      img.src = src;
    });

  useEffect(() => {
    if (isImpression) {
      Gtm.newEvent('gamified_ranking_modal', false, 'impression');
    }
  }, []);

  useEffect(() => {
    const imagesToPreload = [gamificationBadges[0].badgeData.whaleUrl, `/img/gamification/whale-zero-week.svg`];

    Promise.all(imagesToPreload.map((src) => preloadImage(src)))
      .then(() => {
        setIsImagesLoaded(true);
      })
      .catch((error) => console.error('Error preloading images:', error));
  }, []);

  return isImagesLoaded ? (
    <>
      <div className="header-modal">
        <button onClick={() => closeOpenModal()} type="button">
          <CloseModalIcon />
        </button>
        <Whale />
        <Badge />
        <Badge5 />
        <Whale2 />
        <Badge4 />
        <Badge3 />
        <Badge2 />
        <img className="black-whale" src="/img/gamification/whale-zero-week.svg" />
      </div>
      <div className="week-streak-section">
        <div className="rotate-title">
          <span className="first-title">AWESOME!</span>
          <span className="second-title">
            You've Started a New Streak
            <Tooltip
              closeIcon
              content={
                <p>
                  With this first connection, your streak begins. To maintain your streak, log in at least once per week.{' '}
                  <a
                    href="https://support.crakrevenue.com/knowledge-base/crakrevenue-achievement-program/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <u>Learn more</u>
                  </a>
                </p>
              }
              isHtml
              onOpen={() => Gtm.newEvent('gamified_ranking_modal', false, 'click', 'string', 'tooltip')}
              position="left"
            >
              <Info className="more-info" />
            </Tooltip>
          </span>
        </div>
        <div className="progress-bar">
          {[...Array(7).keys()].map((bar, key) => (
            <div className={`bar ${bar < daysStreakThisWeek && 'active'}`} key={key} />
          ))}
          <div className="whale-lvl right">
            <img
              className={whaleClassName(gamificationBadges[0].badgeData.whaleUrl)}
              src={gamificationBadges[0].badgeData.whaleUrl}
            />
            <span className="lvl-chip">Lvl 1</span>
          </div>
        </div>
        <p>
          Remember to log in at least once a week to keep the streak alive. Doing so will not only help you reach your next badge
          but also keep you in the loop for maximum opportunities.
        </p>
      </div>
      <button className="btn-large btn gamification-btn" onClick={() => closeOpenModal(false)}>
        Continue
      </button>
      <ModalFooter />
    </>
  ) : null;
});

WeekZeroModal.displayName = 'WeekZeroModal';

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affInfos: state.profile.data.affInfos,
  gamificationEventsStats: state.gamification.eventsData.notification.stats,
  gamificationEventsNotif: state.gamification.eventsData.notification,
  gamificationAffProgress: state.gamification.eventsData.notification.affiliateProgress,
  gamificationBadges: state.gamification.badgeData,
}))(WeekZeroModal);

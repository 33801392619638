import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

const OfferTag = (props) => {
  const { classNames, Icon, id, messageKey } = props;

  return (
    <div className={['offer-tag', ...classNames].join(' ')} id={id}>
      <FormattedMessage {...messages[messageKey]} />
      <Icon />
    </div>
  );
};

OfferTag.defaultProps = {
  classNames: [],
};

OfferTag.propTypes = {
  Icon: PropTypes.object.isRequired,
  classNames: PropTypes.array,
  id: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
};

export default OfferTag;

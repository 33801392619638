const Cam = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 158"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_158"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="cam" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 155" fill="none" height="30" id="Rectangle_155" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 95" fill="none" id="Ellipse_95" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 189" fill="none" id="Ligne_189" x2="30" y2="30" />
          <line data-name="Ligne 190" fill="none" id="Ligne_190" x1="30" y2="30" />
          <line data-name="Ligne 191" fill="none" id="Ligne_191" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 192" fill="none" id="Ligne_192" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 193" fill="none" id="Ligne_193" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 194" fill="none" id="Ligne_194" transform="translate(15)" y2="30" />
          <line data-name="Ligne 195" fill="none" id="Ligne_195" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 196" fill="none" id="Ligne_196" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 156"
            fill="none"
            height="21.218"
            id="Rectangle_156"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 96"
            fill="none"
            id="Ellipse_96"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 157"
            fill="none"
            height="15.004"
            id="Rectangle_157"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 97"
            fill="none"
            id="Ellipse_97"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <circle
            cx="8.918"
            cy="8.918"
            data-name="Ellipse 98"
            fill="none"
            id="Ellipse_98"
            r="8.918"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(6.082 4.791)"
          />
          <circle
            cx="3.958"
            cy="3.958"
            data-name="Ellipse 99"
            fill="none"
            id="Ellipse_99"
            r="3.958"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(11.042 9.751)"
          />
          <circle
            cx="2.199"
            cy="2.199"
            data-name="Ellipse 100"
            fill="none"
            id="Ellipse_100"
            r="2.199"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(12.801 11.51)"
          />
          <path
            d="M15.054,16.226l1.18,3.082H7.969l1.18-3.082"
            data-name="Tracé 1514"
            fill="none"
            id="Tracé_1514"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(2.898 5.901)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Cam;

import { useCallback, useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Select } from 'react-materialize';
import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper';
import messages from '../../../i18n/base-en';
import { countryAlphaCode2ToAlphaCode3, countryAlphaCode3ToAlphaCode2 } from '../../../utils/list-options/countriesIsoCode3';
import AutoSuggestionAddressReact from '../AutoSuggestionAddress.react';
import { getCountryLabelDefaultMessage } from '../../../utils/list-options/countries';
import { buildAddressLabel, getAddressPartsFromAWSPlaceData } from '../../../utils/addressHelper';
import SelectCustom from '../../../components/reusables/form/SelectCustom';

function UserInfoAutoSuggestionAddress({
  countries,
  fields,
  fields: { country },
  intl,
  onChangeCountry,
  setIsAddressModeSearchTool,
  setIsFetchingAddressData,
  setIsSuggestedAddressComplete,
}) {
  const [suggestionMaxResults, setSuggestionMaxResults] = useState(5);
  const [suggestionInputTimer, setSuggestionInputTimer] = useState(250);
  const [suggestionMinimumInput, setSuggestionMinimumInput] = useState(3);
  const [addressCategoryTypes, setAddressCategoryTypes] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let param = searchParams.get('suggestionMinimumInput');
    if (param) {
      setSuggestionMinimumInput(param);
    }
    param = searchParams.get('suggestionInputTimer');
    if (param) {
      setSuggestionInputTimer(param);
    }
    param = searchParams.get('suggestionMaxResults');
    if (param) {
      setSuggestionMaxResults(param);
    }
  }, []);

  useEffect(() => {
    if (addressCategoryTypes?.length) {
      setIsSuggestedAddressComplete(addressCategoryTypes.find((e) => e === 'AddressType') !== undefined);
    }
    if (addressCategoryTypes === undefined) {
      setIsAddressModeSearchTool(false);
    }
  }, [addressCategoryTypes, setIsSuggestedAddressComplete]);

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getProfileExcludeCountries()
  );

  const countryIsoCode3 = countryAlphaCode2ToAlphaCode3(country.value === 'UK' ? 'GB' : country.value);
  const suggestionFilters = {};
  if (countryIsoCode3) {
    suggestionFilters.FilterCountries = [countryIsoCode3];
  }

  const suggestionParams = {
    inputTimer: suggestionInputTimer,
    language: 'en',
    maxResults: suggestionMaxResults,
    minimumInput: suggestionMinimumInput,
  };

  const mapPlaceAddressToFields = useCallback(
    (place) => {
      if (place) {
        const { address, city, region, postalCode, unitNumber } = getAddressPartsFromAWSPlaceData(place, fields.country.value);

        fields.address.onChange(address);
        fields.city.onChange(city);
        fields.zipcode.onChange(postalCode);
        fields.region.onChange(region);
        fields.province.onChange(region);
        fields.state.onChange(region);
        fields.address2.onChange(unitNumber);
        const displayCountry = getCountryLabelDefaultMessage(countryAlphaCode3ToAlphaCode2(place?.Country));
        const addressLabel = buildAddressLabel({
          address,
          city,
          region,
          postalCode,
          country: displayCountry,
        });
        fields.address_label.onChange(addressLabel);
        fields.autocompleted_address_label.onChange(addressLabel);
        return addressLabel;
      }
    },
    [fields]
  );

  return (
    <>
      <div className="customSelects">
        <div className="fields" data-cy="select-wrapper-country">
          <SelectCustom
            id="country"
            label={messages.genericTextCountry.description}
            onChange={onChangeCountry}
            tabIndex={150}
            value={country.value || ''}
            valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            showSelectedFieldCheckmark={false}
            options={filteredCountries.map((country, index) => ({
              value: country.code,
              text: country.name,
            }))}
          />
        </div>
      </div>

      {country.value ? (
        <AutoSuggestionAddressReact
          address_label_field={fields.address_label}
          countryFullName={getCountryLabelDefaultMessage(country.value)}
          mapPlaceAddressToFields={mapPlaceAddressToFields}
          setAddressCategoryTypes={setAddressCategoryTypes}
          setIsAddressModeSearchTool={setIsAddressModeSearchTool}
          setIsFetchingAddressData={setIsFetchingAddressData}
          suggestionFilters={suggestionFilters}
          suggestionParams={suggestionParams}
        />
      ) : null}
    </>
  );
}

UserInfoAutoSuggestionAddress.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  setIsAddressModeSearchTool: PropTypes.func,
  setIsFetchingAddressData: PropTypes.func,
  setIsSuggestedAddressComplete: PropTypes.func,
};

export default injectIntl(UserInfoAutoSuggestionAddress);

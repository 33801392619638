import { Link } from 'react-router';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import messages from '../../../i18n/base-en.js';

import RankElite from '../../../components/reusables/svg/RankElite.react';
import LinkIcon from '../../../components/reusables/svg/LinkIcon.react';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

const getHomeNotRankedActionCaptionsSmartLinkText = (skin) =>
  skin.getPropertyValue('HomeRankingNotRanked', 'smartLink', 'text') ||
  'SmartLinks are the perfect Set & Forget tools for any Affiliate.';

const getHomeNotRankedActionCaptionsMasterClassText = (skin) =>
  skin.getPropertyValue('HomeRankingNotRanked', 'masterClass', 'text') ||
  'Why not start with our 7 days Masterclass and avoid unnecessary stress!';

const HomeRankingNotRanked = ({ skinConfigurations }) => {
  const skin = new SkinConfigurationsUtils(skinConfigurations);
  const homeNotRankedActionCaptionsSmartLinkText = getHomeNotRankedActionCaptionsSmartLinkText(skin);
  const homeNotRankedActionCaptionsMasterClassText = getHomeNotRankedActionCaptionsMasterClassText(skin);

  const notRankedActions = [
    {
      icon: <LinkIcon />,
      caption: homeNotRankedActionCaptionsSmartLinkText,
      cta: messages.homeNotRankedActionCTAs.smartLink,
      link: '/smartlink',
    },
    {
      icon: <RankElite />,
      caption: homeNotRankedActionCaptionsMasterClassText,
      cta: messages.homeNotRankedActionCTAs.masterclass,
      externalLink: 'https://www.crakrevenue.com/blog/affiliate-masterclass/',
    },
  ];

  return (
    <div className="ranking-inner not-ranked">
      <h3 className="not-ranked-title">
        <small>
          <FormattedMessage {...messages.homeNotRankedSubtitle} />
        </small>
        <FormattedMessage {...messages.homeNotRankedTitle} />
      </h3>
      <ul className="actions">
        {notRankedActions.map((action) => {
          const linkContents = (
            <div className="actions-item">
              <div className="action-icon">{action.icon}</div>
              <div className="action-caption">
                {action.caption}
                <span className="more primary-color">
                  <FormattedHTMLMessage {...action.cta} />
                </span>
              </div>
            </div>
          );
          return (
            <li key={uuidv4()}>
              {!!action.link && (
                <Link onlyActiveOnIndex={false} to={action.link}>
                  {linkContents}
                </Link>
              )}
              {!!action.externalLink && (
                <a href={action.externalLink} rel="noreferrer" target="_blank">
                  {linkContents}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HomeRankingNotRanked;

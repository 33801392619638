import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

const PaymentDateHistory = ({ paymentHistory, temporalMoment }) => {
  if (temporalMoment === 'last') {
    if (paymentHistory.last.amount === '0.00') {
      return <div className="payment-date-history" />;
    }
    return (
      <div className="payment-date-history">
        <FormattedMessage {...messages.genericTextLastPayment} />
        {paymentHistory.asReceivedPayment ? <span className="amount">${paymentHistory.last.amount}</span> : null}
        {paymentHistory.asReceivedPayment ? <span>{paymentHistory.last.date}</span> : null}
      </div>
    );
  }

  return (
    <div className="payment-date-history">
      <FormattedMessage {...messages.genericTextNextPayment} />
      {paymentHistory.asReachedMinimumPayout ? <span className="amount">${paymentHistory.next.amount}</span> : null}
      {paymentHistory.asReachedMinimumPayout ? <span>{paymentHistory.next.date}</span> : null}
      {!paymentHistory.asReachedMinimumPayout && (
        <FormattedMessage className="test" {...messages.profileNotReachedMinPayoutInPaymentHistory} />
      )}
    </div>
  );
};

PaymentDateHistory.propTypes = {
  paymentHistory: PropTypes.object.isRequired,
  temporalMoment: PropTypes.string.isRequired,
};

export default PaymentDateHistory;

import PropTypes from 'prop-types';

import messages from '../../i18n/base-en.js';

import Skype from '../../components/reusables/svg/Skype.react';
import Whale from '../../components/reusables/svg/Whale.react';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import { Gtm } from '../../utils/gtm';

const getHomeYourAffManagerText = (skin) =>
  skin.getPropertyValue('HomeAccountManager', 'affManager', 'text') || 'Your Affiliate Manager';

const HomeAccountManager = ({ affiliateManager, className, isVisible, menuLeft, skinConfigurations }) => {
  const defaultEmail = 'support@crakrevenue.com';
  const defaultName = 'Papa Whale';
  const defaultImagePath = '/img/logo-cr_papa_whale_medium.png';

  const email = 'email' in affiliateManager ? affiliateManager.email : defaultEmail;
  const name = 'first_name' in affiliateManager ? `${affiliateManager.first_name} ${affiliateManager.last_name}` : defaultName;
  const phone = 'cell_phone' in affiliateManager && affiliateManager.cell_phone;
  const image =
    'photo' in affiliateManager
      ? {
          url: affiliateManager.photo || defaultImagePath,
          class: '',
        }
      : { url: defaultImagePath, class: 'bg-primary-color' };

  const showDefaultImage = (e) => {
    e.target.src = defaultImagePath;
    e.target.className = 'bg-primary-color';
  };

  const skin = new SkinConfigurationsUtils(skinConfigurations);
  const homeYourAffManagerText = getHomeYourAffManagerText(skin);

  return (
    <div className={className} id="home-account-manager">
      <div className="row">
        <div className="image-container">
          <span className="image">
            <div className="image-tag">
              <img alt="Account Manager" className={image.class} onError={(e) => showDefaultImage(e)} src={image.url} />
            </div>
            {isVisible.whale ? (
              <div className="whale bg-primary-color">
                <Whale />
              </div>
            ) : null}
          </span>
        </div>
        <div className="col-xs-12 infos">
          <div className="name">
            <span>{name}</span>
          </div>
          <div className="your-aff">{homeYourAffManagerText}</div>
          {!menuLeft && <div className="get-in-touch">{messages.homeGetInTouch.description}</div>}
          {menuLeft && phone ? (
            <div className="phone">
              <span>
                <label>Phone: </label>
                <a href={`tel:${phone}`}>
                  {!menuLeft && <i className="material-icons">email</i>} {email}
                </a>
              </span>
            </div>
          ) : null}
          <div className="email">
            <span>
              {menuLeft ? <label>Email: </label> : null}
              <a
                href={`mailto:${email}`}
                onClick={() => {
                  Gtm.event('dashboard', 'Click', `get in touch email`);
                }}
              >
                {!menuLeft && <i className="material-icons">email</i>} {email}
              </a>
            </span>
          </div>
          {'aim' in affiliateManager && affiliateManager.aim !== null && affiliateManager.aim.length > 0 && (
            <div className="skype">
              <span>
                {menuLeft ? <label>Skype: </label> : null}
                <a href={`skype:${affiliateManager.aim}?chat`}>
                  {!menuLeft && <Skype />}
                  {affiliateManager.aim}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HomeAccountManager.propTypes = {
  affiliateManager: PropTypes.object.isRequired,
  className: PropTypes.string,
  isVisible: PropTypes.any.isRequired,
  menuLeft: PropTypes.any,
  skinConfigurations: PropTypes.object.isRequired,
};

export default HomeAccountManager;

export default {
  tellUsAboutYouHi: {
    id: 'tellUsAboutYou.TellUsAboutYouTitle',
    description: 'Hi',
    defaultMessage: 'Hi',
  },
  tellUsAboutYouTitle: {
    id: 'tellUsAboutYou.TellUsAboutYouTitle',
    description: 'tell us about you',
    defaultMessage: 'tell us about you',
  },
  tellUsWhichVertical: {
    id: 'tellUsAboutYou.TellUsWhichVertical',
    description: 'Please pick your top vertical(s)',
    defaultMessage: 'Please pick your top vertical(s)',
  },
  tellUsYourRole: {
    id: 'tellUsAboutYou.Role',
    description: 'What is your role ?',
    defaultMessage: 'What is your role ?',
  },
  tellUsYourRoleWebmaster: {
    id: 'tellUsAboutYou.Webmaster',
    description: 'Your top website URL(s)',
    defaultMessage: 'Your top website URL(s)',
  },
  tellUsYourRoleSocial: {
    id: 'tellUsAboutYou.Social',
    description: 'Your top social account URL(s)',
    defaultMessage: 'Your top social account URL(s)',
  },
  tellUsMore: {
    id: 'tellUsAboutYou.TellUsMore',
    description: 'Anything else you’d like to add to get approved faster?',
    defaultMessage: 'Anything else you’d like to add to get approved faster?',
  },
  tellUsYourRoleMediaBuyer: {
    id: 'tellUsAboutYou.MediaBuyer',
    description: 'Your monthly budget',
    defaultMessage: 'Your monthly budget',
  },
  tellUsYourRoleNetwork: {
    id: 'tellUsAboutYou.Network',
    description: 'Your network’s website URL',
    defaultMessage: 'Your network’s website URL',
  },
  tellUsVisitorsRegion: {
    id: 'tellUsAboutYou.TellUsVisitorsRegion',
    description: 'What are the top countries you promote offers in?',
    defaultMessage: 'What are the top countries you promote offers in?',
  },
  tellUsWhichOffer: {
    id: 'tellUsAboutYou.TellUsWhichOffer',
    description: 'Which offer do you refer to?',
    defaultMessage: 'Which offer do you refer to?',
  },
  tellUsSkypeId: {
    id: 'tellUsAboutYou.TellUsSkypeId',
    description: 'Your skype ID',
    defaultMessage: 'Your skype ID',
  },
  tellUsPhoneNumber: {
    id: 'tellUsAboutYou.TellUsPhoneNumber',
    description: 'Your phone number',
    defaultMessage: 'Your phone number',
  },
  tellUsHowYouHear: {
    id: 'tellUsAboutYou.TellUsHowYouHear',
    description: 'How did you hear about us ?',
    defaultMessage: 'How did you hear about us ?',
  },
  tellUsCountry: {
    id: 'tellUsAboutYou.tellUsCountry',
    description: 'What is your country of residence',
    defaultMessage: 'What is your country of residence',
  },
  tellUsSearchingParticularOffer: {
    id: 'tellUsAboutYou.TellUsParticularOffer',
    description: 'Am I looking for specific offers?',
    defaultMessage: 'Am I looking for specific offers?',
  },
  tellUsPromoCode: {
    id: 'tellUsAboutYou.TellUsPromoCode',
    description: 'Promo Code',
    defaultMessage: 'Promo Code',
  },
  tellUsOtherNetworks: {
    id: 'tellUsAboutYou.tellUsOtherNetworks',
    description: 'What other Network have you worked with?',
    defaultMessage: 'What other Network have you worked with?',
  },
  tellUsaffiliateAchievements: {
    id: 'tellUsAboutYou.tellUsaffiliateAchievements',
    description: 'Tell us your achievements as an affiliate',
    defaultMessage: 'Tell us your achievements as an affiliate',
  },
  tellUsJoinCrakRevenueNow: {
    id: 'tellUsAboutYou.TellUsJoinCrakRevenueNow',
    description: 'Join CrakRevenue Now',
    defaultMessage: 'Join {site} Now',
  },
};

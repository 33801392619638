const FansRevenue = () => (
  <svg data-name="Calque 2" id="Calque_2" viewBox="0 0 105.87 108.19" xmlns="http://www.w3.org/2000/svg">
    <g data-name="Calque 1" id="Calque_1-2">
      <g>
        <path d="m77.42,108.19c-.26,0-.52,0-.78,0h-.02s-49.75-1.45-49.75-1.45c-7.37-.01-14.18-2.86-19.19-8.01-4.95-5.11-7.68-12-7.68-19.42V29.08c0-7.39,2.9-14.3,8.16-19.43C13.36,4.56,20.29,1.76,27.68,1.74l51.7-1.74.2.02c16.7,1.58,26.28,12.17,26.28,29.06l-.16,51.84c0,7.57-2.82,14.51-7.93,19.51-5.14,5.03-12.34,7.77-20.35,7.77Zm-.63-6.39c6.69.15,12.39-1.91,16.52-5.94,3.88-3.79,6.01-9.11,6.01-14.96l.16-51.84c0-9.43-3.53-21-20.3-22.67l-51.45,1.73c-5.74,0-11.11,2.16-15.13,6.08-4.02,3.93-6.23,9.21-6.23,14.87v50.22c0,5.75,2.09,11.07,5.88,14.98,3.8,3.92,9,6.07,14.65,6.07h.09s49.79,1.46,49.79,1.46Z" />
        <path d="m53.08,68.6h-18.82s-1.94.2-2.25-2.25c-.3-2.45-3.67-28.59-3.67-28.59,0,0-.25-1.1.85,0,1.1,1.1,14.16,14.95,14.16,14.95,0,0,.53.59.84,0s8.49-19.07,8.49-19.07c0,0,.13-.39.36-.39h.05c.23,0,.36.39.36.39,0,0,8.18,18.47,8.49,19.07s.84,0,.84,0c0,0,13.06-13.85,14.16-14.95,1.1-1.1.85,0,.85,0,0,0-3.36,26.14-3.67,28.59-.3,2.45-2.25,2.25-2.25,2.25h-18.82" />
        <g>
          <path d="m53.08,72.48h-19.6s-.35-.08-.35.28.14,1.3.51,1.7.89.62,2.3.62h17.13" />
          <path d="m53.08,72.48h19.6s.35-.08.35.28-.14,1.3-.51,1.7-.89.62-2.3.62c-1.41,0-17.13,0-17.13,0" />
        </g>
      </g>
    </g>
  </svg>
);

export default FansRevenue;

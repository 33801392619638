/* eslint-disable react/no-children-prop */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { HomeAccountManager } from '../../../pages/home';
import MainNavigationTab from './MainNavigationTab/MainNavigationTab';

import { resetOffer } from '../../../pages/offer/Offer-Actions/Offer-Actions';
import { changeMainMenuState } from '../../Application/actions/application-actions';

import config from '../../../config';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

import AffiliateResources from '../../reusables/svg/MenuIcons/AffiliateResources';
import ArrowRight from '../../reusables/svg/MenuIcons/ArrowRight';
import Cam from '../../reusables/svg/MenuIcons/Cam';
import Community from '../../reusables/svg/MenuIcons/Community';
import Dashboard from '../../reusables/svg/MenuIcons/Dashboard';
import Dating from '../../reusables/svg/MenuIcons/Dating';
import AI from '../../reusables/svg/MenuIcons/AI';
import KnowledgeBase from '../../reusables/svg/MenuIcons/KnowledgeBase';
import LiveCamWidget from '../../reusables/svg/MenuIcons/LiveCamWidget';
import PaymentHistory from '../../reusables/svg/MenuIcons/PaymentHistory';
import Profile from '../../reusables/svg/MenuIcons/Profile';
import Smartlink from '../../reusables/svg/MenuIcons/Smartlink';
import Statistics from '../../reusables/svg/MenuIcons/Statistics';
import Logout from '../../reusables/svg/MenuIcons/Logout';
import SurveyMachine from '../../reusables/svg/MenuIcons/SurveyMachine';
import Referrals from '../../reusables/svg/MenuIcons/Referrals';
import JerkmateIcon from '../../reusables/svg/MenuIcons/JerkmateIcon';
import JerkmateNewSticker from '../../reusables/svg/Jerkmate/JerkmateNewSticker';
import { offerHasNewTag } from '../../Jerkmate/helper';
import {
  dispatchsetOffersFromMenuClick,
  dispatchsetSeeAllOffersFromMenuClick,
  setOffersSearchFilters,
} from '../../Offers/actions/offers-actions';
import { JERKMATE_OFFER_TAG } from '../../../pages/jerkmate2/JerkmateOfferProvider';
import Onlyfans from '../../reusables/svg/MenuIcons/OnlyFans';
import NewIcon from '../../reusables/svg/MenuIcons/NewIcon';
import FansRevenue from '../../reusables/svg/MenuIcons/FansRevenue';
import consts from '../../../utils/consts';
import PreRollAds from '../../reusables/svg/MenuIcons/PreRollAds';
import { USE_VAST_PREROLL, VAST_PREROLL_PATH } from '../../../pages/vast-pre-roll/values';
import TrustBox from './TrustpilotBox.react';

const MainNavigation = ({
  affiliateManager,
  handleLogout,
  isConnected,
  mainMenuIsOpen,
  skinConfigurations,
  dispatch,
  allOffers,
  jerkmateOffers,
  offersIsFetching,
  search,
}) => {
  const { AFFILIATE_MANAGER_DEFAULT_ID, VERTICALS_SUB_MENU } = config;
  const SKIN = SkinConfigurationsUtils.getSkinName(skinConfigurations);
  const MENU_ITEMS = SkinConfigurationsUtils.getMenuItems(SKIN);
  const location = window.location.toString();
  const isDisabled = isConnected ? undefined : 'disabled';
  const classNames = ['z-depth-1', mainMenuIsOpen ? 'open' : undefined, isDisabled];

  const [jerkmateOffersCount, setJerkmateOffersCount] = useState(0);

  useEffect(() => {
    let count = 0;

    jerkmateOffers.forEach((offer) => {
      const { tags } = offer;

      const taggedOffer = tags.find(({ name }) => name === JERKMATE_OFFER_TAG);

      if (taggedOffer) {
        count++;
      }
    });

    setJerkmateOffersCount(count);
  }, [jerkmateOffers]);

  const nbCamOffers = [];
  const nbOnlyfansOffers = [];
  const nbDatingOffers = [];
  const nbAIOffers = [];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        dispatch(changeMainMenuState(true));
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (allOffers.length > 0) {
    allOffers.map((offer) => {
      offer.verticals.map((verticals) => {
        switch (verticals.id) {
          case VERTICALS_SUB_MENU.cam:
            nbCamOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.onlyFans:
            nbOnlyfansOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.dating:
            nbDatingOffers.push(offer);
            break;
          case VERTICALS_SUB_MENU.ai:
            nbAIOffers.push(offer);
            break;
        }
      });
    });
  }

  const seeAllScrollTo = () => {
    setTimeout(() => {
      document.getElementById('offers-list').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }, 1000);
  };

  const changeMenuState = () => {
    const pageWrap = document.getElementById('page-wrap');
    if (location.includes('jerkmate')) {
      const scrollY = pageWrap.style.top;
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      if (!mainMenuIsOpen) {
        pageWrap.style.top = `-${window.scrollY}px`;
        pageWrap.style.position = 'fixed';
      } else {
        pageWrap.style.position = '';
        pageWrap.style.top = '';
      }
    }
  };

  const setOffersFromMenuClick = (verticals) => {
    if (offersIsFetching) {
      return;
    }

    search = {
      ...consts.OFFERS_FIND_ALL,
      filters: {
        ...(search.filters = {
          Vertical: verticals,
          Id: [],
        }),
      },
    };
    dispatch(dispatchsetOffersFromMenuClick(verticals));
    dispatch(setOffersSearchFilters(search));
    dispatch(resetOffer());
    dispatch(dispatchsetSeeAllOffersFromMenuClick(verticals.length === 0));

    if (window.innerWidth <= 992) {
      dispatch(changeMainMenuState(!mainMenuIsOpen));
      changeMenuState();
    }
  };

  const generateStatusManager = () =>
    new SkinConfigurationsUtils(skinConfigurations).getPropertyValue('Menu', 'statusManagerLabel', 'statusManager') ||
    'Your Affiliate Manager';

  const verticalHasNewOffers = (offerLists, isJerkmate = false) =>
    offerLists && offerLists.reduce((acc, offer) => acc || offerHasNewTag(offer), false) ? (
      isJerkmate ? (
        <JerkmateNewSticker />
      ) : (
        <NewIcon />
      )
    ) : null;

  return (
    <>
      <div className={`mainNavigation ${classNames.join(' ')}`} id="main-navigation">
        <nav>
          <div className="scrollable">
            <ul className="mainList">
              {MENU_ITEMS.DASHBOARD_IS_VISIBLE ? (
                <li>
                  <ul>
                    <li className="mainItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-dashboard"
                        onlyActiveOnIndex
                        to="/"
                      >
                        <MainNavigationTab children={<Dashboard />} title="Dashboard" />
                      </Link>
                    </li>
                    <li className="mainItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-statistics"
                        onlyActiveOnIndex={false}
                        to="/statistics"
                      >
                        <MainNavigationTab children={<Statistics />} title="Statistics" />
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {MENU_ITEMS.PROFILE_IS_VISIBLE ? (
                <li>
                  <span className="sectionHeader">account</span>
                  <ul>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-payment-history"
                        onlyActiveOnIndex={false}
                        to="payment-history"
                      >
                        <MainNavigationTab children={<PaymentHistory />} title="Payment History" />
                      </Link>
                    </li>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-account"
                        onlyActiveOnIndex={false}
                        to="/profile"
                      >
                        <MainNavigationTab children={<Profile />} title="Profile" />
                      </Link>
                    </li>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-account"
                        onlyActiveOnIndex={false}
                        to="/referrals"
                      >
                        <MainNavigationTab children={<Referrals />} title="Referrals" />
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {MENU_ITEMS.OFFERS_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <Link
                    className={`${isDisabled} sectionHeader`}
                    id="main-navigation-offers"
                    onClick={() => setOffersFromMenuClick([])}
                    onlyActiveOnIndex={false}
                    to="/offers"
                  >
                    <MainNavigationTab children={<ArrowRight />} title="Offers" />
                  </Link>
                  <ul>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active-pink"
                        className={`${isDisabled} jerkmate-nav`}
                        id="main-navigation-jerkmate"
                        onClick={() => changeMenuState()}
                        onlyActiveOnIndex={false}
                        to="/jerkmate"
                      >
                        <MainNavigationTab
                          children={<JerkmateIcon />}
                          newIcon={verticalHasNewOffers(jerkmateOffers, true)}
                          title={`Jerkmate (${jerkmateOffersCount})`}
                        />
                      </Link>
                    </li>
                    <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.cam])}>
                      <Link
                        activeClassName="active"
                        className={`${isDisabled} offers-nav`}
                        id="main-navigation-offers"
                        onlyActiveOnIndex={false}
                        to={`/offers?vertical=${VERTICALS_SUB_MENU.cam}`}
                      >
                        <MainNavigationTab
                          children={<Cam />}
                          newIcon={verticalHasNewOffers(nbCamOffers)}
                          title={`Cam (${nbCamOffers.length})`}
                        />
                      </Link>
                    </li>
                    <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.dating])}>
                      <Link
                        activeClassName="active"
                        className={`${isDisabled} offers-nav`}
                        id="main-navigation-offers"
                        onlyActiveOnIndex={false}
                        to={`/offers?vertical=${VERTICALS_SUB_MENU.dating}`}
                      >
                        <MainNavigationTab
                          children={<Dating />}
                          newIcon={verticalHasNewOffers(nbDatingOffers)}
                          title={`Dating (${nbDatingOffers.length})`}
                        />
                      </Link>
                    </li>
                    <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.ai])}>
                      <Link
                        activeClassName="active"
                        className={`${isDisabled} offers-nav`}
                        id="main-navigation-offers"
                        onlyActiveOnIndex={false}
                        to={`/offers?vertical=${VERTICALS_SUB_MENU.ai}`}
                      >
                        <MainNavigationTab
                          children={<AI />}
                          newIcon={verticalHasNewOffers(nbAIOffers)}
                          title={`AI (${nbAIOffers.length})`}
                        />
                      </Link>
                    </li>
                    <li className="sectionItem" onClick={() => setOffersFromMenuClick([VERTICALS_SUB_MENU.onlyFans])}>
                      <Link
                        activeClassName="active"
                        className={`${isDisabled} onlyfans-nav`}
                        id="main-navigation-offers"
                        onlyActiveOnIndex={false}
                        to={`/offers?vertical=${VERTICALS_SUB_MENU.onlyFans}`}
                      >
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <MainNavigationTab
                          children={<Onlyfans />}
                          newIcon={verticalHasNewOffers(nbOnlyfansOffers)}
                          title={`OnlyFans (${nbOnlyfansOffers.length})`}
                        />
                      </Link>
                    </li>
                    <li
                      className="seeAllOffers"
                      onClick={() => {
                        setOffersFromMenuClick([]);
                        seeAllScrollTo();
                      }}
                    >
                      <Link
                        activeClassName={location.includes('seeAll') ? 'active' : ''}
                        className={isDisabled}
                        id="main-navigation-offers"
                        onlyActiveOnIndex={false}
                        to="/offers#seeAll"
                      >
                        <MainNavigationTab title={`See all (${allOffers.length})`} />
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {MENU_ITEMS.PROMOTOOLS_IS_VISIBLE ? (
                <li className="sub-menu-handle">
                  <Link
                    className={`${isDisabled} sectionHeader`}
                    id="main-navigation-promotools"
                    onlyActiveOnIndex={false}
                    to="/promotools"
                  >
                    <MainNavigationTab children={<ArrowRight />} title="Promo Tools" />
                  </Link>
                  <ul>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-smartlinks"
                        onlyActiveOnIndex={false}
                        to="/smartlink"
                      >
                        <MainNavigationTab children={<Smartlink />} title="Smartlinks" />
                      </Link>
                    </li>
                    <li className="sectionItem">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-live-cam-widget"
                        onlyActiveOnIndex={false}
                        to="/live-cam-widget"
                      >
                        <MainNavigationTab children={<LiveCamWidget />} title="Live Cam Widget" />
                      </Link>
                    </li>
                    {USE_VAST_PREROLL ? (
                      <li className="sectionItem">
                        <Link
                          activeClassName="active"
                          className={isDisabled}
                          id="main-navigation-pre-roll-ads"
                          onlyActiveOnIndex={false}
                          to={`/${VAST_PREROLL_PATH}`}
                        >
                          <MainNavigationTab children={<PreRollAds />} title="Pre-Roll Ads" />
                        </Link>
                      </li>
                    ) : null}
                    <li className="seeAllPromotools">
                      <Link
                        activeClassName="active"
                        className={isDisabled}
                        id="main-navigation-promotools"
                        onlyActiveOnIndex={false}
                        to="promotools"
                      >
                        <MainNavigationTab title="See All Promo Tools" />
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {!!affiliateManager && affiliateManager.id !== JSON.stringify(AFFILIATE_MANAGER_DEFAULT_ID) && (
                <ul>
                  <span className="sectionHeader">support</span>
                  <li className="mainItem">
                    <HomeAccountManager
                      affiliateManager={affiliateManager}
                      className="menu-left"
                      isVisible={[]}
                      menuLeft
                      skinConfigurations={skinConfigurations}
                      statusManager={generateStatusManager()}
                    />
                  </li>
                  <li className="mainItem trust-pilot">
                    <TrustBox />
                  </li>
                </ul>
              )}
              {MENU_ITEMS.KNOWLEDGE_BASE_IS_VISIBLE ? (
                <li>
                  <span className="sectionHeader">other</span>
                  <ul className="last-list">
                    <li className="sectionItem">
                      <a
                        className={isDisabled}
                        href="https://www.reddit.com/r/CrakRevenue/"
                        id="main-navigation-reddit"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <MainNavigationTab children={<Community />} title="Community" />
                      </a>
                    </li>
                    <li className="sectionItem">
                      <a
                        className={isDisabled}
                        href="https://www.crakrevenue.com/affiliate/affiliate-marketing-tools/"
                        id="main-navigation-affiliate-resources"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <MainNavigationTab children={<AffiliateResources />} title="Affiliate Resources" />
                      </a>
                    </li>
                    <li className="sectionItem">
                      <a
                        className={isDisabled}
                        href="https://support.crakrevenue.com/"
                        id="main-navigation-knowledge-base"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <MainNavigationTab children={<KnowledgeBase />} title="Knowledge Base" />
                      </a>
                    </li>
                    <li className="sectionItem">
                      <a
                        className={`${isDisabled} fansrevenue`}
                        href="https://www.fansrevenue.com/?utm_source=crakrevenue&utm_medium=referral&utm_campaign=menulink&r=189324"
                        id="main-navigation-fansrevenue"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <MainNavigationTab children={<FansRevenue />} title="Creator’s Platform" />
                      </a>
                    </li>
                    {window.innerWidth <= 992 && (
                      <li className="sectionItem">
                        <a href="/" id="main-navigation-logout" onClick={handleLogout}>
                          <MainNavigationTab children={<Logout />} title="Logout" />
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              ) : null}
            </ul>
          </div>
        </nav>
      </div>
      {window.innerWidth <= 992 && mainMenuIsOpen ? (
        <div
          className="main-nav-dimmer"
          onClick={() => {
            dispatch(changeMainMenuState(false));
            const scrollY = document.getElementById('page-wrap').style.top;
            document.getElementById('page-wrap').style.position = '';
            document.getElementById('page-wrap').style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
          }}
        />
      ) : null}
    </>
  );
};

MainNavigation.propTypes = {
  affiliateManager: PropTypes.any,
  dispatch: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  mainMenuIsOpen: PropTypes.bool.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affiliateManager: state.profile.data.affiliateManager,
  skinConfigurations: state.skinConfigurations.data,
  allOffers: state.application.lists.allOffers,
  jerkmateOffers: state.jerkmate.offers,
  offersIsFetching: state.offers.offersIsFetching,
  search: state.offers.search,
}))(MainNavigation);

import React from 'react';
import { useOffersFilterContext } from './OfferFilterProvider';
import { MAIN_DROPDOWN_NAME, largeCtaTypes } from './values';

const LargeCta = React.memo(({ text, largeCtaTpe }) => {
  const { activateOuterBox, activeFilters } = useOffersFilterContext();

  let totalFilters = 0;

  const mainFilterCta = largeCtaTpe === largeCtaTypes.mainFilter;

  for (const key in activeFilters) {
    const filterCount = activeFilters[key]?.length;
    totalFilters = totalFilters + filterCount;
  }

  const setOuterBox = (e) => {
    e.stopPropagation();

    const field = mainFilterCta ? MAIN_DROPDOWN_NAME : '';
    activateOuterBox({ field });
  };

  return (
    <div className="largeCta" onClick={setOuterBox}>
      {text}
      {mainFilterCta && totalFilters ? ` (${totalFilters})` : ''}

      {largeCtaTpe === largeCtaTypes.mainFilter && (
        <svg className="filterIcon" xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.511" viewBox="0 0 19.5 19.511">
          <g id="filter" transform="translate(-2.25 -2.25)">
            <g id="Filter-2" data-name="Filter" transform="translate(3 3)">
              <path
                id="Stroke-1"
                d="M1.565,0A1.579,1.579,0,0,0,0,1.59v.936A2.549,2.549,0,0,0,.689,4.272L5.535,9.424l0,0A5.157,5.157,0,0,1,7,13.023V17.6a.4.4,0,0,0,.585.356l2.76-1.5A1.309,1.309,0,0,0,11.02,15.3V13.011a5.153,5.153,0,0,1,1.446-3.587l4.846-5.153A2.553,2.553,0,0,0,18,2.526V1.59A1.578,1.578,0,0,0,16.436,0Z"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                fill-rule="evenodd"
              />
            </g>
          </g>
        </svg>
      )}
    </div>
  );
});

export default LargeCta;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { CodeInput, Copy, Info, InputText } from '../../components/reusables';
import Tooltip from '../../components/reusables/ReactTooltip';

import { fetchMultipleCreatedCreatives } from '../../actions/live-cam-widget-actions';
import { Gtm } from '../../utils/gtm';
import { closeModalAnimation } from '../../components/Modal/modal-actions/modal-actions.js';
import useClickOutside from '../../components/reusables/colorpicker/useClickOutside.js';
import { connect } from 'react-redux';
import moment from 'moment';

const { genericTextApplyChanges, WidgetSection } = messages;

const LiveCamWidgetModal = ({
  fields,
  dispatch,
  title,
  creatives,
  intl,
  widgetType,
  values,
  modalInner,
  affSub4,
  isImAds,
  expireDays,
}) => {
  const [locked, setLocked] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isdisabled, setIsDisabled] = useState(true);
  const [appliedValues, setAppliedValues] = useState();
  const [generatedCode, setGeneratedCode] = useState('Generating code');
  let tabIndex = 0;
  let toolTipTextLoading;

  useEffect(() => {
    setAppliedValues(values);
  }, []);

  useEffect(() => {
    if (Object.entries(creatives).length > 0) {
      setLoading(false);
    }
  }, [Object.entries(creatives).length]);

  useEffect(() => {
    if (values) {
      if (_.isEqual(appliedValues, values)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    setLocked(true);
  }, [values]);

  const updateCreatives = () => {
    setLocked(true);
    setLoading(true);

    Gtm.event('UI Component', 'Click', 'Livecam-Generator-Widget-Apply-Changes');
    dispatch(fetchMultipleCreatedCreatives(Object.values(creatives), { ...values, aff_sub4: affSub4 })).then(() => {
      setLocked(false);
      setAppliedValues(values);
      setLoading(false);
    });
  };
  if (isLoading) {
    toolTipTextLoading = intl.formatMessage(WidgetSection.generateCode.warnings.waitForLoading);
  }

  const currentCreative = widgetType ? creatives[widgetType.value] : undefined;

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);

  useEffect(() => {
    currentCreative && currentCreative.code !== '' && currentCreative.code !== undefined
      ? setGeneratedCode(currentCreative.code)
      : setGeneratedCode('Generating code');
    if (generatedCode !== 'Generating code' && isImAds) {
      const expiredDaysString = generatedCode.substring(generatedCode.indexOf('expire'), generatedCode.lastIndexOf(','));

      setGeneratedCode(
        generatedCode.replace(
          expiredDaysString,
          expiredDaysString.replace(
            expiredDaysString.match(/\d+/g).toString(),
            moment.duration(expireDays, 'minutes').asDays().toString()
          )
        )
      );
    }
  }, [generatedCode, currentCreative?.code]);
  return (
    <div id="live-cam-widget-modal">
      <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
        <i className="material-icons">close</i>
      </span>
      <div className="title">
        <small>
          <FormattedMessage {...WidgetSection.widgetName.text} />:
        </small>
        <h2>{title}</h2>
      </div>
      <h2 className="subtitle">
        <i className="material-icons link">&#xE157;</i>
        <FormattedMessage {...WidgetSection.generateCode.linkCustomization.text} />
        <Info title="" url="https://support.crakrevenue.com/knowledge-base/generating-tracking-link-sub-idindex-html-161/" />
      </h2>
      <div className="inputs">
        <InputText
          field={fields.source}
          id="livecamGenerationModal_source"
          label={WidgetSection.generateCode.fields.source}
          tabIndex={++tabIndex}
          type="text"
        />
        <InputText
          field={fields.aff_sub}
          id="livecamGenerationModal_aff_sub"
          label={WidgetSection.generateCode.fields.subId1}
          tabIndex={++tabIndex}
          type="text"
        />
        <InputText
          field={fields.aff_sub2}
          id="livecamGenerationModal_aff_sub2"
          label={WidgetSection.generateCode.fields.subId2}
          tabIndex={++tabIndex}
          type="text"
        />
      </div>
      <button className="btn apply" disabled={!locked || isLoading || isdisabled} onClick={updateCreatives} tabIndex={++tabIndex}>
        {intl.formatMessage(genericTextApplyChanges)}
      </button>
      <Tooltip element="div" position="top" tooltip={toolTipTextLoading}>
        <CodeInput codeId="codeInput" disabled={isLoading} loading={isLoading} text={generatedCode} title="Code" />
      </Tooltip>

      <div className="footer-modal row">
        <div className="col-lg-4 col-lg-offset-8 text-right">
          <Copy
            clipboardTarget="#codeInput"
            disabled={isLoading}
            dispatch={dispatch}
            onCopy={() => Gtm.event('UI Component', 'Click', 'Livecam-Generator-Widget-Code-Copy')}
          />
        </div>
      </div>
    </div>
  );
};

LiveCamWidgetModal.propTypes = {
  creatives: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  values: PropTypes.object.isRequired,
  widgetType: PropTypes.object,
};

export default connect((state) => ({
  expireDays: state.livecamGenerator.expireDays,
}))(
  injectIntl(
    reduxForm({
      form: 'surveyGenerationModal',
      touchOnChange: false,
      touchOnBlur: false,
      initialValues: {
        aff_sub: '',
        aff_sub2: '',
        aff_sub3: '',
        aff_sub4: '',
        aff_sub5: '',
        source: '',
      },
      fields: ['aff_sub', 'aff_sub2', 'aff_sub3', 'aff_sub4', 'aff_sub5', 'source'],
    })(LiveCamWidgetModal)
  )
);

const LiveCamWidget = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 187"
          fill="#fff"
          height="30"
          id="Rectangle_187"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="live-cam-widget" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 183" fill="none" height="30" id="Rectangle_183" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 118" fill="none" id="Ellipse_118" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 242" fill="none" id="Ligne_242" x2="30" y2="30" />
          <line data-name="Ligne 243" fill="none" id="Ligne_243" x1="30" y2="30" />
          <line data-name="Ligne 244" fill="none" id="Ligne_244" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 245" fill="none" id="Ligne_245" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 246" fill="none" id="Ligne_246" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 247" fill="none" id="Ligne_247" transform="translate(15)" y2="30" />
          <line data-name="Ligne 248" fill="none" id="Ligne_248" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 249" fill="none" id="Ligne_249" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 184"
            fill="none"
            height="21.218"
            id="Rectangle_184"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 119"
            fill="none"
            id="Ellipse_119"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 185"
            fill="none"
            height="15.004"
            id="Rectangle_185"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 120"
            fill="none"
            id="Ellipse_120"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <path
            d="M7.864,17.949a8.2,8.2,0,1,1,10.643,0"
            data-name="Tracé 1530"
            fill="none"
            id="Tracé_1530"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(1.812 1.275)"
          />
          <text fill="#fff" fontFamily="Montserrat" fontSize="4" fontWeight="800" id="LIVE" transform="translate(9.569 23.713)">
            <tspan x="0" y="0">
              LI
            </tspan>
            <tspan letterSpacing="-0.01em" y="0">
              V
            </tspan>
            <tspan y="0">E</tspan>
          </text>
          <circle
            cx="0.653"
            cy="0.653"
            data-name="Ellipse 121"
            fill="#fff"
            id="Ellipse_121"
            r="0.653"
            transform="translate(19.607 21.559)"
          />
          <rect
            data-name="Rectangle 186"
            fill="none"
            height="5.989"
            id="Rectangle_186"
            rx="0.897"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(6.156 19.227)"
            width="17.688"
          />
          <circle
            cx="3.569"
            cy="3.569"
            data-name="Ellipse 122"
            fill="none"
            id="Ellipse_122"
            r="3.569"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(11.431 9.338)"
          />
          <circle
            cx="1.982"
            cy="1.982"
            data-name="Ellipse 123"
            fill="none"
            id="Ellipse_123"
            r="1.982"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(13.018 10.925)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LiveCamWidget;

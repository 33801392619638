import { useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import request from 'superagent';
import config from '../../config';
import { getCookie } from '../../utils/helpers';
import messages from '../../i18n/base-en';
import { InputText } from '../../components/reusables';
import EditIcon from '../../components/reusables/svg/EditIcon.react';
import GeolocationPinIcon from '../../components/reusables/svg/GeolocationPinIcon.react';
import { sanitizeString } from '../../utils/addressHelper';

const { API_URL } = config;
function AutoSuggestionAddress({
  address_label_field,
  countryFullName,
  intl,
  mapPlaceAddressToFields,
  setAddressCategoryTypes,
  setIsAddressModeSearchTool,
  setIsFetchingAddressData,
  suggestionFilters,
  suggestionParams,
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [latestSentInputText, setLatestSentInputText] = useState('');
  const [chosenSuggestion, setChosenSuggestion] = useState('');
  const [customAddressLabel, setCustomAddressLabel] = useState('');
  const [isFetchingPlace, setIsFetchingPlace] = useState(false);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);
  const [isCustomizeAddressButtonActive, setIsCustomizeAddressButtonActive] = useState(false);
  const [suggestionFetchedNumber, setSuggestionFetchedNumber] = useState(false);
  const suggestionInputTimerRef = useRef(null);

  const fetchAddressSuggestions = useCallback(
    async (inputValue) => {
      try {
        const suggestionBody = {
          MaxResults: suggestionParams.maxResults,
          Language: suggestionParams.language,
          Text: inputValue,
          ...suggestionFilters,
        };
        const response = await request
          .post(`${API_URL}/address-location/search/suggestions`)
          .set({
            'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
          })
          .withCredentials()
          .send(suggestionBody);
        const results = response.body?.Results ?? [];
        return results;
      } catch (error) {
        console.error('Error:', error);
      }
    },
    [suggestionParams, suggestionFilters]
  );

  const fetchPlaceAddressData = useCallback(async (placeId) => {
    if (!placeId) {
      return;
    }
    try {
      const response = await request
        .get(`${API_URL}/address-location/places/${placeId}?language=en`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials();
      // console.log(response.body);
      return response.body?.Place;
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  useEffect(() => {
    setIsSuggestionActive(
      address_label_field.value &&
        !address_label_field.error &&
        address_label_field.value.trim() !== address_label_field.initialValue &&
        address_label_field.value.trim() !== latestSentInputText &&
        address_label_field.value.trim() !== chosenSuggestion &&
        address_label_field.value.trim() !== customAddressLabel &&
        address_label_field.value.trim().length >= suggestionParams.minimumInput &&
        !isFetchingSuggestions &&
        !isFetchingPlace
    );
  }, [
    address_label_field,
    chosenSuggestion,
    customAddressLabel,
    isFetchingPlace,
    isFetchingSuggestions,
    latestSentInputText,
    suggestionParams,
  ]);

  useEffect(() => {
    setIsCustomizeAddressButtonActive(
      address_label_field.value &&
        suggestionFetchedNumber &&
        address_label_field.value !== customAddressLabel &&
        !isFetchingSuggestions &&
        !isFetchingPlace
    );
  }, [address_label_field, customAddressLabel, isFetchingPlace, isFetchingSuggestions, suggestions, suggestionFetchedNumber]);

  useEffect(() => {
    setIsFetchingAddressData(isFetchingPlace || isFetchingSuggestions);
  }, [isFetchingPlace, isFetchingSuggestions, setIsFetchingAddressData]);

  useEffect(() => {
    let active = true;

    if (address_label_field.value === '') {
      setSuggestions(address_label_field.value ? [address_label_field.value] : []);
      return undefined;
    }
    if (isSuggestionActive) {
      if (suggestionInputTimerRef.current) {
        clearTimeout(suggestionInputTimerRef.current);
      }
      suggestionInputTimerRef.current = setTimeout(async () => {
        setIsFetchingSuggestions(true);
        const results = await fetchAddressSuggestions(address_label_field.value.trim());
        setSuggestionFetchedNumber(suggestionFetchedNumber + 1);
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setSuggestions(newOptions);
        setLatestSentInputText(address_label_field.value.trim());
        setIsFetchingSuggestions(false);
      }, suggestionParams.inputTimer);
    }

    return () => {
      if (active) {
        active = false;
      }
    };
  }, [address_label_field, fetchAddressSuggestions, isSuggestionActive, suggestionFetchedNumber, suggestionParams]);

  const handleSuggestionClick = async (suggestion) => {
    const sanitizedChoice = sanitizeString(suggestion?.Text);
    address_label_field.onChange(sanitizedChoice); // Update input value with the clicked suggestion
    setChosenSuggestion(sanitizeString(sanitizedChoice));
    setSuggestions([]); // Clear suggestions after selecting one
    setIsFetchingPlace(true);
    try {
      const placeData = await fetchPlaceAddressData(suggestion?.PlaceId);
      const addressLabel = mapPlaceAddressToFields(placeData);
      setCustomAddressLabel(addressLabel);
      setIsFetchingPlace(false);
      setAddressCategoryTypes(placeData?.Categories);
    } catch (err) {
      console.error('unable to fetch suggestions', err);
    }
  };

  useEffect(
    () => () => {
      if (suggestionInputTimerRef.current) {
        clearTimeout(suggestionInputTimerRef.current);
      }
    },
    []
  );

  return (
    <div className="row">
      <div className="col-md-12 input-field">
        <div id="address-suggestion">
          <InputText
            disabled={isFetchingPlace}
            displayErrorInstantly
            field={address_label_field}
            id="address_label_field"
            isLoading={isFetchingSuggestions}
            label={messages.genericTextAddress}
            onChange={address_label_field.onChange} // Handle input changes
            type="text"
          />
          <ul className="suggestion-list">
            {suggestions.map((suggestion, index) => (
              <li className="suggestion-item" key={index} onClick={() => handleSuggestionClick(suggestion)}>
                <GeolocationPinIcon />
                <p>{suggestion?.Text}</p>
              </li>
            ))}
            {isCustomizeAddressButtonActive ? (
              <li
                className="suggestion-item customize"
                key={suggestions.length}
                onClick={() => setIsAddressModeSearchTool(false)}
              >
                <EditIcon />
                <p>{`Can't find your ${countryFullName} address? Enter it manually`}</p>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

AutoSuggestionAddress.propTypes = {
  address_label_field: PropTypes.object.isRequired,
  countryFullName: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  mapPlaceAddressToFields: PropTypes.func.isRequired,
  setAddressCategoryTypes: PropTypes.func,
  setIsAddressModeSearchTool: PropTypes.func,
  setIsFetchingAddressData: PropTypes.func,
  suggestionFilters: PropTypes.object.isRequired,
  suggestionParams: PropTypes.object.isRequired,
};

export default injectIntl(AutoSuggestionAddress);

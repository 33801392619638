const Dating = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 167"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_167"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="dating" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 164" fill="none" height="30" id="Rectangle_164" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 105" fill="none" id="Ellipse_105" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 206" fill="none" id="Ligne_206" x2="30" y2="30" />
          <line data-name="Ligne 207" fill="none" id="Ligne_207" x1="30" y2="30" />
          <line data-name="Ligne 208" fill="none" id="Ligne_208" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 209" fill="none" id="Ligne_209" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 210" fill="none" id="Ligne_210" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 211" fill="none" id="Ligne_211" transform="translate(15)" y2="30" />
          <line data-name="Ligne 212" fill="none" id="Ligne_212" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 213" fill="none" id="Ligne_213" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 165"
            fill="none"
            height="21.218"
            id="Rectangle_165"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 106"
            fill="none"
            id="Ellipse_106"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 166"
            fill="none"
            height="15.004"
            id="Rectangle_166"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 107"
            fill="none"
            id="Ellipse_107"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <path
            d="M22.845,18.255H11.117l-3.26,2.968-.69-2.968H4.605a1.1,1.1,0,0,1-1.1-1.1V6.182a1.1,1.1,0,0,1,1.1-1.1h18.24a1.1,1.1,0,0,1,1.1,1.1V17.154A1.1,1.1,0,0,1,22.845,18.255Z"
            data-name="Tracé 1522"
            fill="none"
            id="Tracé_1522"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(1.274 1.848)"
          />
          <path
            d="M12.064,15.042l2-2.2A16.621,16.621,0,0,0,15.3,11.317q.183-.255.358-.519a2.322,2.322,0,0,0,.382-1.273V9.4A2.2,2.2,0,0,0,13.8,7.183a2.35,2.35,0,0,0-1.737,1.045,2.368,2.368,0,0,0-1.737-1.045A2.19,2.19,0,0,0,8.075,9.4v.127A2.312,2.312,0,0,0,8.457,10.8q.174.263.358.519a16.413,16.413,0,0,0,1.237,1.528l2,2.2"
            data-name="Tracé 1523"
            fill="none"
            id="Tracé_1523"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(2.936 2.611)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Dating;

const PreRollAds = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 235.64 235.64" height="30">
    <defs>
      <style>{'.cls-preroll--2{fill:none;stroke-width:0}'}</style>
    </defs>
    <g id="Calque_1" data-name="Calque 1">
      <path d="M0 0h235.64v235.64H0z" className="cls-preroll--2" />
      <circle cx={117.82} cy={117.82} r={117.82} className="cls-preroll--2" />
      <path d="m0 0 235.64 235.64M235.64 0 0 235.64M34.49 34.49h166.66v166.66H34.49z" className="cls-preroll--2" />
      <circle cx={117.82} cy={117.82} r={83.33} className="cls-preroll--2" />
      <path d="M58.89 58.89h117.85v117.85H58.89z" className="cls-preroll--2" />
      <circle cx={117.82} cy={117.82} r={58.92} className="cls-preroll--2" />
      <rect
        width={160.38}
        height={118.49}
        x={37.63}
        y={58.57}
        rx={14.69}
        ry={14.69}
        style={{
          stroke: '#ffffff',
          strokeWidth: 6,
          fill: 'none',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M98.9 117.82V87.83l25.98 14.99 25.97 15-25.97 14.99-25.98 15v-29.99z"
        style={{
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: '#ffffff',
          strokeWidth: 6,
          fill: 'none',
        }}
      />
    </g>
  </svg>
);

export default PreRollAds;

import PropTypes from 'prop-types';

const Targeting = ({ targeting }) => (
  <div className="targeting">
    {targeting.indexOf('Mobile') !== -1 && targeting.indexOf('Desktop / Web') !== -1 && (
      <img alt="Desktop and mobile" className="mobile-desktop" height="16" src="/img/mobile-and-desktop.svg" width="24" />
    )}
    {targeting.indexOf('Mobile') !== -1 && targeting.indexOf('Desktop / Web') === -1 && (
      <img alt="Mobile" className="mobile" height="22" src="/img/mobile.svg" width="14" />
    )}
    {targeting.indexOf('Mobile') === -1 && targeting.indexOf('Desktop / Web') !== -1 && (
      <img alt="Desktop" className="desktop" height="16" src="/img/desktop.svg" width="24" />
    )}
  </div>
);

Targeting.propTypes = {
  targeting: PropTypes.array.isRequired,
};

export default Targeting;

const Profile = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 154"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_154"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="profile" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 151" fill="none" height="30" id="Rectangle_151" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 91" fill="none" id="Ellipse_91" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 181" fill="none" id="Ligne_181" x2="30" y2="30" />
          <line data-name="Ligne 182" fill="none" id="Ligne_182" x1="30" y2="30" />
          <line data-name="Ligne 183" fill="none" id="Ligne_183" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 184" fill="none" id="Ligne_184" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 185" fill="none" id="Ligne_185" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 186" fill="none" id="Ligne_186" transform="translate(15)" y2="30" />
          <line data-name="Ligne 187" fill="none" id="Ligne_187" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 188" fill="none" id="Ligne_188" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 152"
            fill="none"
            height="21.218"
            id="Rectangle_152"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 92"
            fill="none"
            id="Ellipse_92"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 153"
            fill="none"
            height="15.004"
            id="Rectangle_153"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 93"
            fill="none"
            id="Ellipse_93"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <circle
            cx="10.223"
            cy="10.223"
            data-name="Ellipse 94"
            fill="none"
            id="Ellipse_94"
            r="10.223"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(4.777 4.777)"
          />
          <path
            d="M12.077,16.9h0a3.623,3.623,0,0,1-3.645-3.062l-.465-3.422a3.576,3.576,0,0,1,3.645-3.971h.929a3.573,3.573,0,0,1,3.645,3.966l-.465,3.422A3.627,3.627,0,0,1,12.077,16.9Z"
            data-name="Tracé 1511"
            fill="none"
            id="Tracé_1511"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(2.886 2.343)"
          />
          <path
            d="M10.811,13.7,10.7,14.723A1.869,1.869,0,0,1,9.46,16.28L5.94,17.515"
            data-name="Tracé 1512"
            fill="none"
            id="Tracé_1512"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(2.16 4.981)"
          />
          <path
            d="M12.456,13.7l.112,1.026A1.869,1.869,0,0,0,13.8,16.28l3.521,1.235"
            data-name="Tracé 1513"
            fill="none"
            id="Tracé_1513"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(4.53 4.981)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Profile;

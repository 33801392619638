const OffersSliderImage = ({ className, href, src, index, sliderGtmEvent }) => {
  const onClick = () => {
    sliderGtmEvent({ level: 1, positionIndex: index, imgSrc: src });
  };

  return (
    <>
      <a className={`innerSlideLarge ${className}`} href={href} onClick={onClick}>
        <div className="imgBox">
          <img src={src} />
        </div>
      </a>
    </>
  );
};

export default OffersSliderImage;

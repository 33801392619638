import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import messages from '../../i18n/base-en.js';

import OffersImage from './OffersImage.react';
import { Container, Payout, PayoutType, Targeting, CountryList } from '../../components/reusables';

import { getNiches } from '../../utils/helpers';

import NewIcon from '../reusables/svg/MenuIcons/NewIcon';
import Tooltip from '../reusables/Tooltip.react';
import EpcTooltipIcon from './EpcTooltipIcon';

const OffersCard = ({
  epcIconIsVisible,
  approvalStatus,
  categories,
  channels,
  conversionTypes,
  defaultPayout,
  featured,
  id,
  isNew,
  name,
  payoutType,
  percentPayout,
  statusMapping,
  thumbnail,
  verticals,
  epcAffiliation,
  topCountries,
  intl,
  widthSize,
}) => (
  <Container className="offers-card z-depth-2" entirelyClickable link={{ href: `/offers/${id}`, text: 'See Details' }}>
    <div className="clearfix">
      <div className="image-container">
        <OffersImage alt={name} borderRadius="15px" fontSize="13px" height="70px" thumbnail={thumbnail} width="70px" />
        {featured === true && (
          <div className="featured-flag z-depth-2">
            <img alt="Featured" height="20" src="/img/featured-star.svg" width="20" />
          </div>
        )}
        {isNew ? (
          <div className="icon-new">
            <NewIcon />
          </div>
        ) : null}
      </div>
      <div className="pull-right">
        <div>
          <Targeting targeting={channels} />
        </div>
        <div>
          <Payout
            conversionTypes={conversionTypes.map((ct) => ct.name)}
            defaultPayout={defaultPayout}
            payoutType={payoutType}
            percentPayout={percentPayout}
          />
        </div>
        <div>
          <PayoutType categories={conversionTypes.map((ct) => ct.name)} />
        </div>
      </div>
    </div>
    <div className="title" style={{ width: 'calc(100% - 10px)' }}>
      <Tooltip content={statusMapping[approvalStatus]} position="top">
        <div
          className={`offer-status pull-left ${approvalStatus} ${
            ['approved', 'pending'].indexOf(approvalStatus) !== -1 ? 'bg-primary-color' : ''
          }`}
        />
      </Tooltip>
      <span>{name}</span>
    </div>
    <div>
      <span className="id">{`ID: ${id}`}</span>
      <span className="vertical">{`Vertical: ${verticals.map((ver) => ver.name).join(', ')}`}</span>
      <div className="epc">
        <b>
          <FormattedMessage {...messages.offerEPC} />:{' '}
        </b>

        {epcAffiliation ? (
          <FormattedNumber currency="USD" minimumFractionDigits={4} style="currency" value={parseFloat(epcAffiliation || 0)} />
        ) : (
          <span className="no-epc" title={intl.formatMessage(messages.notEnoughData)}>
            <FormattedMessage {...messages.notAvailable} />
          </span>
        )}
        {epcIconIsVisible ? <EpcTooltipIcon id={`tooltip-epc-${id}`} widthSize={widthSize} /> : null}
      </div>
      <div>
        <b>
          <FormattedMessage {...messages.genericTopPerformingCountries} />:{' '}
        </b>
        {topCountries ? (
          <CountryList list={topCountries} />
        ) : (
          <span className="no-epc" title={intl.formatMessage(messages.notEnoughData)}>
            <FormattedMessage {...messages.notAvailable} />
          </span>
        )}
      </div>
      {getNiches(categories).length > 0 && <div className="niche">{getNiches(categories).join(', ')}</div>}
    </div>
  </Container>
);

OffersCard.defaultProps = {
  categories: [],
  channels: [],
  conversionTypes: [],
  offers: [],
  verticals: [],
  epcAffiliation: false,
};

OffersCard.propTypes = {
  approvalStatus: PropTypes.string,
  categories: PropTypes.array,
  channels: PropTypes.array,
  conversionTypes: PropTypes.array,
  defaultPayout: PropTypes.any,
  epcAffiliation: PropTypes.any,
  featured: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  name: PropTypes.string.isRequired,
  offers: PropTypes.array,
  payoutType: PropTypes.string.isRequired,
  percentPayout: PropTypes.any,
  previewUrl: PropTypes.string.isRequired,
  statusMapping: PropTypes.object.isRequired,
  targeting: PropTypes.array,
  thumbnail: PropTypes.object,
  verticals: PropTypes.array,
};

export default injectIntl(OffersCard);

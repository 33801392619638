const Statistics = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 146"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_146"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="statistics" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 143" fill="none" height="30" id="Rectangle_143" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 84" fill="none" id="Ellipse_84" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 164" fill="none" id="Ligne_164" x2="30" y2="30" />
          <line data-name="Ligne 165" fill="none" id="Ligne_165" x1="30" y2="30" />
          <line data-name="Ligne 166" fill="none" id="Ligne_166" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 167" fill="none" id="Ligne_167" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 168" fill="none" id="Ligne_168" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 169" fill="none" id="Ligne_169" transform="translate(15)" y2="30" />
          <line data-name="Ligne 170" fill="none" id="Ligne_170" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 171" fill="none" id="Ligne_171" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 144"
            fill="none"
            height="21.218"
            id="Rectangle_144"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 85"
            fill="none"
            id="Ellipse_85"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 145"
            fill="none"
            height="15.004"
            id="Rectangle_145"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 86"
            fill="none"
            id="Ellipse_86"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <circle
            cx="10.194"
            cy="10.194"
            data-name="Ellipse 87"
            fill="none"
            id="Ellipse_87"
            r="10.194"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(4.806 4.806)"
          />
          <path
            d="M13.682,3.524V13.846H3.524"
            data-name="Tracé 1508"
            fill="none"
            id="Tracé_1508"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(1.281 1.281)"
          />
          <line
            data-name="Ligne 172"
            fill="none"
            id="Ligne_172"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(7.862 7.723)"
            x1="7.102"
            y1="7.405"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Statistics;

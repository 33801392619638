import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { Gtm } from '../../utils/gtm';
import HeaderPayoutIcon from '../reusables/svg/Jerkmate2/HeaderPayouts';
import HighPerfoIcon from '../reusables/svg/Jerkmate2/HighPerfo';
import LandingPagesIcon from '../reusables/svg/Jerkmate2/LandingPages';
import TrackingIcon from '../reusables/svg/Jerkmate2/Tracking';
import parse from 'react-html-parser';
const JerkmateHeader = () => {
  const { sliderSectionRef } = useJerkmateOffersContext();

  const advantages = [
    {
      description: 'AI-Optimized Landing Pages Rotation',
    },
    {
      description: 'High-performing offers in 200+ geos',
    },
    {
      description: 'Hundreds of optimized landing pages',
    },
    {
      description: 'Exclusive Array <br />of Effective Tools',
    },
  ];

  const scrollToSlider = () => {
    const siteHeaderHeight = document.getElementById('site-header').offsetHeight;
    const target = sliderSectionRef.current;
    window.scroll({ top: target.offsetTop - siteHeaderHeight - 15, behavior: 'smooth' });
    Gtm.event(`jerkmate page`, 'Click', 'access_offers');
  };

  return (
    <div className="header-box">
      <div className="description-box">
        <h1>
          Reach New Heights of Affiliate Success with <span className="jerkmate-pink">Jerkmate</span>
        </h1>
        <p>
          Earn your biggest payouts yet with the largest and best-converting cam site. <br /> Gear yourself up with our exclusive
          and dedicated support!
        </p>
        <div className="jerkmate-btn" onClick={scrollToSlider}>
          See All Jerkmate Offers
        </div>
      </div>
      <div className="advantages-box">
        {advantages.map((advantage, index) => (
          <div className="advantage-box" key={index}>
            <div className="imgCircle">
              <img alt="Jerkmate" className="jerky-coin-right-top" src={`/img/jerkmate2/jerky-header-icon-${index}.svg`} />
            </div>
            <p>{parse(advantage.description)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JerkmateHeader;

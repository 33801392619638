import { Link } from 'react-router';
import { resetOffer } from '../../../../pages/offer/Offer-Actions/Offer-Actions';
const OfferNavigationBar = ({ dispatch }) => (
  <div id="offer-navigation-bar" onClick={() => dispatch(resetOffer())}>
    <Link to="/offers">
      <i className="return-to-offers material-icons">arrow_back</i>
    </Link>
  </div>
);

export default OfferNavigationBar;

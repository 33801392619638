import PropTypes from 'prop-types';
import Lazyload from 'react-lazyload';

import OffersCard from './OffersCard.react';

const OffersCardsContainer = (props) => {
  const { offers, statusMapping, epcIconIsVisible, widthSize } = props;

  return (
    <div id="offers-cards">
      {offers.map((offer) => (
        <Lazyload key={offer.id || offer.offer_id} offset={100}>
          <OffersCard
            approvalStatus={offer.approval_status}
            categories={offer.categories}
            channels={offer.channels.map((chan) => chan.name)}
            conversionTypes={offer.conversion_types}
            country={offer.Country}
            defaultPayout={offer.default_payout}
            epcAffiliation={offer.epc_affiliation}
            epcIconIsVisible={epcIconIsVisible}
            featured={offer.featured !== null}
            id={offer.id || offer.offer_id}
            isNew={!!offer.tags && offer.tags.some((tag) => tag.name === 'New')}
            key={offer.id || offer.offer_id}
            name={offer.name}
            payoutType={offer.payout_type}
            percentPayout={offer.percent_payout}
            previewUrl={offer.preview_url}
            statusMapping={statusMapping}
            thumbnail={offer.thumbnail}
            topCountries={offer.top_countries}
            verticals={offer.verticals}
            widthSize={widthSize}
          />
        </Lazyload>
      ))}
    </div>
  );
};

OffersCardsContainer.propTypes = {
  offers: PropTypes.array.isRequired,
  statusMapping: PropTypes.object.isRequired,
};

export default OffersCardsContainer;

import consts from '../../utils/consts';
import { messengerValidations } from '../access/TellUsAboutYou/ValidateTellUsAboutYou/validateTellUsAboutYou';

const { PHONE } = consts.REGEXES;

export default (values) => {
  const errors = {};

  if (!values.phone) {
    errors.phone = 'Please enter your phone number.';
  } else if (!values.phone.match(PHONE)) {
    errors.phone = 'Please enter a valid phone number.';
  }

  if (!values.bestTimeToCall) {
    errors.bestTimeToCall = 'Please indicate us what is the best time to call you.';
  }

  if (!values.timezone) {
    errors.timezone = 'Please select the timezone you live in.';
  }

  if (!values.messaging_platform_name) {
    errors.messaging_platform_name = 'Please choose your messenger app.';
  }

  if (
    !values.messaging_platform_username ||
    !values.messaging_platform_username?.match(messengerValidations[values.messaging_platform_name])
  ) {
    errors.messaging_platform_username = 'Invalid messaging platform information.';
  }

  return errors;
};

import React, { useEffect } from 'react';
import {
  MAIN_DROPDOWN_NAME,
  OFFER_SEARCH_FORM_NAME,
  filterNiche,
  largeCtaTypes,
  offerDropdownCustomLabels,
  offerDropdownFields,
  offerDropdownIncludesSearch,
  offerDropdownLabels,
  statusDropdown,
} from '../reusables/offersSearch/values';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { initialFetching } from '../../utils/initialFetching';
import config from '../../config';
import { OffersFilterProvider } from '../reusables/offersSearch/OfferFilterProvider';
import SearchMain from '../reusables/offersSearch/SearchMain';
import DropdownTitle from '../reusables/offersSearch/DropdownTitle';
import DropdownOuter from '../reusables/offersSearch/DropdownOuter';
import ClearFilterCta from '../reusables/offersSearch/ClearFilterCta';
import GreyTab from '../reusables/offersSearch/GreyTab';
import LargeCta from '../reusables/offersSearch/LargeCta';
import FiltersTags from '../reusables/offersSearch/FiltersTags';

const { OFFER_AUDIENCES } = config;

const OffersSearch = ({
  categoriesList,
  countries: acceptedCountries,
  listChannels: targeting,
  listConversionTypes: payoutType,
  listMediaPlacements: acceptedPromotionMethod,
  listVerticals: verticals,
  listCountries: performsIn,
  fields,
  handleSearchSubmit,
  offersIsFetching,
  search,
  dispatch,
  offersSearch,
  approvalStatusMapping,
  isSeeAllClicked,
}) => {
  useEffect(() => {
    initialFetching(dispatch);
  }, []);

  let dropdowns = [
    {
      field: offerDropdownFields.vertical,
      options: verticals,
    },
    {
      field: offerDropdownFields.payoutType,
      options: payoutType,
    },
    {
      field: offerDropdownFields.targeting,
      options: targeting,
    },
    {
      field: offerDropdownFields.acceptedCountry,
      options: acceptedCountries,
    },
    {
      field: offerDropdownFields.niche,
      options: filterNiche(categoriesList),
    },
    {
      field: offerDropdownFields.status,
      options: statusDropdown,
    },
    {
      field: offerDropdownFields.acceptedPromotionMethod,
      options: acceptedPromotionMethod,
    },
    {
      field: offerDropdownFields.performsIn,
      options: performsIn,
    },
  ];

  dropdowns = dropdowns.map((dropdown) => {
    const { field, options } = dropdown;

    //CUSTOM MAP STATUS OPTION VALUES
    if (field === offerDropdownFields.status) {
      dropdown.options = options.map((option) => {
        const { name } = option;

        for (const key in approvalStatusMapping) {
          const value = approvalStatusMapping[key];
          if (name === value) {
            return { id: key, ...option };
          }
        }
      });
    }

    dropdown.label = offerDropdownLabels[field];
    dropdown.selectedValues = fields[field]?.value;
    dropdown.customLabel = offerDropdownCustomLabels[field];
    dropdown.search = offerDropdownIncludesSearch.includes(field);

    return dropdown;
  });

  return (
    <div id="offersFilter">
      <OffersFilterProvider
        dispatch={dispatch}
        dropdowns={dropdowns}
        handleSearchSubmit={handleSearchSubmit}
        isSeeAllClicked={isSeeAllClicked}
        offersIsFetching={offersIsFetching}
        offersSearch={offersSearch}
        search={search}
      >
        <SearchMain />
        <div className="filter-box">
          <LargeCta largeCtaTpe={largeCtaTypes.mainFilter} text="Filter" />
          <ClearFilterCta className="clear" />
        </div>
        <DropdownOuter className="greyTabs" field={MAIN_DROPDOWN_NAME}>
          <DropdownTitle className="mainTitle" field={MAIN_DROPDOWN_NAME} label="Filters" />
          <div className="scrollableGreyTabs">
            {dropdowns.map((dropdown, i) => (
              <GreyTab key={i} {...dropdown} />
            ))}
            <ClearFilterCta className="selectableTab clear" />
          </div>
        </DropdownOuter>
        <FiltersTags />
      </OffersFilterProvider>
    </div>
  );
};

const getArrayFromState = (theState) => (theState ? theState.split(',') : []);

export default injectIntl(
  reduxForm(
    {
      form: OFFER_SEARCH_FORM_NAME,
      fields: [
        'approval_status',
        'Audience',
        'Country',
        'featured',
        'MediaPlacement',
        'Niche',
        'Payout',
        'Targeting',
        'TopCountries',
        'searchString',
        'Vertical',
        'Id',
      ],
      destroyOnUnmount: false,
    },
    (state) => ({
      initialValues: {
        approval_status: [],
        Audience: state.profile.data.settings.general === '1' ? OFFER_AUDIENCES.offerAudienceOffersG : [],
        Country: getArrayFromState(state.offers.offersLocationQuery.country),
        featured: !!state.offers.offersLocationQuery.featured,
        MediaPlacement: getArrayFromState(state.offers.offersLocationQuery.mediaplacement),
        Niche: getArrayFromState(state.offers.offersLocationQuery.niche),
        Payout: getArrayFromState(state.offers.offersLocationQuery.payout),
        TopCountries: getArrayFromState(state.offers.offersLocationQuery.top_countries),
        searchString: '',
        Targeting: getArrayFromState(state.offers.offersLocationQuery.targeting),
        Vertical: getArrayFromState(state.offers.offersLocationQuery.vertical),
        Id: getArrayFromState(state.offers.offersLocationQuery.id),
      },
      isSeeAllClicked: state.offers.isSeeAllClicked,
    })
  )(OffersSearch)
);

import PropTypes from 'prop-types';

import OfferTag from '../OfferTag.react';
import NewProductLabel from '../../../reusables/svg/NewProductLabel.react';
import SpeakerIcon from '../../../reusables/svg/SpeakerIcon.react';
import Star from '../../../reusables/svg/Star.react';

const OfferTagsBar = (props) => {
  const { isExclusive, isNew, isTop } = props;
  const isDisplayed = isExclusive || isNew || isTop;

  return isDisplayed ? (
    <div id="offer-tags-bar">
      <div className="container-fluid app-container">
        {isExclusive ? <OfferTag Icon={SpeakerIcon} id="exclusive-offer" messageKey="offerExclusiveOffer" /> : null}
        {isTop ? <OfferTag Icon={Star} id="top-offer" messageKey="offerTopOffer" /> : null}
        {isNew ? (
          <OfferTag Icon={NewProductLabel} classNames={['fill-primary-color']} id="new-offer" messageKey="offerNewOffer" />
        ) : null}
      </div>
    </div>
  ) : null;
};

OfferTagsBar.defaultProps = {
  isExclusive: false,
  isNew: false,
  isTop: false,
};

OfferTagsBar.propTypes = {
  isExclusive: PropTypes.bool,
  isNew: PropTypes.bool,
  isTop: PropTypes.bool,
};

export default OfferTagsBar;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

import messages from '../../i18n/base-en.js';
class Radio extends Component {
  constructor(props) {
    super(props);

    this.renderLabel = this.renderLabel.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      disabled,
      field: { dirty, error, touched, value },
    } = this.props;

    return (
      nextProps.disabled !== disabled ||
      (!!nextProps.field &&
        (dirty !== nextProps.field.dirty ||
          error !== nextProps.field.error ||
          touched !== nextProps.field.touched ||
          value !== nextProps.field.value))
    );
  }

  renderLabel() {
    const { id, label, labelClassNames, optional, preLabel, image } = this.props;

    const labelClasses = [labelClassNames, image ? 'has-image' : undefined, preLabel ? 'pre-label' : undefined];

    return (
      <label className={labelClasses.join(' ')} htmlFor={id}>
        <FormattedHTMLMessage {...label} />
        {optional ? <FormattedHTMLMessage {...messages.genericTextOptionalInput} /> : null}
        {image ? <img alt={`Visual for ${label.defaultMessage} radio button`} src={image} /> : null}
      </label>
    );
  }

  render() {
    const { autoFocus, className, disabled, field, id, label, onClick, preLabel, tabIndex, value, withGap } = this.props;

    const fieldClasses = [
      'radio-field',
      className,
      !!field && field.touched ? 'touched' : undefined,
      !!field && field.error && field.touched ? 'error' : undefined,
    ];

    return (
      <div className={fieldClasses.join(' ')}>
        {label && preLabel ? this.renderLabel() : null}
        <input
          autoFocus={autoFocus}
          checked={field.value === value || parseInt(field.value) === value}
          className={withGap ? 'with-gap' : undefined}
          disabled={disabled}
          id={id}
          name={field.name}
          onChange={field.onChange}
          onClick={onClick}
          onDragStart={field.onDragStart}
          onDrop={field.onDrop}
          onFocus={field.onFocus}
          tabIndex={tabIndex}
          type="radio"
          value={value}
        />
        {label && !preLabel ? this.renderLabel() : null}
      </div>
    );
  }
}

Radio.defaultProps = {
  autoFocus: false,
  disabled: false,
};

Radio.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.any.isRequired,
  image: PropTypes.string,
  label: PropTypes.object.isRequired,
  labelClassNames: PropTypes.array,
  onClick: PropTypes.func,
  optional: PropTypes.bool,
  preLabel: PropTypes.bool,
  tabIndex: PropTypes.number,
  value: PropTypes.any.isRequired,
  withGap: PropTypes.bool,
};

export default injectIntl(Radio);

const AI = () => (
  <svg height="30" width="30" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.24 47.31">
    <defs></defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill="none"
          stroke="#e4e4e4"
          stroke-linejoin="round"
          stroke-width="2.37px"
          d="M37.77,29.88h0c-8.97,0-16.25,7.27-16.25,16.25h0c0-8.97-7.27-16.25-16.25-16.25h0c8.97,0,16.25-7.27,16.25-16.25h0c0,8.97,7.27,16.25,16.25,16.25Z"
        />
        <path
          fill="none"
          stroke="#e4e4e4"
          stroke-linejoin="round"
          stroke-width="2.37px"
          d="M40.05,13.64h0c-3.21,0-5.81,2.6-5.81,5.81h0c0-3.21-2.6-5.81-5.81-5.81h0c3.21,0,5.81-2.6,5.81-5.81h0c0,3.21,2.6,5.81,5.81,5.81Z"
        />
        <path
          fill="none"
          stroke="#e4e4e4"
          stroke-linejoin="round"
          stroke-width="2.37px"
          d="M17.4,9.29h0c-4.48,0-8.11,3.63-8.11,8.11h0c0-4.48-3.63-8.11-8.11-8.11h0c4.48,0,8.11-3.63,8.11-8.11h0c0,4.48,3.63,8.11,8.11,8.11Z"
        />
      </g>
    </g>
  </svg>
);

export default AI;

export default {
  homePaymentHistoryTitle: {
    id: 'home.paymentHistoryTitle',
    description: 'Payment History',
    defaultMessage: 'Payment History',
  },
  homePromoteThisOffer: {
    id: 'home.promoteThisOffer',
    description: 'Promote this offer',
    defaultMessage: 'Promote this offer',
  },
  homeLatestBlogPosts: {
    id: 'home.latestBlogPosts',
    description: 'Latest Blog Posts',
    defaultMessage: 'Latest Blog Posts',
  },
  homeRecentStatistics: {
    id: 'home.recentStatistics',
    description: 'Recent statistics',
    defaultMessage: 'Recent statistics',
  },
  homeFeaturedOffers: {
    id: 'home.featuredOffers',
    description: 'Top Offers',
    defaultMessage: 'Top Offers',
  },
  homeYourAffManager: {
    id: 'home.yourAffManager',
    description: 'Your Affiliate Manager',
    defaultMessage: 'Your Affiliate Manager',
  },
  homeYourDailyStats: {
    id: 'home.yourDailyStats',
    description:
      'Your daily stats will appear in this section when you have some. Wanna see what it looks like to be rich? Start promoting now.',
    defaultMessage:
      'Your daily stats will appear in this section when you have some. Wanna see what it looks like to be rich? Start promoting now.',
  },
  homeStartPromoting: {
    id: 'home.startPromoting',
    description: 'Start promoting now',
    defaultMessage: 'Browse offers',
  },
  homeGetInTouch: {
    id: 'home.getInTouch',
    description: 'Get In Touch',
    defaultMessage: 'Get In Touch',
  },
  homeRecentStatBy: {
    id: 'home.recentStatBy',
    description: 'Recent {stat} statistics by {field}',
    defaultMessage: 'Recent {stat} statistics by {field}',
  },
  homeAffiliateRanking: {
    id: 'home.affiliateRanking',
    description: 'Affiliate Ranking',
    defaultMessage: 'Affiliate Ranking',
  },
  homeYourAffiliateManager: {
    id: 'home.yourAffiliateManager',
    description: 'Your Affiliate Manager',
    defaultMessage: 'Your Affiliate Manager',
  },
  homeTotalPayout: {
    id: 'home.totalPayout',
    description: 'Total Payout Year to Date',
    defaultMessage: 'Total Payout Year to Date',
  },
  homeActiveAffiliate: {
    id: 'home.ActiveAffiliate',
    description: 'Active Affiliate',
    defaultMessage: 'Active Affiliate',
  },
  homeVIPAffiliate: {
    id: 'home.VIPAffiliate',
    description: 'VIP Affiliate',
    defaultMessage: 'VIP Affiliate',
  },
  homeEliteClub: {
    id: 'home.eliteClub',
    description: 'Elite Club',
    defaultMessage: 'Elite Club',
  },
  homeNotRankedTitle: {
    id: 'home.notRankedTitle',
    description: '2 Things You Can Do Now',
    defaultMessage: '2 Things You Can Do Now',
  },
  homeNotRankedSubtitle: {
    id: 'home.notRankedSubtitle',
    description: 'Still Not Ranked... How To Start?',
    defaultMessage: 'Still Not Ranked... How To Start?',
  },
  homeNotRankedActionCaptions: {
    smartLink: {
      id: 'home.homeNotRankedActionCaptions.smartLink',
      description: 'SmartLinks are the perfect Set & Forget tools for any Affiliate.',
      defaultMessage: 'SmartLinks are the perfect Set & Forget tools for any Affiliate.',
    },
    masterclass: {
      id: 'home.homeNotRankedActionCaptions.masterclass',
      description: 'Why not start with our 7 days Masterclass and avoid unnecessary stress!',
      defaultMessage: 'Why not start with our 7 days Masterclass and avoid unnecessary stress!',
    },
  },
  homeNotRankedActionCTAs: {
    smartLink: {
      id: 'home.homeNotRankedCTA.smartLink',
      description: 'Get Started&nbsp;»',
      defaultMessage: 'Get Started&nbsp;»',
    },
    masterclass: {
      id: 'home.homeNotRankedCTA.masterclass',
      description: 'Access Masterclass&nbsp;»',
      defaultMessage: 'Access Masterclass&nbsp;»',
    },
  },
};

export default {
  WidgetSection: {
    widgetName: {
      text: {
        id: 'WidgetSection.widgetName.text',
        description: 'Widgets Section - Widget Name - Text',
        defaultMessage: 'Widget Name',
      },
    },
    widgetPreview: {
      text: {
        id: 'WidgetSection.widgetPreview.text',
        description: 'Widgets Section - Widget Preview - Text',
        defaultMessage: 'Widget preview',
      },
    },
    generateCode: {
      button: {
        text: {
          id: 'WidgetSection.generateCode.button.text',
          description: 'Widgets Section - Generate Code - Button - Text',
          defaultMessage: 'GENERATE WIDGET CODE',
        },
      },
      linkCustomization: {
        text: {
          id: 'WidgetSection.generateCode.linkCustomization.text',
          description: 'Widgets Section - Generate Code - Link Customization - Text',
          defaultMessage: 'Link Customization',
        },
      },
      warnings: {
        applyChangesBefore: {
          id: 'WidgetSection.generateCode.warnings.applyChangesBefore',
          description: 'Widgets Section - Generate Code - Warnings - Apply Changes Before',
          defaultMessage: 'Please apply changes to copy your code',
        },
        waitForLoading: {
          id: 'WidgetSection.generateCode.warnings.waitForLoading',
          description: 'Widgets Section - Generate Code - Warnings - Wait For Loading',
          defaultMessage: 'Please wait for changes to be apply',
        },
        enterNameTemplateOfferFirst: {
          id: 'WidgetSection.generateCode.warnings.enterNameTemplateOfferFirst',
          description: 'Widgets Section - Generate Code - Warnings - Enter Name Template Offer First',
          defaultMessage: "Please name your widget, choose a template and an offer to generate the widget's code",
        },
      },
      fields: {
        subId1: {
          id: 'WidgetSection.generateCode.fields.subId1',
          description: 'Widgets Section - Generate Code - Fields - Sub Id 1',
          defaultMessage: 'Sub ID 1',
        },
        subId2: {
          id: 'WidgetSection.generateCode.fields.subId2',
          description: 'Widgets Section - Generate Code - Fields - Sub Id 2',
          defaultMessage: 'Sub ID 2',
        },
        subId3: {
          id: 'WidgetSection.generateCode.fields.subId3',
          description: 'Widgets Section - Generate Code - Fields - Sub Id 3',
          defaultMessage: 'Sub ID 3',
        },
        subId4: {
          id: 'WidgetSection.generateCode.fields.subId4',
          description: 'Widgets Section - Generate Code - Fields - Sub Id 4',
          defaultMessage: 'Sub ID 4',
        },
        subId5: {
          id: 'WidgetSection.generateCode.fields.subId5',
          description: 'Widgets Section - Generate Code - Fields - Sub Id 5',
          defaultMessage: 'Sub ID 5',
        },
        source: {
          id: 'WidgetSection.generateCode.fields.source',
          description: 'Widgets Section - Generate Code - Fields - Source',
          defaultMessage: 'Source',
        },
      },
    },
    unavailableFeature: {
      header: {
        text: {
          id: 'WidgetSection.unavailableFeature.header.text',
          description: 'Widget Page - Unavailable Feature - Header - Text',
          defaultMessage: 'Sorry',
        },
      },
      desktopContent: {
        text: {
          id: 'WidgetSection.unavailableFeature.desktopContent.text',
          description: 'Widget Page - Unavailable Feature - Desktop Content - Text',
          defaultMessage:
            'This feature is only accessible on larger screens. Therefore, we invite you to either resize your browser or log in from a larger device.',
        },
      },
      mobileContent: {
        text: {
          id: 'WidgetSection.unavailableFeature.mobileContent.text',
          description: 'Widget Page - Unavailable Feature - Mobile Content - Text',
          defaultMessage: 'This feature is only available on desktop. Please connect on a larger device to use this section.',
        },
      },
    },
    cssCustomization: {
      customCss: {
        header: {
          text: {
            id: 'WidgetSection.cssCustomization.customCss.header.text',
            description: 'Widget Page - Css Customization - Custom Css - Header - Text',
            defaultMessage: 'Css Customization',
          },
        },
      },
      cssExample: {
        header: {
          text: {
            id: 'WidgetSection.cssCustomization.cssExample.header.text',
            description: 'Widget Page - Css Customization - Css Example - Header - Text',
            defaultMessage: 'Example',
          },
        },
      },
    },
  },
};

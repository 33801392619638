import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText, Radio, Switch } from '../../components/reusables';

import { Gtm } from '../../utils/gtm';
import intercom from '../../utils/intercom';
import { initialFetching } from '../../utils/initialFetching';

import 'materialize-css';
import { Select } from 'react-materialize';

const SmartLinkCustomize = ({
  fields,
  intl,
  smartLinks,
  values,
  listMediaPlacements,
  dispatch,
  errors,
  isCurrentlySelectedCamSmartLink,
  isCurrentlySelectedDatingSmartLink,
}) => {
  const [payoutTypeOptions, setPayoutTypeOptions] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(smartLinks.offerDating.id);

  useEffect(() => {
    initialFetching(dispatch);
  }, []);

  useEffect(() => {
    if (isCurrentlySelectedCamSmartLink(values.offer_id)) {
      setPayoutTypeOptions([
        { id: 'multi', name: 'Multi CPA', value: 'multi' },
        { id: 'pps', name: 'PPS Only', value: 'pps' },
      ]);
    } else {
      setPayoutTypeOptions([
        { id: 'multi', name: 'Multi CPA', value: 'multi' },
        { id: 'pps', name: 'PPS Only', value: 'pps' },
        { id: 'revshare', name: 'RevShare Only', value: 'revshare' },
      ]);
    }
  }, [values.offer_id]);

  const getBackOffers = (offerId) => {
    let backOffers;
    for (const [, value] of Object.entries(smartLinks)) {
      if (value.id === parseInt(offerId)) {
        backOffers = value.backOffers;
      }
    }

    return backOffers;
  };

  const handleChangeSmartLinkOfferId = (e) => {
    fields.offer_id.onChange(e.target.value);

    if (isCurrentlySelectedDatingSmartLink(e.target.value) || isCurrentlySelectedCamSmartLink(e.target.value)) {
      fields.target.onChange(undefined);
      fields.pyt.onChange('multi');
    } else {
      fields.target.onChange(undefined);
      fields.pyt.onChange(undefined);
    }

    intercom.trackEvent('Smartlink-Select-Vertical');

    Object.entries(smartLinks).map((key) => {
      if (e.target.value === key[1]?.id?.toString()) {
        Gtm.event('dating smartlink', 'click', `smartlink radio - ${intl.formatMessage(messages[key[1]?.label])}`);
        setSelectedOffer(key[1]?.id);
      }
    });

    if (values.bo) {
      const bo = getBackOffers(e.target.value);
      fields.bo.onChange(bo ? bo : '');
    }
  };

  const handleChangePromotionMediumUrlId = (e) => {
    fields.target.onChange(e.target.value);
    Gtm.event('dating smartlink', 'click', `promotion method - ${e.target.value}`);
  };

  const handleChangePayoutType = (e) => {
    fields.pyt.onChange(e.target.value);
    Gtm.event('dating smartlink', 'click', `payout type - ${e.target.value}`, e.target.value);
  };

  const backOffers = getBackOffers(values.offer_id);

  const offerDatingLabel = smartLinks.offerDating.label;

  return (
    <>
      <div className="radio-buttons row">
        <label
          className={selectedOffer === smartLinks.offerDating.id ? 'selected radio-box' : 'radio-box'}
          htmlFor={smartLinks.offerDating.id}
        >
          <Radio
            field={fields.offer_id}
            id={smartLinks.offerDating.id}
            image="/img/featured-star.svg"
            label={messages[offerDatingLabel]}
            onClick={(e) => {
              handleChangeSmartLinkOfferId(e);
            }}
            value={smartLinks.offerDating.id}
          />
        </label>
        {Object.entries(smartLinks)
          .reverse()
          .filter((key) => key[0] !== 'topLabel' && key[0] !== smartLinks.offerDating.label)
          .map((key) => (
            <label className={selectedOffer === key[1].id ? 'selected radio-box' : 'radio-box'} htmlFor={key[1].id} key={key}>
              <Radio
                field={fields.offer_id}
                id={key[1].id}
                image={key[0] !== 'offerGlobalAdult' ? '/img/featured-star.svg' : ''}
                label={messages[key[1].label]}
                onClick={(e) => {
                  handleChangeSmartLinkOfferId(e);
                }}
                value={key[1].id}
              />
            </label>
          ))}
      </div>
      <div id="smart-link-customize">
        {payoutTypeOptions &&
        (isCurrentlySelectedDatingSmartLink(values.offer_id) || isCurrentlySelectedCamSmartLink(values.offer_id)) ? (
          <div className="row">
            <Select
              className="filterInput"
              id="payoutType"
              label={messages.smartLinkPayoutTypeLabel.description}
              onChange={handleChangePayoutType}
              value={fields?.pyt?.value}
            >
              {payoutTypeOptions.map((payoutType, index) => (
                <option key={index} value={payoutType.id}>
                  {payoutType.name}
                </option>
              ))}
            </Select>
          </div>
        ) : null}
        {listMediaPlacements &&
        (isCurrentlySelectedDatingSmartLink(values.offer_id) || isCurrentlySelectedCamSmartLink(values.offer_id)) ? (
          <div className="row">
            <Select
              className="filterInput"
              id="mediaPlacements"
              label={messages.smartLinkPromotionMethodLabel.description}
              onChange={handleChangePromotionMediumUrlId}
              value={fields?.target?.value}
            >
              <option disabled value="" />
              {listMediaPlacements.map((promotionMethod, index) => (
                <option key={index} value={promotionMethod.name.toLowerCase().replace('-', '').replace(/\s/g, '')}>
                  {promotionMethod.name}
                </option>
              ))}
            </Select>
          </div>
        ) : null}
        <div className="row">
          <InputText
            displayErrorInstantly
            field={fields.source}
            gtmEvent={() => {
              Gtm.event('dating smartlink', 'click', 'Sub Id');
            }}
            id="source"
            placeholder={messages.smartLinkSource.description}
            type="text"
          />
        </div>
        <div className="row switchs">
          <div className="col-md-4 col-xs-12">
            <div className="extra-earnings">
              <FormattedMessage {...messages.smartLinkExtra} />
            </div>
          </div>
          <div className="col-md-4 col-xs-6 text-center">
            <Switch
              className="extra-switch"
              field={fields.popUnder}
              label={messages.genericTextPopUnders}
              onChange={() => {
                Gtm.event('dating smartlink', 'click', `pop-unders ${fields.popUnder.value ? 'off' : 'on'}`);
              }}
              value
            />
          </div>
          {backOffers && backOffers.length > 0 ? (
            <div className="col-md-4 col-xs-6 text-center">
              <Switch
                className="extra-switch"
                field={fields.bo}
                label={messages.genericTextBackOffers}
                onChange={() => {
                  Gtm.event('dating smartlink', 'click', `back-offers ${fields.bo.value ? 'off' : 'on'}`);
                }}
                value={backOffers ? backOffers : ''}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

SmartLinkCustomize.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  payoutType: PropTypes.array,
  promotionMedium: PropTypes.array,
  smartLinks: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(SmartLinkCustomize);

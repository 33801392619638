import moment from 'moment';

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import { getCurrentPeriod, fillDailyStatsBlank, getLastPeriod } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestCurrentPeriodPayouts = () => ({
  type: ACTIONS.REQUEST_CURRENT_PERIOD_PAYOUTS,
});
const receiveCurrentPeriodPayouts = (data, totals) => ({
  type: ACTIONS.RECEIVE_CURRENT_PERIOD_PAYOUTS,
  data,
  totals,
});
const failedToReceiveCurrentPeriodPayouts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_CURRENT_PERIOD_PAYOUTS,
});
export const fetchCurrentPeriodPayouts = () => (dispatch) => {
  dispatch(requestCurrentPeriodPayouts());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics`)
      .withCredentials()
      .query({
        search: JSON.stringify({
          groups: ['Stat.date'],
          fields: ['Stat.payout', 'Stat.date'],
          data_start: getCurrentPeriod().dateStart,
          data_end: getCurrentPeriod().dateEnd,
          hour_offset: 0,
          sort: {
            'Stat.date': 'desc',
          },
          totals: true,
        }),
      })
      .end((err, res) => {
        if (res) {
          if (!err) {
            dispatch(receiveCurrentPeriodPayouts(res.body.response.data.data, res.body.response.data.totals));
            fulfill(res.body.response.data.data);
          } else {
            dispatch(failedToReceiveCurrentPeriodPayouts());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        }
      })
  );
};

const requestFeaturedOffers = () => ({ type: ACTIONS.REQUEST_FEATURED_OFFERS });
const receiveFeaturedOffers = (data) => ({
  type: ACTIONS.RECEIVE_FEATURED_OFFERS,
  data,
});
const failedToReceiveFeaturedOffers = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_FEATURED_OFFERS,
});
export const fetchFeaturedOffers = (audience) => (dispatch) => {
  dispatch(requestFeaturedOffers());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offers/findAll`)
      .withCredentials()
      .query({
        search: JSON.stringify({
          filters: {
            Country: [],
          },
          searchString: '',
          featured: true,
          sort: {
            name: 'asc',
          },
          categoriesSort: {},
          categories: {
            Vertical: [],
            Targeting: [],
            Niche: [],
            Payout: [],
            Audience: audience,
          },
          others: {},
          othersSort: {},
          limit: 10,
          page: 1,
        }),
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveFeaturedOffers(res.body.data.offers));
          fulfill();
        } else {
          dispatch(failedToReceiveFeaturedOffers());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestDailyStatistics = () => ({
  type: ACTIONS.REQUEST_DAILY_STATISTICS,
});
const receiveDailyStatistics = (stats) => ({
  type: ACTIONS.RECEIVE_DAILY_STATISTICS,
  data: stats.response.data.data,
  totals: stats.response.data.totals,
});
const failedToReceiveDailyStatistics = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_DAILY_STATISTICS,
});
const fetchDailyStatistics_dailyCall = (queryObj) =>
  new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics`)
      .withCredentials()
      .query(queryObj)
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          fulfill(res);
        }
      })
  );
const fetchDailyStatistics_allTimeCall = (queryObj, dateRange, targetHour) =>
  new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics`)
      .withCredentials()
      .query(queryObj)
      .end((err, res) => {
        if (err) {
          reject(err);
        }

        const statsZero = [];
        const zeroObject = {
          clicks: '0',
          conversions: '0',
          erpc: '0',
          hour: '0',
          impressions: '0',
          payout: '0',
        };

        if (res && res.body?.response.data.data.length > 0) {
          if (dateRange.start <= 2) {
            for (let i = 0; i <= targetHour; i++) {
              statsZero.push({
                Stat: {
                  clicks: '0',
                  conversions: '0',
                  erpc: '0',
                  hour: i,
                  impressions: '0',
                  payout: '0',
                },
              });
            }
          } else {
            const startMoment = moment(dateRange.start);
            const endMoment = moment(dateRange.end);

            for (let m = moment(startMoment); m.isSameOrBefore(endMoment); m.add(1, 'days')) {
              const formattedMoment = m.format('YYYY-MM-DD');

              statsZero.push({
                Stat: {
                  clicks: '0',
                  conversions: '0',
                  erpc: '0',
                  date: formattedMoment,
                  impressions: '0',
                  payout: '0',
                },
              });
            }

            zeroObject.date = moment(startMoment);
          }
          res.body.response.data.data = statsZero;
          res.body.response.data.totals.Stat = zeroObject;
        }

        fulfill(res);
      })
  );
export const fetchDailyStatistics = (dateObj) => (dispatch) => {
  dispatch(requestDailyStatistics());
  return new Promise((fulfill, reject) => {
    const fields = ['Stat.payout', 'Stat.clicks', 'Stat.erpc', 'Stat.conversions'];
    const today = moment().startOf('day').format('YYYY-MM-DD');
    const data_start = dateObj !== null ? dateObj.start : moment().startOf('day').format('YYYY-MM-DD');
    const data_end = dateObj !== null ? dateObj.end : moment().endOf('day').format('YYYY-MM-DD');
    const groups = data_start === data_end ? ['Stat.hour'] : ['Stat.date'];
    const sort = data_start === data_end ? { 'Stat.hour': 'desc' } : { 'Stat.date': 'desc' };

    const currentHour = moment().hour();
    let targetHour = currentHour;
    const dateRange = {
      start: 0,
      end: currentHour,
    };

    if (data_start === data_end && data_start !== today) {
      dateRange.end = parseInt(moment(data_end).endOf('day').format('HH'), 10);
      targetHour = 23;
    } else if (data_start !== data_end) {
      dateRange.start = data_start;
      dateRange.end = data_end;
    }

    fetchDailyStatistics_dailyCall({
      search: JSON.stringify({
        groups,
        fields: [...fields, ...groups],
        data_start,
        data_end,
        totals: true,
        sort,
      }),
    })
      .then((res) => {
        if (res.body.response.data.data.length > 0) {
          const statData = res.body.response.data.data;
          res.body.response.data.data = fillDailyStatsBlank(statData, dateRange);
          return res;
        }
        return fetchDailyStatistics_allTimeCall(
          {
            search: JSON.stringify({
              groups: ['Stat.hour'],
              fields: ['Stat.hour', 'Stat.payout', 'Stat.clicks', 'Stat.erpc', 'Stat.conversions'],
              data_start: '2000-01-01',
              data_end: moment().endOf('day').format('YYYY-MM-DD'),
              hour_offset: 0,
              totals: true,
              sort: {
                'Stat.hour': 'desc',
              },
              limit: 1,
            }),
          },
          dateRange,
          targetHour
        );
      })
      .then((res) => {
        dispatch(receiveDailyStatistics(res.body));
        fulfill(res.body);
      })
      .catch((err) => {
        dispatch(failedToReceiveDailyStatistics());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
      });
  });
};

const requestLastPeriodPayouts = () => ({
  type: ACTIONS.REQUEST_LAST_PERIOD_PAYOUTS,
});
const receiveLastPeriodPayouts = (data, totals) => ({
  type: ACTIONS.RECEIVE_LAST_PERIOD_PAYOUTS,
  data,
  totals,
});
const failedToReceiveLastPeriodPayouts = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_LAST_PERIOD_PAYOUTS,
});
export const fetchLastPeriodPayouts = () => (dispatch) => {
  dispatch(requestLastPeriodPayouts());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics`)
      .withCredentials()
      .query({
        search: JSON.stringify({
          groups: ['Stat.date'],
          fields: ['Stat.payout', 'Stat.date'],
          data_start: getLastPeriod().dateStart,
          data_end: getLastPeriod().dateEnd,
          hour_offset: 0,
          sort: {
            'Stat.date': 'desc',
          },
          totals: true,
        }),
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveLastPeriodPayouts(res.body.response.data.data, res.body.response.data.totals));
          fulfill(res.body.response.data.data);
        } else {
          dispatch(failedToReceiveLastPeriodPayouts());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { reduxForm } from 'redux-form';
import validate from '../pages/smart-link/validateSmartlinkForm.js';
import SmartLinkAdTool from './smart-link/SmartLinkAdTool.react.js';
import SmartLinkCustomize from './smart-link/SmartLinkCustomize.react.js';
import { AlertHeader } from '../components/reusables';

import { fetchTrackingLink, fetchOffer, fetchOfferUrl } from './offer/Offer-Actions/Offer-Actions';
import { clearObject } from '../utils/helpers';

import { Gtm } from '../utils/gtm';
import intercom from '../utils/intercom';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import config from '../config';
import { initialFetching } from '../utils/initialFetching';

const { SMART_LINKS } = config;

const SmartLink = ({
  affId,
  creatives,
  creativesSearchForm,
  creativesSizes,
  creativesIsFetching,
  creativesSizesIsFetching,
  dispatch,
  fields,
  location,
  offerOverlay,
  offerPopCode,
  offersCreativesViewMode,
  smartLinks,
  skinConfigurations,
  trackingLink,
  userEmail,
  values,
  listMediaPlacements,
  offerIsFetching,
  profileData,
  errors,
}) => {
  const timeoutRef = useRef(null);
  const fecthData = () => {
    if (!offerIsFetching) {
      const smartLinkDatingOfferId = SMART_LINKS[SMART_LINKS.topLabel].id;
      dispatch(fetchOffer(smartLinkDatingOfferId));
      dispatch(fetchOfferUrl(smartLinkDatingOfferId));
    }
  };

  const formChangedHandler = () => {
    const formattedValues = {
      ...values,
      bo: values?.bo?.toString(),
      offer_id: values.offer_id === '0' ? SMART_LINKS[SMART_LINKS.topLabel].id : values.offer_id,
    };
    dispatch(fetchTrackingLink(formattedValues.offer_id, clearObject({ ...formattedValues, offer_id: undefined })));
  };

  useEffect(() => {
    initialFetching(dispatch);
    intercom.trackEvent('Smartlink');
    fecthData();
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'smartlink');
    formChangedHandler();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!errors.source) {
        formChangedHandler();
      }
    }, 250);
    return () => clearTimeout(delayDebounceFn);
  }, [JSON.stringify(values)]);

  const onSmartlinkCopy = () => {
    Gtm.event('UI Component', 'Click', 'Console-Smartlink-Copy-Link');
    intercom.update(profileData, {
      company: { latest_copied_link_offer: 'SmartLink' },
    });
    intercom.trackEvent('Smartlink-copy');
  };

  const isCurrentlySelectedCamSmartLink = (currentSelectedDropdownSmartLinkValuesId) =>
    +smartLinks.offerCam.id === +currentSelectedDropdownSmartLinkValuesId;

  const isCurrentlySelectedDatingSmartLink = (currentSelectedDropdownSmartLinkValuesId) =>
    +smartLinks.offerDating.id === +currentSelectedDropdownSmartLinkValuesId;

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('ad-tool')) {
      timeoutRef.current = setTimeout(() => {
        const adToolsList = document.getElementById('offer-creatives');
        if (!adToolsList) {
          return;
        }
        adToolsList.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <AlertHeader className="container-fluid app-container smartlink-alert" iconIsVisible localStorageString="smartlink-alert">
        <span>
          SmartLinks must be promoted in accordance with CrakRevenue’s Terms & Conditions. No incentive, Chat, Spam or Mailing
          traffic allowed.
        </span>
      </AlertHeader>
      <div className="container-fluid app-container" id="smart-link">
        <div className="smart-link-container">
          <div className="row header-container">
            <h1 className="primary-color">Create Your SmartLink!</h1>
            <p className="smartlink-description">
              Unleash CrakRevenue’s exclusive turnkey solution designed to maximize your earnings with one powerful link! Our
              SmartLink is optimized for 300+ top-converting offers on 250+ geos. Let a perfect combination of artificial &
              business intelligence work for you!{' '}
            </p>
          </div>
          <form action="#" id="smartLink" method="post">
            <SmartLinkCustomize
              dispatch={dispatch}
              errors={errors}
              fields={fields}
              isCurrentlySelectedCamSmartLink={isCurrentlySelectedCamSmartLink}
              isCurrentlySelectedDatingSmartLink={isCurrentlySelectedDatingSmartLink}
              listMediaPlacements={listMediaPlacements}
              smartLinks={smartLinks}
              values={values}
            />
          </form>
        </div>
        <SmartLinkAdTool
          affId={affId}
          creatives={creatives}
          creativesIsFetching={creativesIsFetching}
          creativesSearchForm={creativesSearchForm}
          creativesSizes={creativesSizes}
          creativesSizesIsFetching={creativesSizesIsFetching}
          dispatch={dispatch}
          isCurrentlySelectedCamSmartLink={isCurrentlySelectedCamSmartLink}
          isCurrentlySelectedDatingSmartLink={isCurrentlySelectedDatingSmartLink}
          location={location}
          offerOverlay={offerOverlay}
          offerPopCode={offerPopCode}
          offersCreativesViewMode={offersCreativesViewMode}
          onSmartlinkCopy={onSmartlinkCopy}
          trackingLink={trackingLink}
          userEmail={userEmail}
          values={values}
        />
      </div>
    </>
  );
};

SmartLink.propTypes = {
  affId: PropTypes.string.isRequired,
  creatives: PropTypes.object.isRequired,
  creativesIsFetching: PropTypes.bool.isRequired,
  creativesSearchForm: PropTypes.object,
  creativesSizes: PropTypes.array.isRequired,
  creativesSizesIsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  offerOverlay: PropTypes.any,
  offerPopCode: PropTypes.any,
  offersCreativesViewMode: PropTypes.string.isRequired,
  profileData: PropTypes.any.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  smartLinks: PropTypes.object.isRequired,
  trackingLink: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affId: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.affiliate_id : '',
  creatives: state.creatives.data,
  creativesSearchForm: state.form.creativesSearch || {},
  creativesIsFetching: state.creatives.isFetching,
  creativesSizes: state.creativesSizes.data,
  creativesSizesIsFetching: state.creativesSizes.isFetching,
  listMediaPlacements: state.application.lists.searchFields.MediaPlacements,
  offerOverlay: state.offer.offerOverlay,
  offerPopCode: state.offer.offerPopCode,
  offersCreativesViewMode: state.application.ui.offersCreativesViewMode,
  offerIsFetching: state.offer.offerIsFetching,
  profileData: state?.profile?.data,
  skinConfigurations: state.skinConfigurations.data,
  smartLinks: SMART_LINKS,
  trackingLink: state.offer.trackingLink,
  userEmail: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.email : '',
}))(
  reduxForm(
    {
      form: 'smartLink',
      fields: ['source', 'bo', 'popUnder', 'offer_id', 'target', 'pyt', 'offer'],
      validate,
    },
    () => ({
      initialValues: {
        bo: SMART_LINKS[SMART_LINKS.topLabel].backOffers || undefined,
        popUnder: true,
        offer_id: SMART_LINKS[SMART_LINKS.topLabel].id.toString(),
        target: undefined,
        pyt: 'multi',
      },
    })
  )(SmartLink)
);

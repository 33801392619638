import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FormattedMessage } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

import { StandardButton } from '../../../reusables';

import { Gtm } from '../../../../utils/gtm';

const OfferActionsBar = ({ approvalStatus }) =>
  approvalStatus === 'approved' ? (
    <div id="offer-actions-bar">
      <AnchorLink href="#ad-tools">
        <StandardButton
          classNames={['bg-primary-color', 'active']}
          label={<FormattedMessage {...messages.offerGetAdTools} />}
          onClick={() => Gtm.event('offers', 'Click', 'get ad tools')}
        />
      </AnchorLink>
    </div>
  ) : null;

OfferActionsBar.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
};

export default OfferActionsBar;

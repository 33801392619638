import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyValueWidget, Copy, Checkbox } from '../../reusables';
import { Gtm } from '../../../utils/gtm';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { reduxForm } from 'redux-form';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

import { closeModalAnimation } from '../../Modal/modal-actions/modal-actions';
import useClickOutside from '../../reusables/colorpicker/useClickOutside';
import messages from '../../../i18n/base-en.js';
import { explodeDescription } from '../../../utils/helpers';

import 'materialize-css';
import SelectCustom from '../../reusables/form/SelectCustom';

const OfferShareModal = (props) => {
  const { dispatch, offer, affId, fields, intl, skinConfigurations, modalInner } = props;

  const [offerDescription, setOfferDescription] = useState('');

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);

  const onLinkCopy = () => {
    Gtm.event('UI Component', 'Click', 'Share-Link-Copy-Link');
  };

  const getOfferLink = (offerId, affId) => {
    try {
      return `${window.location.hostname}/offers/${offerId}?r=${affId}`;
    } catch (e) {
      return '';
    }
  };

  const parseOfferDescription = (offer, showAcceptedCountries, formatAcceptedCountries) => {
    const payoutString =
      offer.payout_type !== 'cpa_percentage'
        ? `$${parseFloat(offer.default_payout).toFixed(2)}`
        : `${(parseFloat(offer.percent_payout) || 0).toFixed(2)}%`;
    const landingPages = offer.landing_pages ? offer.landing_pages.filter((landingPage) => landingPage.status === 'active') : [];
    const explodedDescription = explodeDescription(offer.description);

    let seperatorAcceptedCountries = '';
    if (formatAcceptedCountries === '' || formatAcceptedCountries === '1') {
      seperatorAcceptedCountries = ', ';
    } else {
      seperatorAcceptedCountries = '\n';
    }

    const offerDescription = `
${offer.name}
Id: ${offer.id}
Default payout: ${payoutString}
${offer.epc_affiliation ? `EPC: $${parseFloat(offer.epc_affiliation).toFixed(4)}` : ''}
${offer.top_countries ? `Top Performing Countries: ${offer.top_countries}` : ''}
${
  offer.screenshot
    ? `Device: ${Object.keys(offer.screenshot)
        .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
        .join(', ')}`
    : ''
}
${offer.verticals && offer.verticals.length > 0 ? `Vertical: ${offer.verticals.map((ver) => ver.name).join(', ')}` : ''}
${landingPages && landingPages.length > 0 ? `Default Landing Page: ${landingPages[0].screenshot.desktop}` : ''}
${
  explodedDescription && explodedDescription.description
    ? `Offer Description: ${explodedDescription.description
        .replace(/<[^>]*>?/gm, '')
        .replace(/\s+/g, ' ')
        .trim()}`
    : ''
}
${
  showAcceptedCountries && offer.countries.length > 0
    ? `
Accepted countries: 
${offer.countries.map((country) => country.name).join(seperatorAcceptedCountries)}`
    : ''
}
`;
    setOfferDescription(offerDescription.trim().replace(/(^[ \t]*\n)/gm, ''));
  };

  useEffect(() => {
    parseOfferDescription(offer, fields.showAcceptedCountries.checked, fields.formatAcceptedCountries.value);
  }, [fields.formatAcceptedCountries, fields.showAcceptedCountries]);

  const closeOpenModal = () => {
    dispatch(closeModalAnimation());
  };

  useClickOutside(modalInner, closeOpenModal);
  return (
    <div className="share-modal customSelects" id="offer-share-modal">
      <div className="modal-content">
        <span className="modal-action modal-close hov-primary-color" onClick={closeOpenModal}>
          <i className="material-icons">close</i>
        </span>
      </div>
      <div className="fields">
        <div className="row top-content-container">
          <img alt="Featured" className="shareImage" src="/img/presentation-share-offer.png" />
          <div className="row formatted-message">
            <h1>
              <FormattedMessage {...messages.shareOfferTitle} />
            </h1>
            <p className="important">
              <FormattedMessage {...messages.shareIt} />
            </p>
            <p>
              <FormattedMessage {...messages.shareOfferDescriptionPartOne} />
              {SKIN.getPropertyValue('Offers', 'OfferShare', 'userTitle') || 'Crakrevenue affiliate'}
              <FormattedMessage {...messages.shareOfferDescriptionPartTwo} /> <br />
              <a
                href="https://support.crakrevenue.com/knowledge-base/crakrevenue-referral-program/"
                rel="noreferrer"
                target="_blank"
              >
                <FormattedMessage {...messages.moreDetails} />
              </a>
            </p>
            <div className="creatives-tracking-link">
              <CopyValueWidget
                dispatch={dispatch}
                id="smart-link-shareLink"
                onCopy={onLinkCopy()}
                value={getOfferLink(offer.id, affId)}
              />
            </div>
          </div>
        </div>
        {offer.countries.length > 0 && (
          <div className="row row-bottom">
            <div className="col-xs-12">
              <Checkbox
                field={fields.showAcceptedCountries}
                id="share-countries"
                label={messages.showAcceptedCountries.defaultMessage}
              />

              {fields.showAcceptedCountries.checked ? (
                <SelectCustom
                  small
                  label={intl.formatMessage(messages.genericTextFormat)}
                  id="format-accepted-countries"
                  onChange={fields.formatAcceptedCountries.onChange}
                  options={[
                    { value: '1', text: 'CSV' },
                    { value: '2', text: intl.formatMessage(messages.genericTextRawText) },
                  ]}
                  touched={fields.formatAcceptedCountries.touched}
                  showSelectedFieldCheckmark={false}
                  value={fields.formatAcceptedCountries.value || '1'}
                />
              ) : null}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <textarea className="textarea-css" defaultValue={offerDescription} id="custom-css" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Copy
              btnFlat={false}
              buttonStyled
              classNames={['waves-effect', 'waves-light', 'btn', 'btn-copy-information']}
              clipboardTarget="#custom-css"
              dispatch={dispatch}
              icon={null}
              text={<FormattedMessage {...messages.copyInformation} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OfferShareModal.propTypes = {
  affId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  offer: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'share',
    fields: ['showAcceptedCountries', 'formatAcceptedCountries'],
  })(OfferShareModal)
);

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import parse from 'react-html-parser';

const JerkmateContributors = () => {
  const sectionRef = useRef(null);
  const testimonialsRef = useRef([]);

  const [inScreenSection, setInScreenSection] = useState(false);
  const [inScreenTestimonials, setInScreenTestimonials] = useState([]);

  const contributors = [
    {
      name: 'Amouranth',
      text: 'World-renowned streamer <br /> and adult content creator',
    },
    {
      name: 'Hot Haus',
      text: 'With the TV series <br /> Hot Haus Season 2',
    },
    {
      name: 'Amber Alena',
      text: 'With the documentary Cam <br /> Model Confessions',
    },
  ];

  useEffect(() => {
    const removeListener = () => window.removeEventListener('scroll', onScroll);

    const onScroll = () => {
      if (!sectionRef.current) {
        return;
      }

      const headerHeight = document.getElementById('site-header').offsetHeight;
      const innerViewportHeight = (window.innerHeight - headerHeight) / 2 - 50;

      let sectionHeight = sectionRef.current.offsetHeight / 2;
      sectionHeight = sectionHeight > innerViewportHeight ? innerViewportHeight : sectionHeight;

      const sectionPosition = sectionRef.current.getBoundingClientRect().top - window.innerHeight + sectionHeight;

      if (testimonialsRef?.current?.length) {
        testimonialsRef?.current.forEach((testimonial, index) => {
          const height = testimonial.offsetHeight;
          const distance = testimonial.getBoundingClientRect().top - window.innerHeight + height + 50;

          if (distance < 0) {
            setInScreenTestimonials((testimonials) => {
              if (testimonials.includes(index)) {
                return testimonials;
              }

              return [...testimonials, index];
            });
          }
        });
      }

      if (sectionPosition < 0) {
        setInScreenSection(true);
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    onScroll();
    return () => removeListener();
  }, []);

  return (
    <div className={`contributors-section ${inScreenSection ? 'active' : ''}`} ref={sectionRef}>
      <h2 className="jerkmate-page-title">They Have Contributed to Jerkmate’s Notoriety</h2>
      <div className="contributors-list">
        {contributors.map((contributor, index) => (
          <div
            className={`contributor ${inScreenTestimonials.includes(index) ? 'active' : ''}`}
            key={index}
            ref={(div) => (testimonialsRef.current[index] = div)}
          >
            <img src={`/img/testimonlial${index}.png`} alt={contributor.name} />
            <div className="content">
              <h3>{contributor.name}</h3>
              <p>{parse(contributor.text)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JerkmateContributors;

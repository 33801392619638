import PropTypes from 'prop-types';

import OfferActionsBar from '../OfferActionsBar/OfferActionsBar.react';
import OfferNavigationBar from '../OfferNavigationBar/OfferNavigationBar.react';
import OfferStatusBar from '../OfferStatusBar/OfferStatusBar.react';

const OfferHeader = ({ dispatch, approvalStatus, offerIsFetching }) => (
  <div id="offer-header">
    <div className="container-fluid app-container">
      <div className="navbar">
        <div className="left-section">
          <OfferNavigationBar dispatch={dispatch} />
          <OfferStatusBar approvalStatus={approvalStatus} offerIsFetching={offerIsFetching} />
        </div>
        <div className="right-section">
          <OfferActionsBar approvalStatus={approvalStatus} />
        </div>
      </div>
    </div>
  </div>
);

OfferHeader.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
  offerIsFetching: PropTypes.bool.isRequired,
};

export default OfferHeader;

export default {
  smartLinkCreate: {
    id: 'smartLink.create',
    description: 'Create Your SmartLink!',
    defaultMessage: 'Create Your SmartLink!',
  },
  smartLinkSelectTrafficType: {
    id: 'smartLink.selectTrafficType',
    description: 'Select the type of traffic, add a traffic source and copy/paste the link on your website.',
    defaultMessage: 'Select the type of traffic, add a traffic source and copy/paste the link on your website.',
  },
  smartLinkSelectTrafficSource: {
    id: 'smartLink.selectTrafficSource',
    description: 'Select your traffic source',
    defaultMessage: 'Select your traffic source',
  },
  smartLinkAdvancedSettings: {
    id: 'smartLink.advancedSettings',
    description: 'Advanced Settings',
    defaultMessage: 'Advanced Settings',
  },
  smartLinkCustomize: {
    id: 'smartLink.customize',
    description: 'Customize your SmartLink',
    defaultMessage: 'Customize your SmartLink',
  },
  smartLinkExtra: {
    id: 'smartLink.extra',
    description: 'Extra earnings',
    defaultMessage: 'Extra earnings',
  },
  smartLinkDisabled: {
    id: 'smartLink.disabled',
    description: 'Disabled when slider is grey',
    defaultMessage: 'Disabled when slider is grey',
  },
  smartLinkSelectAdTools: {
    id: 'smartLink.selectAdTools',
    description: 'Select your AdTool',
    defaultMessage: 'Select your AdTool',
  },
  smartLinkYourLink: {
    id: 'smartLink.yourLink',
    description: 'Your link',
    defaultMessage: 'Your link',
  },
  smartLinkAdTools: {
    id: 'smartLink.adTools',
    description: 'AdTools',
    defaultMessage: 'AdTools',
  },
  smartLinkGetStarted: {
    id: 'smartLink.getStarted',
    description: 'The EASIEST WAY to GET STARTED',
    defaultMessage: 'The EASIEST WAY to GET STARTED',
  },
  smartLinkSmartLinks: {
    id: 'smartLink.smartLinks',
    description: 'SmartLinks',
    defaultMessage: 'SmartLinks',
  },
  smartLinkFastAndEasy: {
    id: 'smartLink.fastAndEasy',
    description: "It's FAST and EASY",
    defaultMessage: "It's FAST and EASY",
  },
  smartLinkSelectYourTraffic: {
    id: 'smartLink.selectYourTraffic',
    description: 'Select the type of traffic you have',
    defaultMessage: 'Select the type of traffic you have',
  },
  smartLinkAddTrafficSource: {
    id: 'smartLink.addTrafficSource',
    description: 'Add a traffic source <span>(it will come in handy analyzing your stats)</span>',
    defaultMessage: 'Add a traffic source <span>(it will come in handy analyzing your stats)</span>',
  },
  smartLinkCopyPaste: {
    id: 'smartLink.copyPaste',
    description: 'COPY / PASTE the link on your website',
    defaultMessage: 'COPY / PASTE the link on your website',
  },
  smartLinkWeTakeCare: {
    id: 'smartLink.weTakeCare',
    description: 'We take care of the rest!',
    defaultMessage: 'We take care of the rest!',
  },
  smartLinkSource: {
    id: 'smartLink.subId',
    description: 'Add source to your tracking link',
    defaultMessage: 'Add source to your tracking link',
  },
  smartLinkPleaseNote: {
    id: 'smartLink.pleaseNote',
    description:
      '<span><strong>Please note :</strong> the information you provide below is indispensable to us and is an important indicator in determining whether or not you are a good fit for our network. Please be accurate and honest. Thank you!</span>',
    defaultMessage:
      '<span><strong>Please note :</strong> the information you provide below is indispensable to us and is an important indicator in determining whether or not you are a good fit for our network. Please be accurate and honest. Thank you!</span>',
  },
  smartLinkRequestApprovalNow: {
    id: 'smartLink.requestApprovalNow',
    description: 'Request approval now',
    defaultMessage: 'Request approval now',
  },
  smartLinkApprovalPending: {
    id: 'smartLink.approvalPending',
    description: 'Your application for "{name}" is pending.',
    defaultMessage: 'Your application for "{name}" is pending.',
  },
  smartLinkIsRestricted: {
    id: 'smartLink.isRestricted',
    description: 'This smartLink is available on request only',
    defaultMessage: 'This smartLink is available on request only',
  },
  smartLinkRequestSent: {
    id: 'smartLink.requestSent',
    description: 'Your request has been sent!',
    defaultMessage: 'Your request has been sent!',
  },
  smartLinkLabel: {
    id: 'smartLink.smartlinkLabel',
    description: 'SmartLink',
    defaultMessage: 'SmartLink',
  },
  smartLinkPromotionMethodLabel: {
    id: 'smartLink.promotionMethod',
    description: 'Promotion Method',
    defaultMessage: 'Promotion Method',
  },
  smartLinkPayoutTypeLabel: {
    id: 'smartLink.payoutType',
    description: 'Payout Type',
    defaultMessage: 'Payout Type',
  },
};

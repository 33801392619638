const KnowledgeBase = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 206"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_206"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="knowledge-base" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 202" fill="none" height="30" id="Rectangle_202" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 135" fill="none" id="Ellipse_135" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 276" fill="none" id="Ligne_276" x2="30" y2="30" />
          <line data-name="Ligne 277" fill="none" id="Ligne_277" x1="30" y2="30" />
          <line data-name="Ligne 278" fill="none" id="Ligne_278" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 279" fill="none" id="Ligne_279" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 280" fill="none" id="Ligne_280" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 281" fill="none" id="Ligne_281" transform="translate(15)" y2="30" />
          <line data-name="Ligne 282" fill="none" id="Ligne_282" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 283" fill="none" id="Ligne_283" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 203"
            fill="none"
            height="21.218"
            id="Rectangle_203"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 136"
            fill="none"
            id="Ellipse_136"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 204"
            fill="none"
            height="15.004"
            id="Rectangle_204"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 137"
            fill="none"
            id="Ellipse_137"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <path
            d="M15.335,17.371a5.911,5.911,0,1,0-5.574-.031l.056.037V20.84h5.518Z"
            data-name="Tracé 1535"
            fill="none"
            id="Tracé_1535"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(2.424 2.266)"
          />
          <path
            d="M8.977,15.67h5.518v1.367a.364.364,0,0,1-.364.364H9.34a.364.364,0,0,1-.364-.364V15.67Z"
            data-name="Tracé 1536"
            fill="none"
            id="Tracé_1536"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(3.264 5.698)"
          />
          <rect
            data-name="Rectangle 205"
            fill="none"
            height="2.106"
            id="Rectangle_205"
            rx="0.259"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(13.274 23.1)"
            width="3.453"
          />
          <line
            data-name="Ligne 284"
            fill="none"
            id="Ligne_284"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(5.319 14.525)"
            x1="2.278"
          />
          <line
            data-name="Ligne 285"
            fill="none"
            id="Ligne_285"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(22.403 14.525)"
            x1="2.278"
          />
          <line
            data-name="Ligne 286"
            fill="none"
            id="Ligne_286"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(20.34 7.699)"
            x1="1.611"
            y2="1.612"
          />
          <line
            data-name="Ligne 287"
            fill="none"
            id="Ligne_287"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(8.291 7.699)"
            x2="1.611"
            y2="1.612"
          />
          <line
            data-name="Ligne 288"
            fill="none"
            id="Ligne_288"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(15 4.763)"
            y2="2.278"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default KnowledgeBase;

export default {
  crakrevenue: 'CrakRevenue',
  mfc: 'MFC',
  whaleshub: 'WhalesHub',
  'genericText.active': 'Active',
  'genericText.add': 'Add',
  'genericText.moreColumns': 'More Columns',
  'genericText.moreDataOptions': 'More Data Options',
  'genericText.moreFilters': 'More Filters',
  'genericText.alreadyHaveAnAccount': 'Already have an account?',
  'genericText.address': 'Address',
  'genericText.alignment': 'Alignment',
  'genericText.amount': 'Amount',
  'genericText.applyChanges': 'Apply',
  'genericText.refresh': 'Refresh',
  'genericText.asian': 'Asian',
  'genericText.apps': 'Apps',
  'genericText.backgroundCheck': 'Background Check',
  'genericText.back': 'Back',
  'genericText.background': 'Background',
  'genericText.BBW': 'BBW',
  'genericText.binaryOptions': 'Binary Options',
  'genericText.both': 'Both',
  'genericText.brand': 'Brand',
  'genericText.brands': 'Brands',
  'genericText.border': 'Border',
  'genericText.cams': 'Cams',
  'genericText.cancel': 'Annuler',
  'genericText.cannotReachTheServer': 'Cannot reach the server',
  'genericText.cards': 'Cards',
  'genericText.choose': 'Choose',
  'genericText.availableOnOtherTemplates': 'Disponible dans un autre template',
  'genericText.colorNumber': 'Color {number}',
  'genericText.casino': 'Casino',
  'genericText.clearAllFilters': 'Clear all filters',
  'genericText.resetAllFilters': 'Reset all filters',
  'genericText.closeFilters': 'Close Filters',
  'genericText.collapsed': 'Fermé',
  'genericText.city': 'Ville',
  'genericText.companyName': 'Company name',
  'genericText.continue': 'Continue',
  'genericText.creatives': 'Creatives',
  'genericText.textCopied': 'The text: "{text}" has been added to clipboard',
  'genericText.copy': 'Copy',
  'genericText.send': 'Send',
  'genericText.country': 'Country',
  'genericText.currentBalance': 'Current balance',
  'genericText.custom': 'Custom',
  'genericText.comments': 'Comments',
  'genericText.date': 'Date',
  'genericText.dating': 'Dating',
  'genericText.delete': 'Delete',
  'genericText.desktop': 'Desktop',
  'genericText.details': 'Details',
  'genericText.display': 'Display',
  'genericText.ebony': 'Ebony',
  'genericText.entertainment': 'Entertainment',
  'genericText.ECPM': 'ECPM',
  'genericText.email': 'Courriel',
  'genericText.english': 'Anglais',
  'genericText.expanded': 'Ouvert',
  'genericText.facebook': 'Facebook',
  'genericText.filter': 'Filter',
  'genericText.filters': 'Filters',
  'genericText.filterBy': 'Filter by:',
  'genericText.firstName': 'Prénom',
  'genericText.french': 'Français',
  'genericText.gaming': 'Gaming',
  'genericText.gambling': 'Gambling',
  'genericText.general': 'General',
  'genericText.goal': 'Goal',
  'genericText.goals': 'Goals',
  'genericText.hardcore': 'Hardcore',
  'genericText.health': 'Health',
  'genericText.Id': 'Id',
  'genericText.info': 'Info',
  'genericText.instagram': 'Instagram',
  'genericText.introduction': 'Introduction',
  'genericText.invoices': 'Invoices',
  'genericText.langue': 'English',
  'genericText.language': 'Language',
  'genericText.languages': 'Languages',
  'genericText.last7Days': 'Last 7 days',
  'genericText.lastMonth': 'Last month',
  'genericText.lastYear': 'Last year',
  'genericText.allTime': 'All time',
  'genericText.lastName': 'Nom de famille',
  'genericText.link': 'Link',
  'genericText.list': 'List',
  'genericText.login': 'Login',
  'genericText.logo': 'Logo',
  'genericText.signUp': 'Sign Up',
  'genericText.logout': 'Logout',
  'genericText.maleEnhancement': 'Male Enhancement',
  'genericText.manualSelection': 'Manual selection',
  'genericText.maximum': 'Maximum',
  'genericText.minimum': 'Minimum',
  'genericText.menu': 'Menu',
  'genericText.menuDisplay': 'Menu display',
  'genericText.milf': 'Milf',
  'genericText.mixed': 'Mixed',
  'genericText.minimumPayout': 'Minimum payout',
  'genericText.mobile': 'Mobile',
  'genericText.mobileApps': 'Mobile Apps',
  'genericText.name': 'Name',
  'genericText.niche': 'Niche',
  'genericText.nutras': 'Nutras',
  'genericText.nutrition': 'Nutrition',
  'genericText.approvals': 'Approvals',
  'genericText.nextPayment': 'Next payment',
  'genericText.lastPayment': 'Last payment',
  'genericText.no': 'No',
  'genericText.offers': 'Offers',
  'genericText.myOffers': 'My Offer Requests',
  'genericText.myOffersDetails':
    'All offers displayed here are offers that require an approval. You can filter by approval status by using the "Status" filter. For offers that are enabled by default and do not require approval, please refer to the ',
  'genericText.myOffersLink': 'Offers section',
  'genericText.on': 'on',
  'genericText.or': 'or',
  'genericText.optionalInput': '(Optional)',
  'genericText.organization': 'Organisation',
  'genericText.other': 'Other',
  'genericText.password': 'Password',
  'genericText.currentPassword': 'Current Password',
  'genericText.payment': 'Payment',
  'genericText.payments': 'Payments',
  'genericText.payout': 'Payout',
  'genericText.paysites': 'Paysites',
  'genericText.pending': 'Pending',
  'genericText.approved': 'Approved',
  'genericText.rejected': 'Rejected',
  'genericText.NotYetApplied': 'Not Yet Applied',
  'genericText.pleaseSpecify': 'Please specify',
  'genericText.PPL': 'PPL',
  'genericText.PPS': 'PPS',
  'genericText.preview': 'Preview',
  'genericText.previous': 'Previous',
  'genericText.province': 'Province',
  'genericText.rating': 'Rating',
  'genericText.format': 'Format',
  'genericText.reddit': 'Reddit',
  'genericText.region': 'Region',
  'genericText.report': 'Report',
  'genericText.rewards': 'Rewards',
  'genericText.reportName': 'Report name',
  'genericText.revshare': 'Revshare',
  'genericText.save': 'Save',
  'genericText.saveChanges': 'Save changes',
  'genericText.search': 'Search',
  'genericText.settings': 'Settings',
  'genericText.signIn': 'Sign in',
  'genericText.size': 'Size',
  'genericTextIframeSize.size': 'Dimensions du Iframe',
  'genericText.skype': 'Skype',
  'genericText.snapchat': 'Snapchat',
  'genericText.state': 'State',
  'genericText.status': 'Status',
  'genericText.paid': 'Paid',
  'genericText.carriedOver': 'Carried Over',
  'genericText.streetAddress': 'Street address',
  'genericText.success': 'Success',
  'genericText.surveys': 'Surveys',
  'genericText.Tags': 'Tags',
  'genericText.targeting': 'Targeting',
  'genericText.taxId': 'Tax ID',
  'genericText.teens': 'Teens',
  'genericText.termsAndConditions': 'Terms & conditions',
  'genericText.titleInOrganisation': 'Title / Function in organisation',
  'genericText.toggleMenu': 'Fermer menu',
  'genericText.twitter': 'Twitter',
  'genericText.type': 'Type',
  'genericText.certical': 'Vertical',
  'genericText.viewMode': 'View mode',
  'genericText.viewSite': 'View site',
  'genericText.website': 'Website',
  'genericText.yes': 'Yes',
  'genericText.postalCode': 'Postal code',
  'genericText.zipCode': 'Zip code',
  'genericText.payoutType': 'Payout Type',
  'genericText.seeDetails': 'See Details',
  'genericText.all': 'All',
  'genericText.default': 'Default',
  'genericText.agree': 'Agree',
  'genericText.reset': 'Reset',
  'genericText.selectAll': 'Select All',
  'genericText.unselectAll': 'Unselect All',
  'genericText.payoutTypesCPABoth': 'CPA Both',
  'genericText.payoutTypesCPAFlat': 'CPA Flat',
  'genericText.payoutTypesCPAPercentage': 'CPA Percentage',
  'genericText.privacyPolicy': 'Privacy policy',
  'genericText.FAQ': 'FAQ',
  'genericText.Support': 'Support',
  'genericText.noFraudOrAbuse':
    'I understand that my account will be terminated and all commissions forfeited if I fraudulently generate leads, sales, or spam.',
  'genericText.blog': 'Blog',
  'genericText.backOffers': 'Back Offers',
  'genericText.popUnders': 'Pop-Unders',
  'genericText.today': 'Today',
  'genericText.yesterday': 'Yesterday',
  'genericText.thisPeriod': 'This period',
  'genericText.lastPeriod': 'Last period',
  'genericText.imAn': "I'm an",
  'genericText.imA': "I'm a",
  'genericText.advertiser': 'advertiser',
  'genericText.affiliate': 'affiliate',
  'genericText.iGotIt': 'I got it!',
  'genericText.dontRemindMe': "Don't remind me",
  'genericText.sorry': 'Sorry',
  'genericText.affiliateAccountUpdate': 'Profile Update',
  'genericText.notifications': 'Notifications',
  'genericText.approvalRequired': 'Approval Required',
  'genericText.moreInfosOnText': 'More infos on {text}',
  'genericText.sorryText': 'This feature is only available on desktop. Please connect on a larger device to use this section.',
  'genericText.sorryTextDesktop':
    'This feature is only accessible on larger screens. Therefore, we invite you to either resize your browser or log in from a larger device.',
  'genericText.audience': 'Audience',
  'genericText.show': 'Show',
  'genericText.text': 'Text',
  'genericText.Rewards': 'Rewards',
  'genericText.step': 'Step',
  'genericText.SmartLink': 'SmartLink',
  'genericText.yourCode': 'Your code',
  'genericText.yourLink': 'Your link',
  'genericText.tryItNow': 'Try it now',
  'genericText.networkName': 'Network name',
  'genericText.payoutDetails': 'Payout details',
  'genericText.promoCode': 'Promo code',
};

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import HomeRankingRank from './ranking/HomeRankingRank.react';
import HomeRankingNotRanked from './ranking/HomeRankingNotRanked.react';
import {
  isActiveRankFromRankStatusName,
  isEliteRankFromRankStatusName,
  isRisingStarRankFromRankStatusName,
  isVIPRankFromRankStatusName,
} from '../../utils/rankingHelper';

const HomeRanking = ({ affStatus, affRank, skinConfigurations }) => {
  const isAffRank = isActiveRankFromRankStatusName(affStatus.rank_status_name);
  const isRisingStarRank = isRisingStarRankFromRankStatusName(affStatus.rank_status_name);
  const isVIPRank = isVIPRankFromRankStatusName(affStatus.rank_status_name);
  const isEliteRank = isEliteRankFromRankStatusName(affStatus.rank_status_name);

  console.log('HomeRanking', affRank, affStatus);
  return (
    <div id="home-ranking">
      <div className="row">
        <div className="col-xs-12">
          {affRank && affStatus ? (
            <HomeRankingRank
              affRank={affRank}
              affStatus={affStatus}
              isAffRank={isAffRank}
              isEliteRank={isEliteRank}
              isRisingStarRank={isRisingStarRank}
              isVIPRank={isVIPRank}
              skinConfigurations={skinConfigurations}
            />
          ) : (
            <HomeRankingNotRanked skinConfigurations={skinConfigurations} />
          )}
        </div>
      </div>
    </div>
  );
};

HomeRanking.propTypes = {
  affRank: PropTypes.object.isRequired,
  affStatus: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default injectIntl(HomeRanking);

import TagManager from 'react-gtm-module';

import config from '../config';
import createStore from '../create-store';

const { UA_ID, OPT_ID, GTM_ID, GA4_ID } = config;
export class Gtm {
  static getAffId() {
    const state = createStore.getState();
    return state.profile.data.affUserInfos.affiliate_id;
  }

  //don't use this one anymore, use newEvent instead
  static event(eventCategory, eventAction, eventLabel, value, otherVars = {}) {
    if (!!eventCategory && !!eventAction && typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'UA_CREvent',
        affId: Gtm.getAffId(),
        customEvent: {
          eventCategory,
          eventAction,
          eventLabel,
          value,
          ...otherVars,
        },
      });
    }
  }

  static newEvent(eventName, conversion, eventParamsName, eventParamsType, eventParamsValue) {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        conversion: conversion,
        affId: Gtm.getAffId(),
        event_params: {
          event_name: eventParamsName,
          type: eventParamsType,
          value: eventParamsValue,
        },
      });
    }
  }

  static searchOffer(searchTerm, searchCategory) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'UA_CRData',
        affId: Gtm.getAffId(),
        customPage: [
          `/offers-result/?`,
          searchTerm ? `s=${searchTerm}` : undefined,
          !!searchCategory && searchCategory.length > 0 ? `&c=${searchCategory.join(' | ')}` : undefined,
        ].join(''),
      });
    }
  }

  static loadGTMScript() {
    if (window.performance) {
      window.dataLayer = window.dataLayer || [];
      const tagManagerArgs = {
        gtmId: GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
      window.dataLayer.push({
        event: 'UA_CRData',
        affId: Gtm.getAffId(),
        GA4_ID: GA4_ID,
        UA_ID: UA_ID,
        optimizeContainerId: OPT_ID,
      });
    }
  }
}

const gtm = new Gtm();
export default gtm;

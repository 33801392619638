import consts from '../utils/consts';
import qs from 'qs';
import { setCookie, getCookie } from '../utils/helpers';
import config from '../config';

const { COOKIES_GET_PARAMS } = consts;
const { API_URL } = config;
export default () => {
  const parsedUrl = qs.parse(window.location.search.replace('?', ''));

  COOKIES_GET_PARAMS.map(({ cookieName, getParam, overrideLastOne, ttl }) => {
    if (Object.keys(parsedUrl).indexOf(getParam) >= 0) {
      const lastCookie = overrideLastOne ? null : getCookie(cookieName);
      if (overrideLastOne || !lastCookie) {
        setCookie(cookieName, parsedUrl[getParam], ttl);
      }
    }
  });
  fetch(`${API_URL}/csrf`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((data) => {
      setCookie('XSRF-TOKEN', data.csrfToken);
    });
};

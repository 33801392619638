export default {
  'offer.acceptedCountries': 'Accepted Countries',
  'offer.addYourSubId1': 'Add Your Sub ID 1',
  'offer.addYourSubId2': 'Add Your Sub ID 2',
  'offer.addYourSubId3': 'Add Your Sub ID 3',
  'offer.addYourSubId4': 'Add Your Sub ID 4',
  'offer.addYourSubId5': 'Add Your Sub ID 5',
  'offer.typeYourPostbackURLHere': 'Type your postback URL here',
  'offer.addYourTrackingLink': 'Add source to your tracking link',
  'offer.autoOptimized': 'Auto-optimized',
  'offer.chooseLandingPage': 'Choose Landing Page',
  'offer.chooseYourGoal': 'Choose your Goal',
  'offer.creativesFilters': 'Creatives Filters',
  'offer.noPostback': 'No postbacks defined',
  'offer.noCreatives': 'No Creatives Selected',
  'offer.creativesSelected': 'Creatives Selected',
  'offer.postbackOptions': 'Postbacks Options',
  'offer.linkCustomization': 'Link Customization',
  'offer.yourTrackingLink': 'Your tracking link',
  'offer.crFeaturedOnly': 'Top Offers',
  'offer.rowsPerPage': 'Results per Page',
  'offer.conversionFlow': 'Conversion Flow',
  'offer.createPostback': 'Create Postback',
  'offer.allCountriesAccepted': 'All Countries Accepted',
  'offer.agreeToTerms': 'First, you must agree to the following Terms & Conditions',
  'offer.sendSelection': 'Send Selection',
  'offer.areYouSureToDeletedPostback': 'Are you sure to delete this Postback?',
  'offer.postbackDeleted': 'Postback deleted',
  'offer.isRestricted': 'This offer is restricted to approved affiliates only',
  'offer.requestApprovalNow': 'Request approval now',
  'offer.approvalPending': 'Your application for this offer is pending.',
  'offer.approvalRejected':
    'This offer is restricted to approved affiliates only. Your application for this offer has been rejected.',
  'offer.requestQuestion':
    "Please give us an estimation of the amount of traffic you'd be able to send to this offer (and please also include the source of where this traffic will be coming from).",
  'offer.requestSent': 'Your request has been sent!',
  'offer.addOptionalVariables': 'Add Optional Variables',
  'offer.backToList': 'Back To Offer List',
  'offer.payoutTiers': 'Payout Tiers',
  'offer.postbackAdded': 'Postback added',
  'offer.willBeDisplayed': 'It will be displayed behind all your creatives.',
  'offer.banner': 'Banner',
  'offer.htmlAds': 'HTML Ads',
  'offer.link': 'Link',
  'offer.email': 'Email',
  'offer.iframe': 'IFrame',
  'offer.pleaseSelectACreativeType': 'Please Select a Creative Type',
  'offer.bannerFilters': 'Banner Filters',
  'offer.htmlAdsFilters': 'HTML Ads Filters',
  'offer.templatesFilters': 'Templates Filters',
  'offer.browseOffers': 'Browse Offers',
  'offer.mainstream': 'Mainstream (G)',
  'offer.adult': 'Adult (18+)',
  'offer.globalAdult': 'Global Adult (18+)',
  'offer.cam': 'Cam (18+)',
  'offer.dating': 'Dating (18+)',
  'offer.vod': 'VOD (18+)',
  'offer.mobileCarrier': 'Mobile Carrier (18+)',
  'offer.gay': 'Gay (18+)',
  'offer.carrierBilling': 'Carrier Billing Optimized (18+)',
  'offer.adultGaming': 'Gaming (18+)',
  'offer.gaming': 'Gaming (G)',
  'offer.trafficType': 'Traffic Type',
  'offer.copySmartLink': 'Copy SmartLink',
  'offer.fastAndEasy':
    "It's FAST and EASY! Just select the type of traffic you have, add a traffic source (it will come in handy analyzing your stats) and COPY/PASTE the link on your website. We take care of the rest! Money will begin flowing directly into your pocket!",
  'offer.placementOptions': 'Placement options',
  'offer.copyCode': 'Copy Code',
  'offer.copyUrl': 'Copy Url',
  'offer.downloadFile': 'Download File',
  'offer.downloadAll': 'Download All',
  'offer.popCode': 'Pop Code',
  'offer.noPopCode': 'No pop code available for this offer',
  'offer.copyPopCode': 'Copy Pop Code',
  'offer.noIframeCode': 'No iFrame code available for this offer',
  'offer.copyIframeCode': 'Copy iFrame Code',
  'offer.iframeCode': 'IFrame Code',
  'offer.noCreativesAvailable': 'No creatives available',
  'offer.creativesWasSent': 'The creatives you selected was successfully sent to your email.',
  'offer.emailInstructions': 'Email Instructions',
  'offer.emailInstructionsText1':
    'Our pre-designed templates for your email marketing campaigns are a great way to target and reach greater sources of traffic.',
  'offer.emailInstructionsText2':
    'Please make sure to remove all non-accepted & unsubscribed users from your Mailing list before you hit send.',
  'offer.approvedFromLines': 'Approved From Lines',
  'offer.approvedSubjectLines': 'Approved Subject Lines',
  'offer.suppressionList': 'Suppression List',
  'offer.mergeList': "IMPORTANT: Scrub your list with this one so you don't send emails to users who have unsubscribed.",
  'offer.audienceOffersG': 'G offers',
  'offer.audienceOffers18': '18+ offers',
  'offer.notFound': 'Offer not found',
  'offer.sorryNotAvailable': 'Sorry, this offer is no longer available or does not exist',
  'offer.legalNotice': 'Legal Notice',
  'offer.legalNoticeText':
    'According to section 8 of CrakRevenue’s <a href="https://www.crakrevenue.com/tos/" target="_blank">Terms of Service</a>, all affiliates shall comply with all applicable international, federal and state laws including, but not limited to, the CAN-SPAM Act of 2003.',
  'offer.mandatoryProcess': 'Mandatory Process',
  'offer.beforeSendingYourEmail': 'Before sending your email campaings, be sure to <strong>do the following steps</strong>:',
  'offer.copyTheHtmlCode': 'Copy this HTML Template code & paste it into your email provider’s outbox:',
  'offer.copyAndPasteUnsubscribeLink':
    'Copy & Paste this <strong>Unsubscribe Link</strong> into the footer of your HTML template: ',
  'offer.useOurSupression':
    'Use our <strong>Suppression List</strong>, or the <strong>Scrubbing tool</strong>, to remove all non-accepted users from your Mailing list.',
  'offer.useSupressionList': 'Use supression list',
  'offer.imDone': "I'm done",
  'offer.noEmailTemplates': "There's no email template for this offer",
  'offer.recentlyAdded': 'Latest Offers Added',
  'offer.shareOfferDescriptionPartOne': 'To top it off, if they are not already a ',
  'offer.shareOfferDescriptionPartTwo': ', you could earn a cool 5% referral commission. ',
};

import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import apiqlFactory from '../../../utils/apiqlFactory';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const { API_URL, TOPOFFER_MFC_ORDER } = config;
const {
  ACTIONS: { TOPOFFERS },
  TOP_OFFERS_OPTIONS,
} = consts;

export const changeTopOffersPage = (newPage) => ({
  type: TOPOFFERS.TOP.CHANGE_PAGE,
  newPage,
});
export const changeMfcOffersPage = (newPage) => ({
  type: TOPOFFERS.MFC.CHANGE_PAGE,
  newPage,
});

const requestTopOffers = (req) => ({
  type: TOPOFFERS.TOP.REQUEST_OFFERS,
  request: req,
});
const receiveTopOffers = (payload) => ({
  type: TOPOFFERS.TOP.RECEIVE_OFFERS,
  payload,
});
const failedToReceiveTopOffers = () => ({
  type: TOPOFFERS.TOP.FAILED_TO_RECEIVE_OFFERS,
});
export const fetchTopOffers =
  (search = {}) =>
  (dispatch) =>
    new Promise((fulfill, reject) => {
      const topSearched = {
        ...consts.OFFERS_SEARCH_INITALSTATE,
        ...TOP_OFFERS_OPTIONS.topDefaultOptions,
        ...search,
      };
      const offerRequest = request
        .post(`${API_URL}/apiql`)
        .query({
          query: apiqlFactory({ TopOffers: topSearched }),
        })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((err, res) => {
          if (!err) {
            const data = res.body.data;
            data.Search = topSearched;
            dispatch(receiveTopOffers(data));
            fulfill();
          } else {
            dispatch(failedToReceiveTopOffers());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        });

      dispatch(requestTopOffers(offerRequest));
    });

const requestRecentOffers = (req) => ({
  type: TOPOFFERS.RECENT.REQUEST_OFFERS,
  request: req,
});
const receiveRecentOffers = (payload) => ({
  type: TOPOFFERS.RECENT.RECEIVE_OFFERS,
  payload,
});
const failedToReceiveRecentOffers = () => ({
  type: TOPOFFERS.RECENT.FAILED_TO_RECEIVE_OFFERS,
});
export const fetchRecentOffers =
  (search = {}) =>
  (dispatch) =>
    new Promise((fulfill, reject) => {
      const recentSearched = {
        ...consts.OFFERS_SEARCH_INITALSTATE,
        ...TOP_OFFERS_OPTIONS.recentDefaultOptions,
        ...search,
      };
      const offerRequest = request
        .post(`${API_URL}/apiql`)
        .query({
          query: apiqlFactory({ TopOffers: recentSearched }),
        })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((err, res) => {
          if (!err) {
            const data = res.body.data;
            data.Search = recentSearched;
            dispatch(receiveRecentOffers(data));
            fulfill();
          } else {
            dispatch(failedToReceiveRecentOffers());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        });
      dispatch(requestRecentOffers(offerRequest));
    });

const requestMfcOffers = (req) => ({
  type: TOPOFFERS.MFC.REQUEST_OFFERS,
  request: req,
});
const receiveMfcOffers = (payload) => ({
  type: TOPOFFERS.MFC.RECEIVE_OFFERS,
  payload,
});
const failedToReceiveMfcOffers = () => ({
  type: TOPOFFERS.MFC.FAILED_TO_RECEIVE_OFFERS,
});
export const fetchMfcOffers =
  (search = {}) =>
  (dispatch) =>
    new Promise((fulfill, reject) => {
      const topSearched = {
        ...consts.OFFERS_SEARCH_INITALSTATE,
        ...TOP_OFFERS_OPTIONS.mfcDefaultOptions,
        ...search,
      };
      const offerRequest = request
        .post(`${API_URL}/apiql`)
        .query({
          query: apiqlFactory({ TopOffers: topSearched }),
        })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((err, res) => {
          if (!err) {
            const data = res.body.data;
            data.Search = topSearched;

            // order the offers
            const offersInOrder = [];
            const offersRest = data.Offers ? data.Offers.Data.filter((obj) => !TOPOFFER_MFC_ORDER.includes(obj.id)) : [];

            TOPOFFER_MFC_ORDER.forEach((id) => {
              const offer = data.Offers ? data.Offers.Data.find((obj) => obj.id === id) : [];
              if (offer) {
                offersInOrder.push(offer);
              }
            });
            if (data.Offers) {
              data.Offers.Data = [...offersInOrder, ...offersRest];
            }

            dispatch(receiveMfcOffers(data));
            fulfill();
          } else {
            dispatch(failedToReceiveMfcOffers());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        });

      dispatch(requestMfcOffers(offerRequest));
    });

export const fetchTopRecentOffers = (search) => (dispatch) =>
  Promise.all([dispatch(fetchTopOffers(search)), dispatch(fetchRecentOffers(search))]);

import PropTypes from 'prop-types';
import Creatives from '../../components/reusables/Creatives';
import config from '../../config';
const { SMARTLINK_CHAT_HEAD_VERTICALS } = config;

const SmartLinkAdTool = ({
  affId,
  creatives,
  creativesIsFetching,
  creativesSearchForm,
  creativesSizes,
  creativesSizesIsFetching,
  dispatch,
  location,
  offerOverlay,
  offerPopCode,
  offersCreativesViewMode,
  onSmartlinkCopy,
  trackingLink,
  values,
  userEmail,
  isCurrentlySelectedCamSmartLink,
  isCurrentlySelectedDatingSmartLink,
}) => {
  const isSmartlinkDatingOrCam =
    isCurrentlySelectedCamSmartLink(values.offer_id) || isCurrentlySelectedDatingSmartLink(values.offer_id);

  const tabs = {
    banner: {
      eventLabel: 'Console-Smartlink-Banner-Tab',
      show: true,
    },
    content: {
      eventLabel: 'Console-Smartlink-Content-Tab',
      show: false,
    },
    email: {
      eventLabel: 'Console-Smartlink-Email-Tab',
      show: false,
    },
    html: {
      eventLabel: 'Console-Smartlink-HTML-Tab',
      show: true,
    },
    iframe: {
      eventLabel: 'Console-Smartlink-IFrame-Tab',
      show: true,
    },
    link: {
      eventLabel: 'Console-Smartlink-Link-Tab',
      show: true,
    },
    overlay: {
      eventLabel: 'Console-Smartlink-Overlay-Tab',
      show: false,
    },
    pop: {
      eventLabel: 'Console-Smartlink-Pop-Tab',
      show: true,
    },
    chatHead: {
      eventLabel: 'Console-Smartlink-ChatHead-Tab',
      show: isSmartlinkDatingOrCam,
    },
  };

  return (
    <div id="smart-link-adtool">
      <Creatives
        affId={affId}
        creatives={creatives}
        creativesIsFetching={creativesIsFetching}
        creativesSearchForm={creativesSearchForm}
        creativesSizes={creativesSizes}
        creativesSizesIsFetching={creativesSizesIsFetching}
        defaultTab="link"
        dispatch={dispatch}
        displaySubHeaders
        location={location}
        offerId={values.offer_id}
        offerOverlay={offerOverlay}
        offerPopCode={offerPopCode}
        offersCreativesViewMode={offersCreativesViewMode}
        onSmartlinkCopy={onSmartlinkCopy}
        tabs={tabs}
        trackingLink={trackingLink}
        userEmail={userEmail}
        values={values}
        isSmartlinkDatingOrCam={isSmartlinkDatingOrCam}
      />
    </div>
  );
};

SmartLinkAdTool.propTypes = {
  affId: PropTypes.string.isRequired,
  creatives: PropTypes.object.isRequired,
  creativesIsFetching: PropTypes.bool.isRequired,
  creativesSearchForm: PropTypes.object.isRequired,
  creativesSizes: PropTypes.array.isRequired,
  creativesSizesIsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  offerOverlay: PropTypes.any,
  offerPopCode: PropTypes.any,
  offersCreativesViewMode: PropTypes.string.isRequired,
  onSmartlinkCopy: PropTypes.func,
  trackingLink: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default SmartLinkAdTool;

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { SelectMulti, InputText } from '../../../components/reusables';

const StatisticsSecondaryFilters = ({
  browsersList,
  categories,
  fields,
  goalsList,
  intl,
  isDisabled,
  statisticsSelectedFilters,
}) => (
  <div className="row secondaryFilters">
    {statisticsSelectedFilters.filterGoals ? (
      <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
        <SelectMulti
          filterCategory="Goal"
          disabled={isDisabled}
          field={fields.goals}
          filterName="Goal.id"
          id="goals"
          label={messages.genericTextGoals.description}
          tabIndex={70}
          value={fields.goals.value}
        >
          <option disabled value="">
            {intl.formatMessage(messages.genericTextChoose)}
          </option>
          {goalsList.map((goal) => (
            <option data-category="Goal" key={`goal-${goal.id}`} value={goal.id}>
              {goal.name}
            </option>
          ))}
        </SelectMulti>
      </div>
    ) : null}

    {statisticsSelectedFilters.filterNiche ? (
      <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
        <SelectMulti
          filterCategory="Niche"
          disabled={isDisabled}
          field={fields.niche}
          filterName="OfferCategory.id"
          id="niche"
          label={messages.genericTextNiche.description}
          tabIndex={80}
          value={fields.niche.value}
        >
          <option disabled value="">
            {intl.formatMessage(messages.genericTextChoose)}
          </option>
          {categories
            .filter((category) => category.name.indexOf('Niche') === 0)
            .map((category) => (
              <option key={'niche-' + category.id} value={category.id}>
                {category.name.split('Niche - ')[1]}
              </option>
            ))}
        </SelectMulti>
      </div>
    ) : null}

    {statisticsSelectedFilters.filterBrowser ? (
      <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
        <SelectMulti
          filterCategory="Device"
          disabled={isDisabled}
          field={fields.browser}
          filterName="Browser.id"
          id="browser"
          label={messages.statisticsColumns['Browser.display_name'].description}
          tabIndex={90}
          value={fields.browser.value}
          canSearch
        >
          <option disabled value="">
            {intl.formatMessage(messages.genericTextChoose)}
          </option>
          {browsersList.map((browser) => (
            <option key={'browser-' + browser.id} value={browser.id}>
              {browser.name}
            </option>
          ))}
        </SelectMulti>
      </div>
    ) : null}

    {statisticsSelectedFilters.filterPayoutType ? (
      <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
        <SelectMulti
          filterCategory="Payout Type"
          disabled={isDisabled}
          field={fields.payoutType}
          filterName="Stat.payout_type"
          id="payoutType"
          label={messages.statisticsColumns['Stat.payout_type'].description}
          tabIndex={100}
          canSearch
          value={fields.payoutType.value}
        >
          <option disabled value="">
            {intl.formatMessage(messages.genericTextChoose)}
          </option>
          <option value="cpa_flat">CPA</option>
          <option value="cpa_both">CPA + REV</option>
          <option value="cpa_percentage">REV</option>
        </SelectMulti>
      </div>
    ) : null}

    {statisticsSelectedFilters.filterSubId1 ? (
      <InputText
        category="Sub ID 1"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.subId1}
        filtername="Stat.affiliate_info1"
        id="subId1"
        label={messages.statisticsColumns['Stat.affiliate_info1']}
        type="text"
      />
    ) : null}
    {statisticsSelectedFilters.filterSubId2 ? (
      <InputText
        category="Sub ID 2"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.subId2}
        filtername="Stat.affiliate_info2"
        id="subId2"
        label={messages.statisticsColumns['Stat.affiliate_info2']}
        type="text"
      />
    ) : null}
    {statisticsSelectedFilters.filterSubId3 ? (
      <InputText
        category="Sub ID 3"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.subId3}
        filtername="Stat.affiliate_info3"
        id="subId3"
        label={messages.statisticsColumns['Stat.affiliate_info3']}
        type="text"
      />
    ) : null}
    {statisticsSelectedFilters.filterSubId4 ? (
      <InputText
        category="Sub ID 4"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.subId4}
        filtername="Stat.affiliate_info4"
        id="subId4"
        label={messages.statisticsColumns['Stat.affiliate_info4']}
        type="text"
      />
    ) : null}
    {statisticsSelectedFilters.filterSubId5 ? (
      <InputText
        category="Sub ID 5"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.subId5}
        filtername="Stat.affiliate_info5"
        id="subId5"
        label={messages.statisticsColumns['Stat.affiliate_info5']}
        type="text"
      />
    ) : null}
    {statisticsSelectedFilters.filterSource ? (
      <InputText
        category="Source"
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInputText"
        compare="like"
        field={fields.source}
        filtername="Stat.source"
        id="source"
        label={messages.statisticsColumns['Stat.source']}
        type="text"
      />
    ) : null}
  </div>
);

StatisticsSecondaryFilters.propTypes = {
  browsersList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  goalsList: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  statisticsSelectedFilters: PropTypes.object.isRequired,
};

export default injectIntl(StatisticsSecondaryFilters);

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import { InputText, InputNotice } from '../../../../components/reusables';

import provincesList from '../../../../utils/list-options/provinces';
import statesList from '../../../../utils/list-options/states';

import messages from '../../../../i18n/base-en.js';

import 'materialize-css';
import { Select } from 'react-materialize';

function BeneficiaryInfos({ fields, values, countries, intl }) {
  return (
    <div className="beneficiaryInfos_section">
      <div className="row">
        <div className="col-md-12">
          <h2>
            <FormattedMessage {...messages.paymentInfosBeneficiaryInfosTitle} />
          </h2>
        </div>
      </div>
      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={fields.wireBeneficiaryName}
          id="wireBeneficiaryName"
          label={messages.genericTextName}
          maxLength={35}
          tabIndex={30}
          type="text"
        />
      </div>
      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={fields.wireBeneficiaryAddress}
          id="wireBeneficiaryAddress"
          label={messages.genericTextAddress}
          maxLength={60}
          tabIndex={40}
          type="text"
        />
      </div>
      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={fields.wireBeneficiaryCity}
          id="wireBeneficiaryCity"
          label={messages.genericTextCity}
          maxLength={20}
          tabIndex={50}
          type="text"
        />
      </div>

      <div className="row">
        <div className="col-md-12">
          <Select
            id="wireBeneficiaryCountry"
            label={messages.genericTextCountry.description}
            onChange={fields.wireBeneficiaryCountry.onChange}
            tabIndex={60}
            value={fields.wireBeneficiaryCountry.value || ''}
          >
            <option disabled value="">
              {intl.formatMessage(messages.genericTextChoose)}
            </option>
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
        </div>
        {(fields.wireBeneficiaryCountry.value === 'UA' || fields.wireBeneficiaryCountry.value === 'RU') && (
          <InputNotice className="col-md-12">
            <span>
              <FormattedHTMLMessage {...messages.paymentInfosRussiaUkraineMessage} />
            </span>
          </InputNotice>
        )}
      </div>

      {values.wireBeneficiaryCountry === 'CA' && (
        <div className="row">
          <div className="col-md-12">
            <Select
              id="wireBeneficiaryProvince"
              label={messages.genericTextProvince.description}
              onChange={fields.wireBeneficiaryProvince.onChange}
              tabIndex={70}
              value={fields.wireBeneficiaryProvince.value || ''}
            >
              <option disabled value="">
                {intl.formatMessage(messages.genericTextChoose)}
              </option>
              {provincesList.map((province, index) => (
                <option key={province.name + '-' + index} value={province.value}>
                  {intl.formatMessage(messages[province.label.id])}
                </option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {values.wireBeneficiaryCountry === 'US' && (
        <div className="row">
          <div className="col-md-12">
            <Select
              id="wireBeneficiaryState"
              label={messages.genericTextState.description}
              onChange={fields.wireBeneficiaryState.onChange}
              tabIndex={70}
              value={fields.wireBeneficiaryState.value || ''}
            >
              <option disabled value="">
                {intl.formatMessage(messages.genericTextChoose)}
              </option>
              {statesList.map((state, index) => (
                <option key={state.name + '-' + index} value={state.value}>
                  {intl.formatMessage(messages[state.label.id])}
                </option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {values.wireBeneficiaryCountry !== 'CA' && values.wireBeneficiaryCountry !== 'US' && (
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.wireBeneficiaryRegion}
            id="wireBeneficiaryRegion"
            label={messages.genericTextRegion}
            tabIndex={70}
            type="text"
          />
        </div>
      )}

      <div className="row">
        <InputText
          className="col-md-12"
          displayErrorInstantly
          field={fields.wireBeneficiaryZipcode}
          id="wireBeneficiaryZipcode"
          label={messages.genericTextZipCode}
          tabIndex={80}
          type="text"
        />
      </div>
    </div>
  );
}

BeneficiaryInfos.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(BeneficiaryInfos);

import React from 'react';
import {
  BANNER_FILTERS,
  BANNER_TRENDING_FILTERS,
  CLEAR_ALL_TEXT,
  CREATIVE_BANNER_FORMAT_LIST,
  CREATIVE_BANNER_LANGUAGE_LIST,
  isTrendingCreativesTab,
} from './values';
import { useCreativesBanners } from '../CreativesBanners';
import { Gtm } from '../../../../utils/gtm';
import BannerFilter from './BannerFilter';

const BannerFilters = React.memo(() => {
  let {
    activeTab,
    creativesSizes,
    dispatchSearchFormChange,
    setOpenFilter,
    firstCreativeSize,
    creativesLoading,
    creativesTrending,
    setActivatedFilters,
    activatedFiltersByTab,
  } = useCreativesBanners();

  if (creativesSizes?.length) {
    creativesSizes = creativesSizes.map((size) => ({ key: size, value: size }));
  }

  const closeFilterDropdown = () => setOpenFilter('');

  const setFilter = ({ field, key }) => {
    if (creativesLoading) {
      return;
    }

    Gtm.event('offers', 'Click', `banner ${field} - ${key}`);

    if (activatedFiltersByTab[field] !== key) {
      setActivatedFilters((filters) => {
        filters[activeTab][field] = filters[activeTab]?.[field] === key ? '' : key;

        return { ...filters };
      });

      if (!isTrendingCreativesTab(activeTab)) {
        dispatchSearchFormChange({ field, key });
      }
    }

    closeFilterDropdown();
  };

  const clearAll = ({ field }) => {
    closeFilterDropdown();

    Gtm.event('offers', 'Click', `banner - ${field} - ${CLEAR_ALL_TEXT[field]}`);

    if (
      creativesLoading ||
      !activatedFiltersByTab[field] ||
      (!isTrendingCreativesTab(activeTab) && activatedFiltersByTab[field] === firstCreativeSize)
    ) {
      return;
    }

    setActivatedFilters((filters) => {
      if (isTrendingCreativesTab(activeTab)) {
        delete filters[activeTab][field];
      } else {
        filters[activeTab][field] = '';
      }

      return { ...filters };
    });

    if (!isTrendingCreativesTab(activeTab)) {
      dispatchSearchFormChange({ field, key: '' });
    }
  };

  const openDropdown = ({ field }) => {
    if (creativesLoading) {
      return;
    }
    setOpenFilter((openFilter) => (openFilter === field ? '' : field));
  };

  const dropdowns = {
    size: isTrendingCreativesTab(activeTab) ? creativesTrending.sizes : creativesSizes,
    language: CREATIVE_BANNER_LANGUAGE_LIST,
    format: isTrendingCreativesTab(activeTab) ? creativesTrending.formats : CREATIVE_BANNER_FORMAT_LIST,
    countryCode: creativesTrending.countries,
    days: creativesTrending.days,
  };

  return (
    <div className={`bannerFilters ${activeTab}`}>
      {isTrendingCreativesTab(activeTab) && (
        <div className="trendingBannerFilters">
          <div className="trendingText">Best creatives in</div>
          <div className="trendingFilterRow">
            {BANNER_TRENDING_FILTERS.map(({ field, text }) => (
              <>
                {text ? (
                  <div className="trendingText">{text}</div>
                ) : (
                  <BannerFilter
                    clearAll={clearAll}
                    dropdowns={dropdowns}
                    field={field}
                    openDropdown={openDropdown}
                    setFilter={setFilter}
                    filterOptions={activatedFiltersByTab}
                    trendingFilter={true}
                    clearAllText={'Reset'}
                    displayValue={activatedFiltersByTab[field]}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      )}
      <div className="standardBannerFilters">
        {BANNER_FILTERS.map((field) => (
          <BannerFilter
            clearAll={clearAll}
            dropdowns={dropdowns}
            field={field}
            openDropdown={openDropdown}
            setFilter={setFilter}
            clearAllText={isTrendingCreativesTab(activeTab) ? 'Reset' : ''}
            filterOptions={activatedFiltersByTab}
          />
        ))}
      </div>
    </div>
  );
});

export default BannerFilters;

const AffiliateResources = () => (
  <svg height="30" viewBox="0 0 40 40" width="30">
    <defs>
      <clipPath id="clip-path">
        <rect
          data-name="Rectangle 451"
          fill="#fff"
          height="40"
          id="Rectangle_451"
          stroke="#fff"
          strokeWidth="1"
          transform="translate(338 295)"
          width="40"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)" id="referals" transform="translate(-338 -295)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(338 295)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 584" fill="none" height="40" id="Rectangle_584" width="40" />
          <circle cx="20" cy="20" data-name="Ellipse 127" fill="none" id="Ellipse_127" r="20" transform="translate(0 0)" />
          <line data-name="Ligne 298" fill="none" id="Ligne_298" x2="40" y2="40" />
          <line data-name="Ligne 299" fill="none" id="Ligne_299" x1="40" y2="40" />
          <line data-name="Ligne 300" fill="none" id="Ligne_300" transform="translate(0 20)" x2="40" />
          <line data-name="Ligne 301" fill="none" id="Ligne_301" transform="translate(0 9.997)" x2="40" />
          <line data-name="Ligne 302" fill="none" id="Ligne_302" transform="translate(0 30.002)" x2="40" />
          <line data-name="Ligne 303" fill="none" id="Ligne_303" transform="translate(20)" y2="40" />
          <line data-name="Ligne 304" fill="none" id="Ligne_304" transform="translate(9.997)" y2="40" />
          <line data-name="Ligne 305" fill="none" id="Ligne_305" transform="translate(30.002)" y2="40" />
          <rect
            data-name="Rectangle 585"
            fill="none"
            height="28.291"
            id="Rectangle_585"
            transform="translate(5.855 5.855)"
            width="28.291"
          />
          <circle
            cx="14.145"
            cy="14.145"
            data-name="Ellipse 128"
            fill="none"
            id="Ellipse_128"
            r="14.145"
            transform="translate(5.855 5.855)"
          />
          <rect
            data-name="Rectangle 586"
            fill="none"
            height="20.005"
            id="Rectangle_586"
            transform="translate(9.997 9.997)"
            width="20.005"
          />
          <circle
            cx="10.002"
            cy="10.002"
            data-name="Ellipse 129"
            fill="none"
            id="Ellipse_129"
            path="true"
            r="10.002"
            transform="translate(9.998 9.998)"
          />
          <path
            d="M17.766,24.965h0a4.261,4.261,0,0,1-4.286-3.6l-.545-4.023a4.2,4.2,0,0,1,4.285-4.661h1.091A4.2,4.2,0,0,1,22.6,17.342l-.545,4.023A4.259,4.259,0,0,1,17.766,24.965Z"
            data-name="Tracé 175"
            fill="none"
            id="Tracé_175"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
          <path
            d="M15.424,24.3l-.132,1.205a2.195,2.195,0,0,1-1.455,1.832L9.7,28.793"
            data-name="Tracé 176"
            fill="none"
            id="Tracé_176"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
          <path
            d="M20.139,24.3l.131,1.205a2.2,2.2,0,0,0,1.458,1.832l3.707,1.3a3.591,3.591,0,0,1,2.614,2.331"
            data-name="Tracé 177"
            fill="none"
            id="Tracé_177"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
          <path
            d="M22.55,12.68a4.2,4.2,0,0,1,4.285,4.661l-.547,4.023A4.26,4.26,0,0,1,22,24.965"
            data-name="Tracé 178"
            fill="none"
            id="Tracé_178"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
          <path
            d="M24.414,24.374a2.193,2.193,0,0,0,1.3,1.231l3.706,1.3a5.476,5.476,0,0,1,1.714.912"
            data-name="Tracé 179"
            fill="none"
            id="Tracé_179"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
          <line
            data-name="Ligne 306"
            fill="none"
            id="Ligne_306"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
            transform="translate(30.915 9.737)"
            y2="4.763"
          />
          <line
            data-name="Ligne 307"
            fill="none"
            id="Ligne_307"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
            transform="translate(28.533 12.118)"
            x1="4.763"
          />
          <path
            d="M28.527,9.4a13.566,13.566,0,1,0,4.262,5.941"
            data-name="Tracé 180"
            fill="none"
            id="Tracé_180"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.019"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AffiliateResources;

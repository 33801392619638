import consts from '../utils/consts';

const {
  ACTIONS: { SSIGN_UP },
} = consts;

const initialState = {
  saved: false,
  isFetching: false,
  err: null,
  signupToken: null,
  userInfo: {},
  hoQuestions: {},
  hoQuestionsIsFetching: false,
};

export const signup = (state = initialState, action = {}) => {
  switch (action.type) {
    case SSIGN_UP.REQUEST_VALIDATE_USER:
    case SSIGN_UP.REQUEST_CREATE_LEAD:
    case SSIGN_UP.REQUEST_CREATE_USER:
      return {
        ...state,
        isFetching: true,
        err: null,
      };

    case SSIGN_UP.RECEIVE_CREATE_LEAD:
    case SSIGN_UP.RECEIVE_CREATE_USER:
      return {
        ...state,
        saved: true,
        isFetching: false,
        err: null,
      };

    case SSIGN_UP.RECEIVED_VALIDATE_USER:
      return {
        ...state,
        isFetching: false,
        err: null,
        userInfo: {
          ...action.data,
        },
      };

    case SSIGN_UP.FAILED_VALIDATE_USER:
    case SSIGN_UP.FAILED_TO_CREATE_LEAD:
    case SSIGN_UP.FAILED_TO_CREATE_USER:
      return {
        ...state,
        isFetching: false,
        err: action.err,
      };

    case SSIGN_UP.SET_SIGNUP_TOKEN:
      return {
        ...state,
        signupToken: action.data,
      };

    case SSIGN_UP.REQUEST_HO_QUESTIONS: {
      return {
        ...state,
        hoQuestionsIsFetching: true,
      };
    }

    case SSIGN_UP.RECEIVE_HO_QUESTIONS: {
      return {
        ...state,
        hoQuestionsIsFetching: false,
        hoQuestions: action.data,
      };
    }
    case SSIGN_UP.FAILED_TO_FECTH_HO_QUESTIONS: {
      return {
        ...state,
        hoQuestionsIsFetching: false,
      };
    }

    default:
      return state;
  }
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { v4 as uuidv4 } from 'uuid';

import SelectCustom from './form/SelectCustom.js';
const Pagination = ({
  values,
  currentPage,
  rowsPerPage,
  handlePageChange,
  totalResults,
  className,
  pageCount,
  selectId,
  fields,
  choices,
  handleRowsChange,
  silent,
  title,
}) => {
  useEffect(() => {}, [rowsPerPage, currentPage, pageCount, totalResults, title, values]);

  const previousPageChange = (e) => {
    e.preventDefault();

    const previousPage = currentPage - 1;

    if (!!handlePageChange && previousPage >= 1) {
      handlePageChange(previousPage);
    }

    if (selectId === 'rowsPerPageBottom') {
      document.getElementById('offers-list').scrollIntoView();
    }
  };

  const nextPageChange = (e) => {
    e.preventDefault();

    const nextPage = currentPage + 1;

    if (!!handlePageChange && nextPage <= pageCount) {
      handlePageChange(nextPage);
    }

    if (selectId === 'rowsPerPageBottom') {
      if (document.getElementById('referrals-infos')) {
        document.getElementById('referrals-infos').scrollIntoView();
      }
      if (document.getElementById('offers-list')) {
        document.getElementById('offers-list').scrollIntoView();
      }
    }
  };

  const rowsPerPageChange = (rowsPerPage) => {
    let page = currentPage;

    if (page * rowsPerPage > totalResults) {
      page = Math.ceil(totalResults / rowsPerPage);
    }
    if (handleRowsChange) {
      handleRowsChange(rowsPerPage, page);
    }
  };

  useEffect(() => {
    if (pageCount && !silent) {
      handlePageChange(1);
    }

    if (values.rowsPerPage) {
      rowsPerPageChange(values.rowsPerPage);
    }
  }, [pageCount, values.rowsPerPage]);

  const btnClasses = 'btn-flat';
  const prevBtnClasses = currentPage === 1 ? btnClasses + ' disabled' : btnClasses + ' waves-effect';
  const nextBtnClasses = currentPage >= pageCount ? btnClasses + ' disabled' : btnClasses + ' waves-effect';

  let firstResult = (currentPage - 1) * rowsPerPage + 1;
  let lastResult = currentPage * rowsPerPage;
  let dash = ' - ';

  if (lastResult > totalResults) {
    lastResult = totalResults;
  }

  if (rowsPerPage === 1) {
    firstResult = ' ';
    dash = ' ';
    lastResult = totalResults;
  }
  if (rowsPerPage === 2) {
    firstResult = totalResults - 1;
    lastResult = totalResults;
  }
  if (rowsPerPage === 3) {
    firstResult = totalResults - 2;
    lastResult = totalResults;
  }

  return (
    <div className={className} id="pagination">
      <div className="results pull-right">
        <span className="text">
          {firstResult} {dash} {lastResult} <FormattedMessage {...messages.genericTextOn} /> {totalResults}
        </span>
        <div className="arrows">
          <span className={prevBtnClasses} disabled={currentPage === 1} onClick={(e) => previousPageChange(e)}>
            <i className="material-icons">chevron_left</i>
          </span>
          <span className={nextBtnClasses} disabled={currentPage >= pageCount} onClick={(e) => nextPageChange(e)}>
            <i className="material-icons">chevron_right</i>
          </span>
        </div>
      </div>
      {choices ? (
        <div className="rowsPerPage pull-right">
          <span className="text pull-left">
            <FormattedMessage {...messages.offerRowsPerPage} />
          </span>
          <form action="#" className="pull-left" method="post">
            <div className="pagination-select customSelects" data-cy="select-inputtext-rowsPerPageTop">
              <div className="fields">
                <SelectCustom
                  id={selectId}
                  onChange={fields.rowsPerPage.onChange}
                  value={rowsPerPage}
                  styleBox={true}
                  showSelectedFieldCheckmark={false}
                  options={choices.map((choice) => ({ value: choice, text: choice }))}
                />
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

Pagination.propTypes = {
  choices: PropTypes.array,
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleRowsChange: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  selectId: PropTypes.string,
  silent: PropTypes.bool,
  title: PropTypes.object,
  totalResults: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'pagination',
  fields: ['rowsPerPage'],
})(Pagination);

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import PaymentCard from './PaymentCard.react';

const PaymentHistoryCards = ({ handleInvoiceRequest, handlePaymentRequest, paymentsList }) => (
  <div className="payment-history-cards">
    {paymentsList.length < 1 ? (
      <p className="noResults">
        <FormattedMessage {...messages.paymentHistoryNoHistory} />
      </p>
    ) : (
      paymentsList.map((payment) => (
        <PaymentCard
          amount={payment.amount}
          currency={payment.currency}
          date={payment.date}
          handleInvoiceRequest={handleInvoiceRequest}
          handlePaymentRequest={handlePaymentRequest}
          id={payment.id}
          key={`payment-${payment.id}`}
          status={payment.status}
          type={payment.type}
        />
      ))
    )}
  </div>
);

PaymentHistoryCards.propTypes = {
  handleInvoiceRequest: PropTypes.func.isRequired,
  handlePaymentRequest: PropTypes.func.isRequired,
  paymentsList: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PaymentHistoryCards;

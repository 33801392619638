import { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from '../i18n/base-en.js';

import PaymentHistoryCards from './payment-history/PaymentHistoryCards.react';
import PaymentHistoryTable from './payment-history/PaymentHistoryTable.react';
import PaymentHistoryGraph from '../components/reusables/PaymentHistoryGraph.react';
import PaymentDateHistory from '../components/reusables/PaymentDateHistory.react';
import { AlertHeader, Container, DateRange, Info, VideoModalIcon, ViewMode } from '../components/reusables';

import { changePaymentHistoryViewMode, fetchPaymentHistory, fetchAffiliateInvoice } from '../actions/payment-history-actions';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { Gtm } from '../utils/gtm';
import intercom from '../utils/intercom';
import request from '../utils/Req';
import config from '../config';
import { PaymentHistoryWidget } from '../components/reusables';
import { getCountryLabelDefaultMessage } from '../utils/list-options/countries.js';
import { getCookie } from '../utils/helpers.js';

const { API_URL } = config;

const PaymentHistory = (props) => {
  const {
    dispatch,
    fields,
    isFetchingPaymentsList,
    paymentHistoryViewMode,
    paymentsList,
    currentPeriodPayout,
    minimumPayoutAmount,
    nextPaymentAmount,
    paymentHistory,
    paymentTerms,
    lastPeriodPayout,
    isCurrentPeriodPayout,
    skinConfigurations,
    userInfos,
    values,
    profileCompleted,
    minimumPayout,
    affiliateBeneficiary,
    affType,
    affCompany,
  } = props;

  useEffect(() => {
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'payment-history');
    intercom.trackEvent('Payment-history');
    const filtersObj = {
      start_date: values.startDateFilter,
      end_date: values.endDateFilter,
    };

    dispatch(fetchPaymentHistory(filtersObj));
  }, [values]);

  const handleViewModeChange = (viewMode) => {
    dispatch(changePaymentHistoryViewMode(viewMode));
  };

  const handleInvoiceRequest = (paymentId) => {
    const SKIN_NAME = SkinConfigurationsUtils.getSkinName(skinConfigurations).toLowerCase();
    const filters = {
      id: paymentId,
    };
    const newWin = window.open(
      '',
      'Statement Details',
      'Toolbar=0,Location=0-,Directories=0,Status=0,Menubar=0,Scrollbars=0,Resizable=0,width=850px,height=980px,left=550'
    );

    dispatch(fetchAffiliateInvoice(filters))
      .then((resp) => {
        if (typeof resp.body.history.data[paymentId].AffiliateInvoiceItem.length !== 'undefined') {
          request
            .post(`${API_URL}/invoices/print/${paymentId}`)
            .send({
              affiliateInvoiceInfos: resp.body.history.data[paymentId].AffiliateInvoice,
              affiliateInvoiceItemsInfos: resp.body.history.data[paymentId].AffiliateInvoiceItem,
              logoPath: `${window.location.origin}/img/${
                SKIN_NAME === 'crakrevenue' ? 'affiliates' : SKIN_NAME
              }/logo-white-bg.svg`,
              userInfos,
            })
            .end((err, res) => {
              newWin.document.body.innerHTML = res.text;
              newWin.document.body.onload = () => newWin.print();
              newWin.document.title = messages.paymentHistoryStatementDetails.defaultMessage;
            });
        } else {
          newWin.document.body.innerHTML = `<p>${messages.paymentHistoryNoInvoice.defaultMessage}</p>`;
        }
      })
      .catch(() => (newWin.document.body.innerHTML = `<p>${messages.genericTextSomethingWrong.defaultMessage}</p>`));
  };

  const handlePaymentRequest = (paymentId) => {
    const newWin = window.open(
      '',
      'Statement Details',
      'Toolbar=0,Location=0-,Directories=0,Status=0,Menubar=0,Scrollbars=0,Resizable=0,width=850px,height=980px,left=550'
    );

    request
      .post(`${API_URL}/payments/print/${paymentId}`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .send({
        logoPath: `${window.location.origin}/img/affiliates/follow-the-whale-black.svg`,
        referralIconPath: `${window.location.origin}/img/referral-icon.svg`,
        userInfos,
        affiliateBeneficiary: {
          ...affiliateBeneficiary,
          country: getCountryLabelDefaultMessage(affiliateBeneficiary?.country_code),
        },
        affCompany: affType?.type?.toLowerCase() === 'Company'.toLowerCase() ? affCompany : undefined,
      })
      .end((err, res) => {
        newWin.document.body.innerHTML = res.text;
        newWin.document.body.onload = () => newWin.print();
        newWin.document.title = messages.paymentHistoryStatementDetails.defaultMessage;
      });
  };
  return (
    <div id="payment-history">
      <AlertHeader
        className="container-fluid app-container no-close"
        iconIsVisible
        localStorageString="paymenthistory-alert-noclose"
      >
        <span>
          <FormattedMessage {...messages.profilePaymentHistoryInfo} />
        </span>
      </AlertHeader>
      <div className="container-fluid app-container">
        <h1 className="primary-color">
          <FormattedMessage {...messages.profilePaymentHistoryTitle} />
          <Info
            onClick={() => Gtm.event('payment history', 'click', 'info icon')}
            title="Click here for more informations on Payment History"
            url="https://support.crakrevenue.com/knowledge-base/category/payments/"
          />
          <VideoModalIcon
            className="pointer paymentHistoryModal"
            dispatch={dispatch}
            gtmEvent={() => Gtm.event('payment history', 'click', 'video icon')}
            videoUrl="https://player.vimeo.com/video/391533934"
          />
        </h1>
        <div className="balance-container">
          <h2 className="payment-subtitle">
            <FormattedMessage {...messages.profileBalanceTitle} />
          </h2>
          <div className="payment-infos-container">
            <PaymentHistoryGraph
              currentPeriodPayout={currentPeriodPayout}
              hasReachedMinimumPayout={paymentHistory.asReachedMinimumPayout}
              isCurrentPeriodPayout={isCurrentPeriodPayout}
              lastPeriodHasInvoice={paymentHistory.lastPeriodHasInvoice}
              lastPeriodPayout={lastPeriodPayout}
              minimumPayoutAmount={minimumPayoutAmount}
              nextPaymentAmount={nextPaymentAmount}
              paymentTerms={paymentTerms}
            />
            {profileCompleted.hasAPaymentMethod === false ? (
              <div className="to-be-completed">
                <div className="temporal-history">
                  <PaymentDateHistory paymentHistory={paymentHistory} temporalMoment="next" />
                </div>
                <div className="widget">
                  <PaymentHistoryWidget
                    minimumPayout={minimumPayout}
                    paymentHistory={paymentHistory}
                    paymentTerms={paymentTerms}
                    profileCommunicationStatus={profileCompleted.communicationStatus}
                  />
                </div>
              </div>
            ) : (
              <div className="temporal-history">
                <PaymentDateHistory paymentHistory={paymentHistory} temporalMoment="next" />
                <PaymentDateHistory paymentHistory={paymentHistory} temporalMoment="last" />
              </div>
            )}
          </div>
        </div>

        <div className="history-container">
          <h2 className="payment-subtitle">
            <FormattedMessage {...messages.profileHistoryTitle} />
          </h2>
          <div className="history-filters">
            <DateRange
              dispatch={dispatch}
              endField={fields.endDateFilter}
              gtmEvent
              presets={fields.presets}
              startField={fields.startDateFilter}
            />
            <ViewMode
              classNames={['hidden-sm', 'hidden-xs']}
              currentViewMode={paymentHistoryViewMode}
              handleViewModeChange={handleViewModeChange}
            />
          </div>
        </div>
        <Container className={`payments-list z-depth-2 ${isFetchingPaymentsList ? 'table-loading-state' : ''}`}>
          {paymentHistoryViewMode === 'cards' ? (
            <PaymentHistoryCards
              handleInvoiceRequest={handleInvoiceRequest}
              handlePaymentRequest={handlePaymentRequest}
              paymentsList={paymentsList}
            />
          ) : (
            <PaymentHistoryTable
              handleInvoiceRequest={handleInvoiceRequest}
              handlePaymentRequest={handlePaymentRequest}
              isLoadingPayments={isFetchingPaymentsList}
              paymentsList={paymentsList}
            />
          )}
        </Container>
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  isFetchingPaymentsList: PropTypes.bool,
  paymentHistoryViewMode: PropTypes.string.isRequired,
  paymentsList: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  userInfos: PropTypes.object,
  values: PropTypes.object.isRequired,
};

const hooks = {
  defer: ({ dispatch, state }) =>
    new Promise((fulfill) => {
      const promises = [];

      if (!state.paymentHistory.isFetchingPaymentsList) {
        promises.push(dispatch(fetchPaymentHistory()));
      }

      Promise.all(promises).then(fulfill).catch(fulfill);
    }),
};

export default provideHooks(hooks)(
  connect((state) => ({
    isFetchingPaymentsList: state.paymentHistory.isFetchingPaymentsList,
    paymentHistoryViewMode: state.application.ui.paymentHistoryViewMode,
    paymentsList: state.paymentHistory.paymentsList,
    skinConfigurations: state.skinConfigurations.data,
    userInfos: state.profile.data.affInfos,
    affiliateBeneficiary: state.profile.data.affiliateBeneficiary,
    affCompany: state.profile.data.affCompany,
    affType: state.profile.data.customProfile.affiliate.affiliate_type,
    profileCompleted: state.profileCompleted,
    minimumPayout: state.profile.data.customProfile.affiliate.minimum_payout
      ? state.profile.data.customProfile.affiliate.minimum_payout.amount || ''
      : '',
    isFetchingUserInfos: state.profile.isFetching,
    isCurrentPeriodPayout: state.home.currentPeriodPayouts.totals || null,
    currentPeriodPayout:
      (state.home.currentPeriodPayouts.totals &&
        state.home.currentPeriodPayouts.totals.Stat &&
        +parseFloat(state.home.currentPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
      0,
    minimumPayoutAmount: +parseFloat(state.profile.data.customProfile.affiliate.minimum_payout.amount).toFixed(2) || 0,
    nextPaymentAmount: +parseFloat(state.profile.data.paymentHistory.next.amount).toFixed(2) || 0,
    paymentHistory: state.profile.data.paymentHistory,
    paymentTerms: state.profile.data.customProfile.affiliate.payment_term || '',
    lastPeriodPayout:
      (state.home.lastPeriodPayouts.totals &&
        state.home.lastPeriodPayouts.totals.Stat &&
        +parseFloat(state.home.lastPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
      0,
  }))(
    injectIntl(
      reduxForm({
        form: 'paymentHistoryFilters',
        fields: ['endDateFilter', 'presets', 'startDateFilter'],
        initialValues: {
          endDateFilter: moment().add('1', 'day').format('YYYY-MM-DD'),
          presets: 'All Time',
          startDateFilter: '2001-01-01',
        },
      })(PaymentHistory)
    )
  )
);

import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import PaymentHistoryWidget from './PaymentHistoryWidget.react';

const communicationLink = (profileCommunicationStatus) => {
  switch (profileCommunicationStatus) {
    case 'both':
    case 'profile':
      return (
        <Link
          className="profile-history-button"
          id="profileHistoryButton"
          onClick={() => {
            Gtm.event('profile - user details', 'Click', `see details`);
          }}
          onlyActiveOnIndex={false}
          to="/profile/user-details"
        >
          <span className="hidden-xs">
            <FormattedMessage {...messages.genericTextGoToUserDetails} />
          </span>
          <i className="material-icons">keyboard_arrow_right</i>
        </Link>
      );
    case 'payment':
      return (
        <Link
          className="profile-history-button"
          id="profileHistoryButton"
          onClick={() => {
            Gtm.event('profile - user details', 'Click', `see details`);
          }}
          onlyActiveOnIndex={false}
          to="/profile/billing-infos"
        >
          <span className="hidden-xs">
            <FormattedMessage {...messages.genericTextGoToBillingInformation} />
          </span>
          <i className="material-icons">keyboard_arrow_right</i>
        </Link>
      );
    default:
      return (
        <Link
          className="profile-history-button"
          id="profileHistoryButton"
          onClick={() => {
            Gtm.event('profile - user details', 'Click', `see details`);
          }}
          onlyActiveOnIndex={false}
          to="/profile/payment-history"
        >
          <span className="hidden-xs">
            <FormattedMessage {...messages.genericTextSeeDetails} />
          </span>
          <i className="material-icons">keyboard_arrow_right</i>
        </Link>
      );
  }
};

const PaymentHistoryWidgetContainer = ({ minimumPayout, paymentHistory, paymentTerms, profileCommunicationStatus }) => (
  <div className="payment-history-widget-container">
    <h2>
      <i className="material-icons">&#xE84F;</i>
      <FormattedMessage {...messages.profilePaymentHistoryTitle} />
    </h2>
    <div className="row">
      <div className="col-sm-12 col-xs-10">
        <PaymentHistoryWidget
          className="light-color"
          minimumPayout={minimumPayout}
          paymentHistory={paymentHistory}
          paymentTerms={paymentTerms}
          profileCommunicationStatus={profileCommunicationStatus}
        />
      </div>
      <div className="col-sm-12 col-xs-2">{communicationLink(profileCommunicationStatus)}</div>
    </div>
  </div>
);

PaymentHistoryWidgetContainer.propTypes = {
  minimumPayout: PropTypes.any.isRequired,
  paymentHistory: PropTypes.object.isRequired,
  paymentTerms: PropTypes.string.isRequired,
  profileCommunicationStatus: PropTypes.string,
};

export default injectIntl(PaymentHistoryWidgetContainer);

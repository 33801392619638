export default {
  offerExclusiveOffer: {
    id: 'offer.ExclusiveOffer',
    description: 'Offer - Exclusive Offer',
    defaultMessage: 'Exclusive Offer',
  },
  offerTopOffer: {
    id: 'offer.topOffer',
    description: 'Offer - Top Offer',
    defaultMessage: 'Top Offer',
  },
  offerTopOffers: {
    id: 'offer.topOffers',
    description: 'Offer - Top Offers',
    defaultMessage: 'Top Offers',
  },
  offerNewOffer: {
    id: 'offer.newOffer',
    description: 'Offer - New Offer',
    defaultMessage: 'New Offer',
  },
  offerAdditionalInfo: {
    id: 'offer.additionalInfo',
    description: 'Offer - Additional Info',
    defaultMessage: 'Additional Information',
  },
  offerChannels: {
    id: 'offer.channels',
    description: 'Offer - Channels',
    defaultMessage: 'Device',
  },
  offerOS: {
    id: 'offer.os',
    description: 'Offer - OS',
    defaultMessage: 'OS',
  },
  offerTargetAudience: {
    id: 'offer.targetAudience',
    description: 'Offer - Target Audience',
    defaultMessage: 'Target Audience',
  },
  offerTopPromotionalMethods: {
    id: 'offer.topPromotionalMethods',
    description: 'Offer - Top Promotional Methods',
    defaultMessage: 'Top Promotional Methods',
  },
  offerVerticals: {
    id: 'offer.verticals',
    description: 'Offer - Verticals',
    defaultMessage: 'Vertical',
  },
  offerConversionFlowTitle: {
    id: 'offer.conversionFlowTitle',
    description: 'Offer - Conversion Flow Title',
    defaultMessage: 'Conversion Flow',
  },
  offerDefaultConversionFlowTitle: {
    id: 'offer.defaultConversionFlowTitle',
    description: 'Offer - Default Conversion Flow Title',
    defaultMessage: 'Default Conversion Flow',
  },
  offerDefaultConversionFlow: [
    {
      id: 'offer.defaultConversionFlowStep1',
      description: 'Offer - Default Conversion Flow Step 1',
      defaultMessage: 'User clicks on the link',
    },
    {
      id: 'offer.defaultConversionFlowStep2',
      description: 'Offer - Default Conversion Flow Step 2',
      defaultMessage: 'User generates a conversion ',
    },
    {
      id: 'offer.defaultConversionFlowStep3',
      description: 'Offer - Default Conversion Flow Step 3',
      defaultMessage: 'Payable action triggered when the user completes the desired action',
    },
  ],
  offerAddYourSubId1: {
    id: 'offer.addYourSubId1',
    description: 'Add Your Sub ID 1',
    defaultMessage: 'Add Your Sub ID 1',
  },
  offerAddYourSubId2: {
    id: 'offer.addYourSubId2',
    description: 'Add Your Sub ID 2',
    defaultMessage: 'Add Your Sub ID 2',
  },
  offerAddYourSubId3: {
    id: 'offer.addYourSubId3',
    description: 'Add Your Sub ID 3',
    defaultMessage: 'Add Your Sub ID 3',
  },
  offerAddYourSubId4: {
    id: 'offer.addYourSubId4',
    description: 'Add Your Sub ID 4',
    defaultMessage: 'Add Your Sub ID 4',
  },
  offerAddYourSubId5: {
    id: 'offer.addYourSubId5',
    description: 'Add Your Sub ID 5',
    defaultMessage: 'Add Your Sub ID 5',
  },
  offerTypeYourPostbackURLHere: {
    id: 'offer.typeYourPostbackURLHere',
    description: 'Type your postback URL here',
    defaultMessage: 'Type your postback URL here',
  },
  offerAddYourTrackingLink: {
    id: 'offer.addYourTrackingLink',
    description: 'Add source to your tracking link',
    defaultMessage: 'Add source to your tracking link',
  },
  offerDefaultLanding: {
    id: 'offer.defaultLanding',
    description: 'Default Landing',
    defaultMessage: 'Default Landing',
  },
  offerAutoOptimizedLanding: {
    id: 'offer.autoOptimizedLanding',
    description: 'Auto Optimized',
    defaultMessage: 'Auto Optimized Landing',
  },
  offerAvailableLandings: {
    id: 'offer.AvailableLandings',
    description: 'Available Landings',
    defaultMessage: 'All Available Landings',
  },
  offerChooseYourGoal: {
    id: 'offer.chooseYourGoal',
    description: 'Choose your Goal',
    defaultMessage: 'Choose your Goal',
  },
  offerCreativesFilters: {
    id: 'offer.creativesFilters',
    description: 'Creatives Filters',
    defaultMessage: 'Creatives Filters',
  },
  offerTemplatesFilters: {
    id: 'offer.templatesFilters',
    description: 'Templates Filters',
    defaultMessage: 'Templates Filters',
  },
  offerNoPostback: {
    id: 'offer.noPostback',
    description: 'No postbacks defined',
    defaultMessage: 'No postbacks defined',
  },
  offerNoCreatives: {
    id: 'offer.noCreatives',
    description: 'No Creatives Selected',
    defaultMessage: 'No Creatives Selected',
  },
  offerPostbackOptions: {
    id: 'offer.postbackOptions',
    description: 'Postbacks Options',
    defaultMessage: 'Postbacks Options',
  },
  offerCreativesSelected: {
    id: 'offer.creativesSelected',
    description: 'Creatives Selected',
    defaultMessage: 'Creatives Selected',
  },
  offerLinkCustomization: {
    id: 'offer.linkCustomization',
    description: 'Link Customization',
    defaultMessage: 'Link Customization',
  },
  offerTrackingLink: {
    id: 'offer.trackingLink',
    description: 'Offer - Tracking link',
    defaultMessage: 'Tracking link',
  },
  offerYourLink: {
    id: 'offer.yourLink',
    description: 'Offer - Your Link',
    defaultMessage: 'Your Link',
  },
  offerCrFeaturedOnly: {
    id: 'offer.crFeaturedOnly',
    description: 'Top Offers',
    defaultMessage: 'Top Offers',
  },
  offerRowsPerPage: {
    id: 'offer.rowsPerPage',
    description: 'Results per Page',
    defaultMessage: 'Results per Page',
  },
  offerCreatePostback: {
    id: 'offer.CreatePostback',
    description: 'Offer - Create Postback',
    defaultMessage: 'Create Postback',
  },
  offerAllCountriesAccepted: {
    id: 'offer.allCountriesAccepted',
    description: 'All Ccountries Accepted',
    defaultMessage: 'All Countries Accepted',
  },
  offerAgreeToTerms: {
    id: 'offer.agreeToTerms',
    description: 'First, you must agree to the following Terms & Conditions',
    defaultMessage: 'First, you must agree to the following Terms & Conditions',
  },
  offerSendSelection: {
    id: 'offer.sendSelection',
    description: 'Send Selection',
    defaultMessage: 'Send Selection',
  },
  offerAreYouSureToDeletedPostback: {
    id: 'offer.areYouSureToDeletedPostback',
    description: 'Are you sure to delete this Postback?',
    defaultMessage: 'Are you sure to delete this Postback?',
  },
  offerDeletedPostback: {
    id: 'offer.deletedPostback',
    description: 'Postback deleted',
    defaultMessage: 'Postback deleted',
  },
  offerPostbackAdded: {
    id: 'offer.postbackAdded',
    description: 'Postback added',
    defaultMessage: 'Postback added',
  },
  offerRequestQuestion: {
    id: 'offer.requestQuestion',
    description: 'Please provide a complete answer, otherwise your request will be denied.',
    defaultMessage: 'Please provide a complete answer, otherwise your request will be denied.',
  },
  offerRequestSent: {
    id: 'offer.requestSent',
    description: 'Your request has been sent!',
    defaultMessage: 'Your request has been sent!',
  },
  offerAddVariables: {
    id: 'offer.addVariables',
    description: 'Offer - Add Variables',
    defaultMessage: 'Add Variables',
  },
  offerBackToList: {
    id: 'offer.backToList',
    description: 'Back To Offer List',
    defaultMessage: 'Back To offer List',
  },
  offerPayoutTiers: {
    id: 'offer.payoutTiers',
    description: 'Payout Tiers',
    defaultMessage: 'Payout Tiers',
  },
  offerLandingPageDescription: {
    id: 'offer.landingPageDescription',
    description: 'Landing Page Description',
    defaultMessage: 'This is the page where your traffic is sent.',
  },
  offerBanner: {
    id: 'offer.banner',
    description: 'Banners',
    defaultMessage: 'Banners',
  },
  offerHtmlAds: {
    id: 'offer.htmlAds',
    description: 'HTML Ads',
    defaultMessage: 'HTML Ads',
  },
  offerLink: {
    id: 'offer.link',
    description: 'Link',
    defaultMessage: 'Link',
  },
  offerEmail: {
    id: 'offer.email',
    description: 'Email',
    defaultMessage: 'Email',
  },
  offerIframe: {
    id: 'offer.iframe',
    description: 'IFrame',
    defaultMessage: 'IFrame',
  },
  offerChatHead: {
    id: 'offer.chatHead',
    description: 'Chat Head',
    defaultMessage: 'Chat Head',
  },
  offerPleaseSelectAnAdTool: {
    id: 'offer.pleaseSelectAnAdTool',
    description: 'Offer - Please Select an Ad Tool',
    defaultMessage: 'Please Select an Ad Tool',
  },
  offerBannerFilters: {
    id: 'offer.bannerFilters',
    description: 'Banner Filters',
    defaultMessage: 'Banner Filters',
  },
  offerHtmlAdsFilters: {
    id: 'offer.htmlAdsFilters',
    description: 'HTML Ads Filters',
    defaultMessage: 'HTML Ads Filters',
  },
  offerBrowseOffers: {
    id: 'offer.browseOffers',
    description: 'Browse Offers',
    defaultMessage: 'Browse Offers',
  },
  offerMainstream: {
    id: 'offer.mainstream',
    description: 'Mainstream (G)',
    defaultMessage: 'Mainstream (G)',
  },
  offerAdult: {
    id: 'offer.adult',
    description: 'Adult (18+)',
    defaultMessage: 'Adult (18+)',
  },
  offerGlobalAdult: {
    id: 'offer.mixed',
    description: 'Mixed (18+)',
    defaultMessage: 'Mixed (18+)',
  },
  offerCam: {
    id: 'offer.cam',
    description: 'Cam (18+)',
    defaultMessage: 'Cam (18+)',
  },
  offerDating: {
    id: 'offer.dating',
    description: 'Dating (18+)',
    defaultMessage: 'Dating (18+)',
  },
  offerVod: {
    id: 'offer.vod',
    description: 'VOD (18+)',
    defaultMessage: 'VOD (18+)',
  },
  offerMobileCarrier: {
    id: 'offer.mobileCarrier',
    description: 'Mobile Carrier (18+)',
    defaultMessage: 'Mobile Carrier (18+)',
  },
  offerGay: {
    id: 'offer.gay',
    description: 'Gay (18+)',
    defaultMessage: 'Gay (18+)',
  },
  offerAdultGaming: {
    id: 'offer.adultGaming',
    description: 'Gaming (18+)',
    defaultMessage: 'Gaming (18+)',
  },
  offerDatingNonNude: {
    id: 'offer.datingNonNude',
    description: 'Dating Non-Nude (18+)',
    defaultMessage: 'Dating Non-Nude (18+)',
  },
  offerGaming: {
    id: 'offer.gaming',
    description: 'Gaming (G)',
    defaultMessage: 'Gaming (G)',
  },
  offerCarrierBilling: {
    id: 'offer.carrierBilling',
    description: 'Carrier Billing Optimized (18+)',
    defaultMessage: 'Carrier Billing Optimized (18+)',
  },
  offerTrafficType: {
    id: 'offer.trafficType',
    description: 'Traffic Type',
    defaultMessage: 'Traffic Type',
  },
  offerCopySmartLink: {
    id: 'offer.copySmartLink',
    description: 'Copy SmartLink',
    defaultMessage: 'Copy SmartLink',
  },
  offerPostitial: {
    msgHeader: {
      id: 'offer.postitial.header',
      description: 'Offer - Postitial - Header',
      defaultMessage: 'Postitial',
    },
  },
  offerPrestitial: {
    msgHeader: {
      id: 'offer.prestitial.header',
      description: 'Offer - Prestitial - Header',
      defaultMessage: 'Prestitial',
    },
  },
  offerPopCode: {
    msgCopy: {
      id: 'offer.popCode.copy',
      description: 'Offer - PopCode - Copy',
      defaultMessage: 'Copy Pop Code',
    },
    msgHeader: {
      id: 'offer.popCode.header',
      description: 'Offer - PopCode - Header',
      defaultMessage: 'PopCode',
    },
    msgLoading: {
      id: 'offer.popCode.loading',
      description: 'Offer - PopCode - Loading',
      defaultMessage: 'Loading PopCode ...',
    },
    msgNoData: {
      id: 'offer.popCode.noData',
      description: 'Offer - PopCode - No Data',
      defaultMessage: 'No PopCode available for this offer',
    },
  },
  offerIframeCode: {
    id: 'offer.iframeCode',
    description: 'IFrame Code',
    defaultMessage: 'IFrame Code',
  },
  offerNoIframeCode: {
    id: 'offer.noIframeCode',
    description: 'No iFrame code available for this offer',
    defaultMessage: 'No iFrame code available for this offer',
  },
  offerCopyIframeCode: {
    id: 'offer.copyIframeCode',
    description: 'Copy iFrame Code',
    defaultMessage: 'Copy iFrame Code',
  },
  offerPlacementOptions: {
    id: 'offer.placementOptions',
    description: 'Placement options',
    defaultMessage: 'Placement options',
  },
  offerCopyCode: {
    id: 'offer.copyCode',
    description: 'Copy code',
    defaultMessage: 'Copy code',
  },
  offerCopyUrl: {
    id: 'offer.copyUrl',
    description: 'Copy url',
    defaultMessage: 'Copy url',
  },
  offerDownloadFile: {
    id: 'offer.downloadFile',
    description: 'Download file',
    defaultMessage: 'Download file',
  },
  offerDownloadAll: {
    id: 'offer.downloadAll',
    description: 'Download all',
    defaultMessage: 'Download all',
  },
  offerNoCreativesAvailable: {
    id: 'offer.noCreativesAvailable',
    description: 'No creatives available',
    defaultMessage: 'No creatives available',
  },
  offerCreativesWasSent: {
    id: 'offer.creativesWasSent',
    description: 'The creatives you selected was successfully sent to your email.',
    defaultMessage: 'The creatives you selected was successfully sent to your email.',
  },
  offerEmailInstructions: {
    id: 'offer.emailInstructions',
    description: 'Email Instructions',
    defaultMessage: 'Email Instructions',
  },
  offerEmailInstructionsText1: {
    id: 'offer.emailInstructionsText1',
    description:
      'Our pre-designed templates for your email marketing campaigns are a great way to target and reach greater sources of traffic.',
    defaultMessage:
      'Our pre-designed templates for your email marketing campaigns are a great way to target and reach greater sources of traffic.',
  },
  offerEmailInstructionsText2: {
    id: 'offer.emailInstructionsText2',
    description: 'Please make sure to remove all non-accepted & unsubscribed users from your Mailing list before you hit send.',
    defaultMessage:
      'Please make sure to remove all non-accepted & unsubscribed users from your Mailing list before you hit send.',
  },
  offerApprovedFromLines: {
    id: 'offer.approvedFromLines',
    description: 'Approved From Lines',
    defaultMessage: 'Approved From Lines',
  },
  offerApprovedSubjectLines: {
    id: 'offer.approvedSubjectLines',
    description: 'Approved Subject Lines',
    defaultMessage: 'Approved Subject Lines',
  },
  offerSuppressionList: {
    id: 'offer.suppressionList',
    description: 'Suppression List',
    defaultMessage: 'Suppression List',
  },
  offerMergeList: {
    id: 'offer.mergeList',
    description: "Scrub your list with this one so you don't send emails to users who have unsubscribed.",
    defaultMessage: "Scrub your list with this one so you don't send emails to users who have unsubscribed.",
  },
  offerAudienceOffersG: {
    id: 'offer.audienceOffersG',
    description: 'G offers',
    defaultMessage: 'G offers',
  },
  offerAudienceOffers18: {
    id: 'offer.audienceOffers18',
    description: '18+ offers',
    defaultMessage: '18+ offers',
  },
  offerNotFound: {
    id: 'offer.notFound',
    description: 'Offer not found',
    defaultMessage: 'Offer not found',
  },
  offerSorryNotAvailable: {
    id: 'offer.sorryNotAvailable',
    description: 'Sorry, this offer is no longer available or does not exist',
    defaultMessage: 'Sorry, this offer is no longer available or does not exist',
  },
  offerLegalNotice: {
    id: 'offer.legalNotice',
    description: 'Legal Notice',
    defaultMessage: 'Legal Notice',
  },
  offerLegalNoticeText: {
    id: 'offer.legalNoticeText',
    description:
      'According to section 8 of CrakRevenue’s <a href="https://www.crakrevenue.com/tos/" target="_blank">Terms of Service</a>, all affiliates shall comply with all applicable international, federal and state laws including, but not limited to, the CAN-SPAM Act of 2003.',
    defaultMessage:
      'According to section 8 of CrakRevenue’s <a href="https://www.crakrevenue.com/tos/" target="_blank">Terms of Service</a>, all affiliates shall comply with all applicable international, federal and state laws including, but not limited to, the CAN-SPAM Act of 2003.',
  },
  offerMandatoryProcess: {
    id: 'offer.mandatoryProcess',
    description: 'Mandatory Process',
    defaultMessage: 'Mandatory Process',
  },
  offerBeforeSendingYourEmail: {
    id: 'offer.beforeSendingYourEmail',
    description: 'Before sending your email campaigns, be sure to <strong>do the following steps</strong>:',
    defaultMessage: 'Before sending your email campaigns, be sure to <strong>do the following steps</strong>:',
  },
  offerCopyTheHtmlCode: {
    id: 'offer.copyTheHtmlCode',
    description: 'Copy this HTML Template code & paste it into your email provider’s outbox:',
    defaultMessage: 'Copy this HTML Template code & paste it into your email provider’s outbox:',
  },
  offerCopyAndPasteUnsubscribeLink: {
    id: 'offer.copyAndPasteUnsubscribeLink',
    description: 'Copy & Paste the <strong>Unsubscribe link</strong> into the footer of the email template.',
    defaultMessage: 'Copy & Paste the <strong>Unsubscribe link</strong> into the footer of the email template.',
  },
  offerUseOurSupression: {
    id: 'offer.useOurSupression',
    description:
      'Use our <strong>Suppression List</strong>, or the <strong>Scrubbing tool</strong>, to remove all non-accepted users from your Mailing list.',
    defaultMessage:
      'Use our <strong>Suppression List</strong>, or the <strong>Scrubbing tool</strong>, to remove all non-accepted users from your Mailing list.',
  },
  offerUseSupressionList: {
    id: 'offer.useSupressionList',
    description: 'Use supression list',
    defaultMessage: 'Use supression list',
  },
  offerImDone: {
    id: 'offer.imDone',
    description: "I'm done",
    defaultMessage: "I'm done",
  },
  offerNoEmailTemplates: {
    id: 'offer.noEmailTemplates',
    description: "There's no email template for this offer",
    defaultMessage: "There's no email template for this offer",
  },
  offerRecentlyAdded: {
    id: 'offer.recentlyAdded',
    description: 'Recently Added Offers',
    defaultMessage: 'Recently Added Offers',
  },
  offerLoadingIframe: {
    id: 'offer.loadingIframe',
    description: 'Loading Iframe code ...',
    defaultMessage: 'Loading Iframe code ...',
  },
  offerLoadingPreview: {
    id: 'offer.loadingPreview',
    description: 'Loading preview ...',
    defaultMessage: 'Loading preview ...',
  },
  offerNoPreview: {
    id: 'offer.noPreview',
    description: 'No Preview available for this offer',
    defaultMessage: 'No Preview available for this offer',
  },
  offerId: {
    id: 'offer.id',
    description: 'Offer Id',
    defaultMessage: 'Id',
  },
  offerStatus: {
    label: {
      id: 'offer.status.label',
      description: 'Offer Status',
      defaultMessage: 'Your Status',
    },
    values: {
      'approval-required': {
        id: 'offer.status.values["approval-required"]',
        description: 'Offer Status : Approval Required',
        defaultMessage: 'Request approval',
      },
      approved: {
        id: 'offer.status.values.approved',
        description: 'Offer Status : Approved',
        defaultMessage: 'Approved',
      },
      pending: {
        id: 'offer.status.values.pending',
        description: 'Offer Status : Pending',
        defaultMessage: 'Pending approval',
      },
      rejected: {
        id: 'offer.status.values.rejected',
        description: 'Offer Status : Rejected',
        defaultMessage: 'Rejected',
      },
    },
  },
  offerDescription: {
    id: 'offer.description',
    description: 'Offer Description',
    defaultMessage: 'Offer Description',
  },
  offerPayout: {
    id: 'offer.payout',
    description: 'Offer Payout Title',
    defaultMessage: 'Payout',
  },
  offerConversionCap: {
    id: 'offer.conversionCap',
    description: 'Offer Conversion Cap',
    defaultMessage: 'Conversion cap',
  },
  offerEPC: {
    id: 'offer.epc',
    description: 'EPC',
    defaultMessage: 'EPC',
  },
  offerTopCountries: {
    id: 'offer.topCountries',
    description: 'Offer Top Countries',
    defaultMessage: 'Top Performing Countries',
  },
  offerAcceptedCountries: {
    id: 'offer.acceptedCountries',
    description: 'Accepted Countries',
    defaultMessage: 'Accepted Countries',
  },
  offerRestrictions: {
    id: 'offer.Restrictions',
    description: 'Offer Restrictions',
    defaultMessage: 'Restrictions',
  },
  offerChooseLandingPage: {
    id: 'offer.chooseLandingPage',
    description: 'Choose Landing Page',
    defaultMessage: 'Choose Landing Page',
  },
  offerChooseLandingPageOrPerformer: {
    id: 'offer.offerChooseLandingPageOrPerformer',
    description: 'Choose Page',
    defaultMessage: 'Choose Page',
  },
  offerAvailableLandingPage: {
    id: 'offer.availableLandingPage',
    description: 'Available Landing Pages',
    defaultMessage: 'Available Landing Pages',
  },
  offerAvailablePages: {
    id: 'offer.availablePages',
    description: 'Available Pages',
    defaultMessage: 'Available Pages',
  },
  noResultlandingPage: {
    id: 'offer.noResultlandingPage',
    description:
      "It looks like we couldn't find what you were searching for. You can refine your search or contact us if you would like\n" +
      '          to discuss the addition of a new landing page',
    defaultMessage:
      "It looks like we couldn't find what you were searching for. You can refine your search or contact us if you would like\n" +
      '          to discuss the addition of a new landing page',
  },
  contactUsBtn: {
    id: 'offer.contactUsBtn',
    description: 'Contact us',
    defaultMessage: 'Contact us',
  },
  offerMoreInfoAboutMultiCPA: {
    id: 'offer.MoreInfoAboutMultiCPA',
    description: 'More about Multi-CPA',
    defaultMessage: 'More about Multi-CPA',
  },
  offerGetAdTools: {
    id: 'offer.GetAdTools',
    description: 'Offer - Get Ad Tools',
    defaultMessage: 'Get Ad Tools',
  },
  offerAdTools: {
    id: 'offer.AdTools',
    description: 'Offer - Ad Tools',
    defaultMessage: 'Ad Tools',
  },
  notEnoughData: {
    id: 'offer.notEnoughData',
    description: 'Not enough data yet, start promoting!',
    defaultMessage: 'Not enough data yet, start promoting!',
  },
  notAvailable: {
    id: 'offer.notAvailable',
    description: 'N/A',
    defaultMessage: 'N/A',
  },
  copyInformation: {
    id: 'offer.copyInformation',
    description: 'Copy information',
    defaultMessage: 'Copy information',
  },
  shareOffer: {
    id: 'offer.shareOffer',
    description: 'Share the offer',
    defaultMessage: 'Share the offer',
  },
  shareOfferTitle: {
    id: 'offer.shareOfferTitle',
    description: 'Do you think one of your friends can benefit from this offer?',
    defaultMessage: 'Do you think one of your friends can benefit from this offer?',
  },
  shareIt: {
    id: 'offer.shareIt',
    description: 'share it!',
    defaultMessage: 'share it!',
  },
  shareOfferDescriptionPartOne: {
    id: 'offer.shareOfferDescriptionPartOne',
    description: 'To top it off, if they are not already a ',
    defaultMessage: 'To top it off, if they are not already a ',
  },
  shareOfferDescriptionPartTwo: {
    id: 'offer.shareOfferDescriptionPartTwo',
    description: ', you could earn a cool 5% referral commission.',
    defaultMessage: ', you could earn a cool 5% referral commission. ',
  },
  showAcceptedCountries: {
    id: 'offer.showAcceptedCountries',
    description: 'Show accepted countries',
    defaultMessage: 'Show accepted countries',
  },
  moreDetails: {
    id: 'offer.moreDetails',
    description: 'More details',
    defaultMessage: 'More details',
  },
};

const PaymentHistory = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 150"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_150"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="payment-history" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 147" fill="none" height="30" id="Rectangle_147" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 88" fill="none" id="Ellipse_88" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 173" fill="none" id="Ligne_173" x2="30" y2="30" />
          <line data-name="Ligne 174" fill="none" id="Ligne_174" x1="30" y2="30" />
          <line data-name="Ligne 175" fill="none" id="Ligne_175" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 176" fill="none" id="Ligne_176" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 177" fill="none" id="Ligne_177" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 178" fill="none" id="Ligne_178" transform="translate(15)" y2="30" />
          <line data-name="Ligne 179" fill="none" id="Ligne_179" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 180" fill="none" id="Ligne_180" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 148"
            fill="none"
            height="21.218"
            id="Rectangle_148"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 89"
            fill="none"
            id="Ellipse_89"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 149"
            fill="none"
            height="15.004"
            id="Rectangle_149"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 90"
            fill="none"
            id="Ellipse_90"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <text
            data-name="$"
            fill="none"
            fontFamily="Montserrat"
            fontSize="10"
            fontWeight="800"
            id="_"
            stroke="#fff"
            strokeWidth="0.7"
            transform="translate(11.546 18.111)"
          >
            <tspan x="0" y="0">
              $
            </tspan>
          </text>
          <path
            d="M3.5,14.624A10.242,10.242,0,1,0,6.62,6.339"
            data-name="Tracé 1509"
            fill="none"
            id="Tracé_1509"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(1.274 1.269)"
          />
          <path
            d="M4.177,8.7,7.9,7.165,4.713,4.7Z"
            data-name="Tracé 1510"
            fill="none"
            id="Tracé_1510"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(1.519 1.71)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PaymentHistory;

import consts from '../../../utils/consts';

export default class CompleteProfileService {
  static hasToCompleteProfile({ affInfos }) {
    let hasToCompleteProfile = true;
    if (affInfos) {
      const requireAddress = !consts.COUNTRIES_WITHOUT_ZIP.includes(affInfos.country) && !affInfos.zipcode;
      hasToCompleteProfile = !affInfos.address1 || !affInfos.city || !affInfos.country || requireAddress;
    }
    return hasToCompleteProfile;
  }
}

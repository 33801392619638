const Smartlink = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 182"
          fill="#fff"
          height="30"
          id="Rectangle_182"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="smartlink" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 179" fill="none" height="30" id="Rectangle_179" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 115" fill="none" id="Ellipse_115" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 234" fill="none" id="Ligne_234" x2="30" y2="30" />
          <line data-name="Ligne 235" fill="none" id="Ligne_235" x1="30" y2="30" />
          <line data-name="Ligne 236" fill="none" id="Ligne_236" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 237" fill="none" id="Ligne_237" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 238" fill="none" id="Ligne_238" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 239" fill="none" id="Ligne_239" transform="translate(15)" y2="30" />
          <line data-name="Ligne 240" fill="none" id="Ligne_240" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 241" fill="none" id="Ligne_241" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 180"
            fill="none"
            height="21.218"
            id="Rectangle_180"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 116"
            fill="none"
            id="Ellipse_116"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 181"
            fill="none"
            height="15.004"
            id="Rectangle_181"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 117"
            fill="none"
            id="Ellipse_117"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <path
            d="M18.719,6.633V4.867a.426.426,0,0,0-.427-.426H9.441a6.111,6.111,0,1,0,0,12.213H18.3a.428.428,0,0,0,0-.854H9.441a5.253,5.253,0,1,1,0-10.505h8.432V6.633Z"
            data-name="Tracé 1528"
            fill="#fff"
            id="Tracé_1528"
            transform="translate(1.126 1.613)"
          />
          <path
            d="M22.838,14.711A6.124,6.124,0,0,0,16.727,8.6H7.873a.428.428,0,1,0,0,.856h8.857a5.253,5.253,0,1,1,0,10.505H8.3V18.627H7.446v1.765a.428.428,0,0,0,.428.428h8.857a6.124,6.124,0,0,0,6.107-6.108Z"
            data-name="Tracé 1529"
            fill="#fff"
            id="Tracé_1529"
            transform="translate(2.708 3.127)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Smartlink;

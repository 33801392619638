const ArrowRight = () => (
  <svg height="16.602" id="arrow-right" viewBox="0 0 16.602 16.602" width="16.602" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0,8.442,6.52,4.967c.77-.41.77-1.082,0-1.492L0,0"
      fill="none"
      id="Vector"
      stroke="#00bd00"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="translate(5.01 4.08)"
    />
    <path
      d="M0,0H16.6V16.6H0Z"
      data-name="Vector"
      fill="none"
      id="Vector-2"
      opacity="0"
      transform="translate(16.602 16.602) rotate(180)"
    />
  </svg>
);

export default ArrowRight;

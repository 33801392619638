import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import messages from '../i18n/base-en.js';

import LoginForm from '../components/Login/LoginForm';
import SignupSelection from '../components/Login/SignupSelection';
import LoginErrors from '../components/Login/LoginErrors';
import Offline from '../components/Offline/Offline';

import { fetchLoginAuth } from '../components/Login/actions/login-actions';
import { getGoogleUrl } from '../components/Login/actions/signup-actions';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import { getNiceToSeeYouFromSkinName } from '../utils/logoHelper';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this);

    this.state = {
      googleIsLoading: false,
      signupActive: props.route.path === 'signup-choice',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      route: { path },
    } = this.props;

    if (path !== nextProps.route.path) {
      this.setState({
        signupActive: nextProps.route.path === 'signup-choice',
      });
    }
  }

  handleLoginFormSubmit(data) {
    const { dispatch } = this.props;

    dispatch(fetchLoginAuth(data));
  }

  handleGoogleLogin() {
    const { dispatch } = this.props;

    this.setState({ googleIsLoading: true });
    dispatch(getGoogleUrl('access/connection'))
      .then((data) => {
        window.location = data.body.url;
        this.setState({ googleIsLoading: false });
      })
      .catch(() => this.setState({ googleIsLoading: false }));
  }

  getSignUpLink(skinName, signupActive) {
    if (skinName === 'bluechew') {
      return (
        <a href="http://bluerevenue.com/form/">
          <span className={signupActive ? 'active' : ''} data-cy="link-signup-bluerevenue">
            <FormattedMessage {...messages.genericTextSignUp} />
          </span>
        </a>
      );
    }

    if (skinName === 'fansrevenue') {
      return (
        <a href="https://www.fansrevenue.com/join/">
          <span className={signupActive ? 'active' : ''} data-cy="link-signup-fansrevenue">
            <FormattedMessage {...messages.genericTextSignUp} />
          </span>
        </a>
      );
    }

    return (
      <Link onlyActiveOnIndex={false} to="/access/create-account">
        <span className={signupActive ? 'active' : ''} data-cy="link-signup-crakrevenue">
          <FormattedMessage {...messages.genericTextSignUp} />
        </span>
      </Link>
    );
  }

  render() {
    const { dispatch, error, isFetchingAuth, skinConfigurations } = this.props;
    const { signupActive, googleIsLoading } = this.state;

    const SKIN = new SkinConfigurationsUtils(skinConfigurations);
    const SKIN_NAME = SKIN.getSkinName().toLowerCase();

    return (
      <Offline
        isLoginPage
        link={{
          url: SKIN.getPropertyValue('Global', 'OfficialWebsite', 'url') || 'http://crakrevenue.com',
          text: <FormattedMessage {...messages.loginPageGoBackToWebsite} />,
          isExternal: true,
        }}
      >
        <div className="row" id="login-page">
          <div
            className={
              signupActive
                ? 'col-md-4 col-sm-12 hidden-xs image-container text-center image-sign-up'
                : 'hidden-xs image-container text-center image-login'
            }
            style={{
              backgroundImage: SKIN_NAME !== 'crakrevenue' && `url(${getNiceToSeeYouFromSkinName(SKIN_NAME)})`,
            }}
          >
            <span className="image-sign-up" key="image-sign-up">
              {SKIN_NAME === 'bluechew' && (
                <a className="image-link" data-cy="button-signup-bluerevenue" href="https://bluerevenue.com/form/">
                  <FormattedMessage {...messages.genericTextSignUp} />
                </a>
              )}
              {SKIN_NAME === 'fansrevenue' && (
                <a className="image-link" data-cy="button-signup-fansrevenue" href="https://fansrevenue.com/join/">
                  <FormattedMessage {...messages.genericTextSignUp} />
                </a>
              )}
            </span>
          </div>
          <div className="text-container">
            <div>
              <div className="registration-links">
                <Link data-cy="link-login-submit" onlyActiveOnIndex={false} to="/login">
                  <span className={!signupActive ? 'active' : ''}>
                    <FormattedMessage {...messages.genericTextLogin} />
                  </span>
                </Link>
                {this.getSignUpLink(SKIN_NAME, signupActive)}
              </div>

              {signupActive ? (
                <SignupSelection skinName={SKIN_NAME} />
              ) : (
                <LoginForm
                  googleIsLoading={googleIsLoading}
                  googleLogin={this.handleGoogleLogin}
                  isFetchingAuth={isFetchingAuth}
                  onSubmit={this.handleLoginFormSubmit}
                />
              )}
              <LoginErrors data-cy="error-login-form" dispatch={dispatch} error={error} showErrorTime={6000} />
            </div>
          </div>
        </div>
      </Offline>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.any,
  isFetchingAuth: PropTypes.bool.isRequired,
  isFetchingTfaValidation: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  'route.path': PropTypes.any,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  error: state.login.error,
  isFetchingAuth: state.login.isFetchingAuth,
  skinConfigurations: state.skinConfigurations.data,
  isFetchingTfaValidation: state.login.isFetchingTfaValidation,
}))(Login);

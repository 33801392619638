function NewProductLabel({ className }) {
  return (
    <svg className={className} viewBox="0 0 30.316 30.316" x="0px" y="0px">
      <g>
        <path
          d="M29.19,6.406c-2.319-4.019-7.478-5.399-11.494-3.08L0,13.542l8.414,14.574L26.11,17.899
          C30.129,15.58,31.51,10.424,29.19,6.406z M25.51,16.857L8.855,26.474L1.643,13.983l16.654-9.616
          c3.444-1.988,7.864-0.804,9.853,2.64C30.137,10.451,28.953,14.87,25.51,16.857z"
        />
        <path
          d="M18.898,5.408L3.284,14.423l6.011,10.409l15.613-9.015c2.875-1.66,3.859-5.335,2.2-8.209
          C25.448,4.733,21.772,3.749,18.898,5.408z M12.988,18.563l-1.836-1.104c-0.511-0.306-1.097-0.688-1.588-1.062l-0.015,0.018
          c0.346,0.541,0.699,1.127,1.096,1.813l0.732,1.268L10.502,20l-2.428-4.207l1.11-0.643l1.764,1.038
          c0.506,0.3,1.059,0.68,1.52,1.045l0.019-0.011c-0.383-0.52-0.729-1.076-1.093-1.707l-0.71-1.229l0.873-0.507l2.43,4.208
          L12.988,18.563z M14.812,17.512l-2.429-4.208l2.597-1.499l0.45,0.78l-1.641,0.947l0.503,0.874l1.549-0.894l0.447,0.773
          l-1.549,0.895l0.577,0.999l1.729-0.999l0.45,0.779L14.812,17.512z M21.125,13.866l-1.4-1.604c-0.324-0.378-0.621-0.73-0.948-1.184
          l-0.013,0.007c0.199,0.519,0.36,0.958,0.506,1.439l0.646,2.041l-1.041,0.603l-3.429-3.63l1.017-0.588l1.321,1.551
          c0.382,0.446,0.782,0.94,1.098,1.323l0.012-0.007c-0.193-0.495-0.387-1.059-0.58-1.638l-0.639-1.929l1.012-0.584l1.361,1.579
          c0.377,0.438,0.705,0.849,1.039,1.28l0.012-0.008c-0.209-0.505-0.398-1.052-0.6-1.601l-0.652-1.921l0.97-0.56l1.342,4.836
          L21.125,13.866z M25.309,10.15c-0.623,0.361-1.42,0.147-1.778-0.477c-0.358-0.622-0.146-1.419,0.478-1.778
          c0.621-0.358,1.418-0.146,1.777,0.476C26.145,8.995,25.932,9.792,25.309,10.15z"
        />
      </g>
    </svg>
  );
}

export default NewProductLabel;

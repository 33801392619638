function ShareIcon() {
  return (
    <svg viewBox="0 0 469.33 512" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Calque 2" id="Calque_2">
        <g data-name="Calque 1" id="Calque_1-2">
          <path d="M389.33,352a79.82,79.82,0,0,0-60.21,27.42L156.06,280.76a79.8,79.8,0,0,0,0-49.53l173.05-98.66a79.78,79.78,0,1,0-15.83-27.79l-173,98.65a80,80,0,1,0,0,105.13l173,98.66A80,80,0,1,0,389.33,352Zm0-320a48,48,0,1,1-48,48A48.05,48.05,0,0,1,389.33,32ZM80,304a48,48,0,1,1,48-48A48.06,48.06,0,0,1,80,304ZM389.33,480a48,48,0,1,1,48-48A48.06,48.06,0,0,1,389.33,480Z" />
        </g>
      </g>
    </svg>
  );
}

export default ShareIcon;

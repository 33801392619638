import PropTypes from 'prop-types';
import { FormattedNumber, injectIntl } from 'react-intl';

import { Container } from '../../components/reusables';
import { Gtm } from '../../utils/gtm';
const PaymentCard = ({ amount, currency, date, handleInvoiceRequest, handlePaymentRequest, id, status, type }) => {
  const url = new URL(window.location.href);
  const isPrintPaymentEnabled = url.searchParams.get('print_payment') === 'true';
  return (
    <Container className={`z-depth-2 card ${status.toLowerCase()}`}>
      <div className="payment-info">
        <div className={type.toLowerCase() === 'payment' ? 'primary-color' : ''}>
          {type} N<sup>o</sup>
          {id}
        </div>
        <div className="status-print">
          <div className="status">{status}</div>
          {type !== 'Payment' && (
            <i
              className="pdf-invoice material-icons primary-color"
              onClick={() => {
                handleInvoiceRequest(id);
                Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - invoice');
              }}
            >
              print
            </i>
          )}
          {isPrintPaymentEnabled && date >= '2024-06-01' && type === 'Payment' ? (
            <i
              className="pdf-invoice material-icons primary-color"
              onClick={() => {
                handlePaymentRequest(id);
                Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - payment');
              }}
            >
              print
            </i>
          ) : null}
        </div>
      </div>
      <div className="price">
        <FormattedNumber currency={currency} style="currency" value={amount} />
      </div>
      <div className="date">{date}</div>
    </Container>
  );
};

PaymentCard.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleInvoiceRequest: PropTypes.func.isRequired,
  handlePaymentRequest: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default injectIntl(PaymentCard);

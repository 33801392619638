import moment from 'moment';

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  referralCommission: {},
  referralCommissionIsFetching: false,
  referrals: [],
  referralsInfos: {
    currentPage: 1,
    total: 0,
    pageCount: 0,
  },
  referralsIsFetching: false,
  referralsSettings: {
    filters: {
      'Stat.date': {
        conditional: 'BETWEEN',
        values: ['2000-01-01', moment().format('YYYY-MM-DD')],
      },
    },
    limit: 0,
    page: 1,
    sort: {
      'Stat.date': 'desc',
    },
  },
  referralsTimeStamp: 0,
};

export const referrals = (state = initialState, action = {}) => {
  const { referralsInfos, referralsSettings } = state;
  const referralsFilters = referralsSettings.filters;

  switch (action.type) {
    case ACTIONS.REQUEST_REFERRALS:
      return {
        ...state,
        referralsIsFetching: true,
      };

    case ACTIONS.RECEIVE_REFERRALS:
      return {
        ...state,
        referralsTimeStamp: Date.now(),
        referralsIsFetching: false,
        referrals: action.data.data,
        referralsSettings: {
          ...referralsSettings,
          page: action.data.page,
        },
        referralsInfos: {
          ...referralsInfos,
          currentPage: action.data.page,
          total: action.data.count || 0,
          pageCount: action.data.pageCount || 0,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_REFERRALS:
      return {
        ...state,
        referralsIsFetching: false,
      };

    case ACTIONS.CHANGE_REFERRALS_DATE_FILTERS:
      return {
        ...state,
        referralsSettings: {
          ...referralsSettings,
          filters: {
            ...referralsFilters,
            'Stat.date': {
              conditional: 'BETWEEN',
              values: action.dates.split(','),
            },
          },
        },
      };

    case ACTIONS.REQUEST_REFERRAL_COMMISSION:
      return {
        ...state,
        referralCommissionIsFetching: true,
      };

    case ACTIONS.RECEIVE_REFERRAL_COMMISSION:
      return {
        ...state,
        referralCommission: action.data,
        referralCommissionIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_REFERRAL_COMMISSION:
      return {
        ...state,
        referralCommissionIsFetching: false,
      };

    default:
      return state;
  }
};

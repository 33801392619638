import { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import { Info, LoadingBar } from '../reusables';

class Container extends Component {
  constructor(props) {
    super(props);

    this.handleCollapse = this.handleCollapse.bind(this);

    this.state = {
      collapsed: !props.isOpen,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props;

    if (isOpen !== nextProps.isOpen) {
      this.setState({
        collapsed: !nextProps.isOpen,
      });
    }
  }

  handleCollapse() {
    const { collapsable, onToggle } = this.props;
    const { collapsed } = this.state;

    if (collapsable) {
      this.setState({
        collapsed: !collapsed,
      });

      if (onToggle) {
        onToggle();
      }
    }
  }

  render() {
    const {
      children,
      className,
      collapsable,
      icon,
      id,
      info,
      link,
      loading,
      overlay,
      Svg,
      title,
      entirelyClickable,
      ignorePadding,
    } = this.props;
    const { collapsed } = this.state;

    const linkProps = link ? { to: link.href, title: link.title, target: link.target } : {};

    let LinkOrA = (props) => <Link {...props} {...linkProps} />;
    if (link && link.isExternal) {
      // eslint-disable-next-line
      LinkOrA = (props) => <a {...props} {...linkProps} />;
    }

    const ContainerTag = link && entirelyClickable ? LinkOrA : 'div';
    const LinkTag = entirelyClickable ? 'div' : LinkOrA;

    return (
      <ContainerTag
        className={`div-container ${className} ${entirelyClickable ? 'clickable' : ''} ${collapsed ? '' : 'isOpen'}`}
        id={id}
        {...linkProps}
      >
        {title ? (
          <div className={`div-container-title ${collapsable ? 'collapsable pointer' : ''}`} onClick={this.handleCollapse}>
            <h2 className={`title ${Svg ? 'with-svg' : ''}`}>
              {icon ? <i className="material-icons">{icon}</i> : null}
              {Svg ? <Svg className="icon-svg" /> : null}
              {title}
              {info ? (
                <Info
                  onClick={() => {
                    info.gtmEvent();
                  }}
                  stopPropagation
                  title={info.title}
                  url={info.url}
                />
              ) : null}
            </h2>
            {collapsable ? (
              <i className="material-icons collapse-icon pointer">{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
            ) : null}
          </div>
        ) : null}
        {loading ? <LoadingBar className="container-loader" /> : null}
        {(!collapsable || !collapsed) && (
          <div className={ignorePadding ? '' : 'div-container-content'}>
            {children}
            {overlay ? <div className="overlay">{overlay}</div> : null}
          </div>
        )}
        {link ? (
          <div className="div-container-footer-container">
            <div className="div-container-footer clearfix">
              <LinkTag
                className="link"
                onClick={() => {
                  link.gtmEvent();
                }}
              >
                {link.text}
                <i className="material-icons">keyboard_arrow_right</i>
              </LinkTag>
            </div>
          </div>
        ) : null}
      </ContainerTag>
    );
  }
}

Container.propTypes = {
  Svg: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  collapsable: PropTypes.bool,
  entirelyClickable: PropTypes.bool,
  icon: PropTypes.any,
  id: PropTypes.string,
  info: PropTypes.object,
  isOpen: PropTypes.bool,
  link: PropTypes.object,
  loading: PropTypes.bool,
  onToggle: PropTypes.func,
  overlay: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Container;

import request from '../../../utils/Req';
import { loggedInFetching } from '../../../utils/initialFetching';
import intercom from '../../../utils/intercom';
import HandleAPIError from '../../../utils/handleAPIError';
import messages from '../../../i18n/base-en.js';
import { getCookie, setLocalStorageValue, deleteLocalStorageKey, setCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';
import store from '../../../create-store';
import { fetchProfile } from '../../../actions/profile-actions';

const { API_URL } = config;
const { ACTIONS } = consts;

export const setLoginError = (error) => ({
  type: ACTIONS.SET_LOGIN_ERROR,
  error,
});
export const login = () => ({ type: ACTIONS.LOGIN });

export const logout = () => async (dispatch) => {
  deleteLocalStorageKey('isOverrided');
  intercom.shutdown();
  intercom.boot();
  try {
    await request.get(`${API_URL}/clear-session`).withCredentials();
    const response = await request.get(`${API_URL}/csrf`).withCredentials();
    if (response?.body?.csrfToken) {
      setCookie('XSRF-TOKEN', response.body.csrfToken);
    }
  } catch (e) {
    console.log('error at logout');
  }
  dispatch({ type: ACTIONS.LOGOUT });
};

const requestLoginAuth = () => ({ type: ACTIONS.REQUEST_LOGIN_AUTH });
const receiveLoginAuth = () => ({ type: ACTIONS.RECEIVE_LOGIN_AUTH });
const failedToReceiveLoginAuth = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_LOGIN_AUTH,
});

export const fetchLoginAuth =
  ({ email, password, rememberMe }) =>
  (dispatch) =>
    new Promise((fulfill, reject) => {
      dispatch(requestLoginAuth());

      const emailPwdRequest = request
        .post(`${API_URL}/auth`)
        .send({ email, password, rememberMe })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials();

      emailPwdRequest
        .then((authRes) => {
          dispatch(receiveLoginAuth());

          if (authRes.body.csrfToken) {
            setCookie('XSRF-TOKEN', authRes.body.csrfToken);
          }

          switch (authRes.body.accountStatus) {
            case 'active':
              dispatch(fetchProfile())
                .then(() => {
                  const newState = store.getState();
                  const fetchingPromises = [
                    loggedInFetching(newState.profile.data, dispatch, {
                      path: '/',
                      query: {},
                    }),
                  ];
                  Promise.all(fetchingPromises);
                })
                .catch((res) => {
                  new HandleAPIError(res, dispatch).handleAll();
                });
              break;

            case 'pending':
              dispatch(setLoginError(messages.loginFormAccountInValidation));
              reject();
              break;

            default:
              dispatch(
                setLoginError(authRes.body.reason ? authRes.body.reason : `Your account is ${authRes.body.accountStatus}`)
              );
              reject();
              break;
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.body) {
            let errorMessageDisplay;

            switch (err.response.body.errorMessage) {
              case 'Invalid Authorization':
                errorMessageDisplay = messages.loginFormEmailOrPasswordAreNotValid;
                break;
              case 'Affiliate lacks permission to have an API Key':
                errorMessageDisplay = messages.loginFormLackOfPermissions;
                break;
              default:
                errorMessageDisplay = err.response.body.errorMessage;
                break;
            }

            dispatch(setLoginError(errorMessageDisplay));
          } else {
            dispatch(failedToReceiveLoginAuth());
            console.error('Failed to reach API');
          }
        });
    });

export const fetchOverrideAuth =
  ({ email, password, userId }) =>
  (dispatch) => {
    dispatch(requestLoginAuth());
    return new Promise((fulfill, reject) =>
      request
        .post(`${API_URL}/override`)
        .send({ email, password, userId })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((authError, authRes) => {
          if (authRes) {
            dispatch(receiveLoginAuth());
            if (!authError) {
              // Put HO login data in login-reducer
              if (authRes.body.accountStatus === 'active') {
                // Get profile informations
                setLocalStorageValue('isOverrided', true);
                dispatch(fetchProfile())
                  .then(() => {
                    const newState = store.getState();
                    const fetchingPromises = [
                      loggedInFetching(newState.profile.data, dispatch, {
                        path: '/',
                        query: {},
                      }),
                    ];
                    Promise.all(fetchingPromises);
                  })
                  .catch((res) => {
                    new HandleAPIError(res, dispatch).handleAll();
                  });
              } else if (authRes.body.data.accountStatus === 'pending') {
                dispatch(setLoginError(messages.loginFormAccountInValidation));
                reject();
              } else {
                dispatch(setLoginError(`The account is ${authRes.body.accountStatus}`));
                reject();
              }
            } else {
              let errorMessageDisplay;

              if (authRes.body.errorMessage) {
                switch (authRes.body.errorMessage) {
                  case 'Invalid Authorization':
                    errorMessageDisplay = messages.loginFormEmailOrPasswordAreNotValid;
                    break;
                  case 'Affiliate lacks permission to have an API Key':
                    errorMessageDisplay = messages.loginFormLackOfPermissions;
                    break;
                  case "You don't have the right to override accounts.":
                    errorMessageDisplay = messages.loginFormCantOverride;
                    break;
                  default:
                    errorMessageDisplay = authRes.body.errorMessage;
                    break;
                }
              } else {
                console.error('Failed to reach API');
              }

              dispatch(setLoginError(errorMessageDisplay));
            }
          } else {
            dispatch(failedToReceiveLoginAuth());
            console.error('Failed to reach API');
          }
        })
    );
  };

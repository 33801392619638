const Dashboard = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 76"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_76"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="dashboard" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 77" fill="none" height="30" id="Rectangle_77" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 17" fill="none" id="Ellipse_17" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 6" fill="none" id="Ligne_6" x2="30" y2="30" />
          <line data-name="Ligne 7" fill="none" id="Ligne_7" x1="30" y2="30" />
          <line data-name="Ligne 8" fill="none" id="Ligne_8" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 9" fill="none" id="Ligne_9" transform="translate(0 7.6)" x2="30.412" />
          <line data-name="Ligne 10" fill="none" id="Ligne_10" transform="translate(0 22.81)" x2="30.412" />
          <line data-name="Ligne 11" fill="none" id="Ligne_11" transform="translate(15)" y2="30" />
          <line data-name="Ligne 12" fill="none" id="Ligne_12" transform="translate(7.6)" y2="30.412" />
          <line data-name="Ligne 13" fill="none" id="Ligne_13" transform="translate(22.81)" y2="30.412" />
          <rect
            data-name="Rectangle 78"
            fill="none"
            height="21.509"
            id="Rectangle_78"
            transform="translate(4.451 4.451)"
            width="21.509"
          />
          <circle
            cx="10.755"
            cy="10.755"
            data-name="Ellipse 18"
            fill="none"
            id="Ellipse_18"
            r="10.755"
            transform="translate(4.451 4.451)"
          />
          <rect
            data-name="Rectangle 79"
            fill="none"
            height="15.21"
            id="Rectangle_79"
            transform="translate(7.6 7.6)"
            width="15.21"
          />
          <circle
            cx="7.604"
            cy="7.604"
            data-name="Ellipse 19"
            fill="none"
            id="Ellipse_19"
            r="7.604"
            transform="translate(7.602 7.602)"
          />
          <rect
            data-name="Rectangle 80"
            fill="none"
            height="11.525"
            id="Rectangle_80"
            rx="0.803"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(4.854 5.439)"
            width="9.176"
          />
          <rect
            data-name="Rectangle 81"
            fill="none"
            height="5.763"
            id="Rectangle_81"
            rx="0.803"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(4.854 19.211)"
            width="9.176"
          />
          <rect
            data-name="Rectangle 82"
            fill="none"
            height="11.525"
            id="Rectangle_82"
            rx="0.803"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(16.38 13.448)"
            width="9.176"
          />
          <rect
            data-name="Rectangle 83"
            fill="none"
            height="5.763"
            id="Rectangle_83"
            rx="0.803"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1"
            transform="translate(16.38 5.439)"
            width="9.176"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Dashboard;

import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import CloseModalIcon from './svg/Gamification/CloseModalIcon.react';

const Tooltip = ({
  id,
  children,
  className,
  closeDelay = 500,
  closeIcon,
  content,
  onOpen = () => {},
  onClose = () => {},
  position,
  isHoverable = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const hideTimeoutRef = useRef(null);
  const tooltipRef = useRef(null);
  const wrapperRef = useRef(null);

  const showTooltip = (event) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    if (visible) {
      return;
    }
    const rect = event.currentTarget.getBoundingClientRect();
    let newTooltipStyle = {};

    switch (position) {
      case 'left':
        newTooltipStyle = {
          top: rect.top + window.scrollY + rect.height / 2,
          left: rect.left + window.scrollX - 10,
          transform: 'translateX(-100%) translateY(-50%)',
        };
        break;
      case 'right':
        newTooltipStyle = {
          top: rect.top + window.scrollY + rect.height / 2,
          left: rect.right + window.scrollX + 10,
          transform: 'translateY(-50%)',
        };
        break;
      case 'top':
        newTooltipStyle = {
          top: rect.top + window.scrollY - 10,
          left: rect.left + window.scrollX + rect.width / 2,
          transform: 'translateX(-50%) translateY(-100%)',
        };
        break;
      case 'bottom':
      default:
        newTooltipStyle = {
          top: rect.bottom + window.scrollY + 10,
          left: rect.left + window.scrollX + rect.width / 2,
          transform: 'translateX(-50%)',
        };
        break;
    }
    setTooltipStyle(newTooltipStyle);
    setVisible(true);
  };

  useEffect(() => {
    if (visible) {
      onOpen();
    }
  }, [visible]);

  const hideTooltip = useCallback(
    (event, useTimer = true) => {
      if (!visible) {
        return;
      }
      if (useTimer) {
        hideTimeoutRef.current = setTimeout(() => {
          setVisible(false);
          hideTimeoutRef.current = null;
        }, closeDelay);
      } else {
        setVisible(false);
        hideTimeoutRef.current = null;
      }
      onClose();
    },
    [closeDelay, onClose, visible]
  );

  const handleTooltipClick = (event) => {
    event.stopPropagation();
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        event.target instanceof Node &&
        tooltipRef.current &&
        event.target instanceof Node &&
        !tooltipRef.current.contains(event.target) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        hideTooltip(event, false);
      }
    },
    [hideTooltip]
  );

  const handleOnMouseLeave = useCallback(
    (event) => {
      if (
        event.target instanceof Node &&
        tooltipRef.current &&
        event.relatedTarget instanceof Node &&
        !tooltipRef.current.contains(event.relatedTarget) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.relatedTarget)
      ) {
        hideTooltip(event, true);
      }
    },
    [hideTooltip]
  );

  const handleClick = (event) => {
    event.preventDefault();
    if (!visible) {
      showTooltip(event);
    } else {
      hideTooltip(event, false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const tooltipElement = visible ? (
    <>
      <button onClick={(event) => hideTooltip(event, false)} type="button">
        <CloseModalIcon />
      </button>
      <div
        className={`${className} tooltip`}
        data-position={position}
        id={id ? id : 'tooltip-custom'}
        onClick={handleTooltipClick}
        onMouseEnter={(e) => {
          showTooltip(e);
        }}
        onMouseLeave={(e) => {
          handleOnMouseLeave(e);
        }}
        ref={tooltipRef}
        style={tooltipStyle}
      >
        {content}
        {closeIcon ? (
          <button className="tooltip-close" onClick={(event) => hideTooltip(event, false)} type="button">
            <CloseModalIcon />
          </button>
        ) : null}
      </div>
    </>
  ) : null;

  return (
    <div
      className="tooltip-wrapper"
      onClick={handleClick}
      onMouseEnter={(e) => {
        if (isHoverable) {
          showTooltip(e);
        }
      }}
      onMouseLeave={(e) => {
        if (isHoverable) {
          handleOnMouseLeave(e);
        }
      }}
      ref={wrapperRef}
    >
      <div>{children}</div>
      {createPortal(tooltipElement, document.body)}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  closeDelay: PropTypes.number,
  closeIcon: PropTypes.bool,
  content: PropTypes.node,
  id: PropTypes.string,
  isHoverable: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

Tooltip.defaultProps = {
  position: 'bottom',
};

export default Tooltip;

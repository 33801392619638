const JerkmateIcon = () => (
  <svg height="16.556" viewBox="0 0 28.18 16.556" width="28.18" xmlns="http://www.w3.org/2000/svg">
    <g id="TipsnGolds" transform="translate(109.775 -18.98)">
      <g data-name="TipsnGolds" id="TipsnGolds-2" transform="translate(-109.847 19.067)">
        <g id="Coin" transform="translate(0 3.716) rotate(-8)">
          <path
            d="M1.641,0A1.982,1.982,0,0,0,.029,2.294,2.042,2.042,0,0,0,1.7,3.968Z"
            fill="#fff"
            id="Mouth"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(0 4.836)"
          />
          <path
            d="M0,0A2.025,2.025,0,0,1,1.674,2.294,2.042,2.042,0,0,1,0,3.968Z"
            fill="#fff"
            id="Mouth-2"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(25.015 4.774)"
          />
          <path
            d="M5.084,12.772A5.107,5.107,0,0,1,0,7.688V6.014A5.107,5.107,0,0,1,5.084.93H8.308A2.028,2.028,0,0,1,9.8,0h.372a1.864,1.864,0,0,1,1.488.93H14.88a5.107,5.107,0,0,1,5.084,5.084V7.688a5.107,5.107,0,0,1-5.084,5.084h-9.8Z"
            fill="none"
            id="Union_1"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(3.315 0)"
          />
          <g id="Rectangle_15" transform="translate(4.955 2.241) rotate(0.137)">
            <path
              d="M3.968,0h7.626a4,4,0,0,1,3.968,3.968h0a4,4,0,0,1-3.968,3.968H3.968A4,4,0,0,1,0,3.968H0A4,4,0,0,1,3.968,0Z"
              data-name="Tracé 1759"
              fill="none"
              id="Tracé_1759"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="1"
              transform="translate(0.62 0.62)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default JerkmateIcon;

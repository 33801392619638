const SpeakerIcon = () => (
  <svg viewBox="0 0 501.3 498.56" xmlns="http://www.w3.org/2000/svg">
    <title>Speaker Icon</title>
    <path d="M463.81,265.28,331.24,15.91a30,30,0,1,0-53,28.17l132.6,249.37a30,30,0,0,0,53-28.17Z" />
    <path d="M256,66.15l-3.64,6.76c-25.61,47.55-64.93,88.14-110.13,117l72.14,135.67c49.16-21.35,104.33-32.11,158.08-26.77l7.66.77Z" />
    <path d="M116.6,205.57,23.89,254.85A45,45,0,0,0,5.28,315.72l28.17,53A45,45,0,0,0,94.31,387.3L187,338Zm-24,114.68a15,15,0,0,1-14.08-26.49L105,279.68a15,15,0,0,1,14.08,26.49Z" />
    <path d="M222.87,435l25.48-13.55A45,45,0,0,0,267,360.55L256.9,341.62A284,284,0,0,0,228.46,352l12,22.63a15,15,0,0,1-6.2,20.27l-25.9,13.77-20.51-37.15-53,28.16,45.8,83a30,30,0,0,0,53-28.2Z" />
    <path d="M411,26.85a15,15,0,0,0-18.73,10L383.51,65.5a15,15,0,0,0,28.69,8.77L421,45.58A15,15,0,0,0,411,26.85Z" />
    <path d="M490.68,176.69,462,167.92a15,15,0,0,0-8.77,28.69l28.69,8.77a15,15,0,1,0,8.77-28.69Z" />
    <path d="M497.16,89.15A15,15,0,0,0,476.88,83l-37.46,19.92a15,15,0,0,0,14.08,26.49L491,109.44A1x5,15,0,0,0,497.16,89.15Z" />
  </svg>
);

export default SpeakerIcon;

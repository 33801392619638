import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import EpcTooltipIcon from '../Offers/EpcTooltipIcon';
import { LoadingBar } from './index';

const Table = ({ epcIconIsVisible, animated, classNames, id, isLoading, loadingData, noData, table }) => {
  const createRows = () =>
    table.body.map((row, rowIndex) => (
      <tr key={`row-${rowIndex}`}>
        {row.map((column, columnIndex) => (
          <td key={`column-${columnIndex}`}>{column}</td>
        ))}
      </tr>
    ));

  return (
    <div className={classNames.join(' ')} id={id}>
      <table className="table bordered highlight">
        <thead>
          <tr>
            {table.head.map((head, headIndex) => (
              <th key={`head-${headIndex}`}>
                <span className="label">
                  {head.component}
                  {!!head.helpLink && epcIconIsVisible ? <EpcTooltipIcon id="tooltip-epc" /> : null}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        {animated ? (
          <ReactCSSTransitionGroup
            className="animationTable"
            component="tbody"
            transitionEnterTimeout={table.body.length * 50 + 500}
            transitionLeave={false}
            transitionLeaveTimeout={0}
            transitionName="tablerow"
          >
            {createRows()}
          </ReactCSSTransitionGroup>
        ) : (
          <tbody>{createRows()}</tbody>
        )}
      </table>
      {table.body.length < 1 &&
        (isLoading ? (
          <span>
            <LoadingBar />
            <div className="loadingResults">{loadingData}</div>
          </span>
        ) : (
          <div className="noResults">{noData}</div>
        ))}
    </div>
  );
};

Table.defaultProps = {
  classNames: [],
  loadingData: 'Loading...',
  noData: 'No data',
};

Table.propTypes = {
  animated: PropTypes.bool,
  classNames: PropTypes.array,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingData: PropTypes.string,
  noData: PropTypes.any,
  table: PropTypes.object.isRequired,
};

export default Table;

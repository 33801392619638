import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';
import config from '../config';
import consts from '../utils/consts';
import { openModal } from '../components/Modal/modal-actions/modal-actions.js';
const { API_URL } = config;
const {
  ACTIONS: { UPDATE_BILLING_INFOS },
} = consts;

const requestUpdateBillingInfos = () => ({
  type: UPDATE_BILLING_INFOS.REQUEST_UPDATE_BILLING_INFOS,
});
const receiveUpdateBillingInfos = () => ({
  type: UPDATE_BILLING_INFOS.RECEIVE_UPDATE_BILLING_INFOS,
});
const failedToUpdateBillingInfos = () => ({
  type: UPDATE_BILLING_INFOS.FAILED_TO_UPDATE_BILLING_INFOS,
});

export const putBillingInfos = (data) => (dispatch) => {
  dispatch(requestUpdateBillingInfos());
  return new Promise((fulfill, reject) =>
    request
      .put(`${API_URL}/user/billing-infos/?_csrf=${encodeURIComponent(getCookie('XSRF-TOKEN'))}`)
      .withCredentials()
      .send({
        id: data.id,
        affiliate_id: data.affiliate_id,
        payment_method_id: data.payment_method_id,
        wire_bank_name: data.wire_bank_name,
        wire_bank_address: data.wire_bank_address,
        wire_bank_city: data.wire_bank_city,
        wire_bank_country_code: data.wire_bank_country_code,
        wire_bank_zipcode: data.wire_bank_zipcode,
        wire_bank_account_number: data.wire_bank_account_number,
        wire_aba_routing_branch_number: data.wire_aba_routing_branch_number,
        wire_bank_swift_bic: data.wire_bank_swift_bic,
        wire_bank_institution_number: data.wire_bank_institution_number,
        wire_intermediary_bank_name: data.wire_intermediary_bank_name,
        wire_intermediary_bank_state: data.wire_intermediary_bank_state,
        wire_intermediary_bank_address: data.wire_intermediary_bank_address,
        wire_intermediary_bank_city: data.wire_intermediary_bank_city,
        wire_intermediary_bank_country_code: data.wire_intermediary_bank_country_code,
        wire_intermediary_bank_zipcode: data.wire_intermediary_bank_zipcode,
        wire_intermediary_bank_account_number: data.wire_intermediary_bank_account_number,
        wire_intermediary_aba_routing_branch_number: data.wire_intermediary_aba_routing_branch_number,
        wire_intermediary_bank_swift_bic: data.wire_intermediary_bank_swift_bic,
        wire_intermediary_bank_institution_number: data.wire_intermediary_bank_institution_number,
        wire_beneficiary_name: data.wire_beneficiary_name,
        wire_beneficiary_address: data.wire_beneficiary_address,
        wire_beneficiary_city: data.wire_beneficiary_city,
        wire_beneficiary_country_code: data.wire_beneficiary_country_code,
        wire_beneficiary_zipcode: data.wire_beneficiary_zipcode,
        paxum_email: data.paxum_email,
        paypal_email: data.paypal_email,
        check_payable_to: data.check_payable_to,
        check_address: data.check_address,
        check_city: data.check_city,
        check_zipcode: data.check_zipcode,
        check_country_code: data.check_country_code,
        check_state: data.check_state,
        ach_config_id: data.ach_config_id,
        ach_country_name: data.ach_country_name,
        ach_beneficiary_zipcode: data.ach_beneficiary_zipcode,
        ach_beneficiary_country_code: data.ach_beneficiary_country_code,
        ach_beneficiary_city: data.ach_beneficiary_city,
        ach_beneficiary_address: data.ach_beneficiary_address,
        ach_beneficiary_state: data.ach_beneficiary_state,
        ach_beneficiary_name: data.ach_beneficiary_name,
        ach_beneficiary_bank_address: data.ach_beneficiary_bank_address,
        ach_beneficiary_bank_name: data.ach_beneficiary_bank_name,
        ach_transit_number: data.ach_transit_number,
        ach_account_number: data.ach_account_number,
        tax_id: data.tax_id,
        //payable_to_whom_id: intentionnellement commenté pas dans le model, car on n'utilise plus cette valeur
        affiliate_type_id: data.affiliate_type_id,
        other_information: data.other_information,
        payment_term: data.payment_term,
        wire_beneficiary_state: data.wire_beneficiary_state,
        minimum_payout_id: data.minimum_payout_id,
        wire_bank_state: data.wire_bank_state,
        epayservice_beneficiary_first_name: data.epayservice_beneficiary_first_name,
        epayservice_beneficiary_last_name: data.epayservice_beneficiary_last_name,
        epayservice_ewallet_number: data.epayservice_ewallet_number,
        bitcoin_wallet_address: data.bitcoin_wallet_address,
        minimum_payout: data.minimum_payout,
        affiliate_type: data.affiliate_type,
        payment_method: data.payment_method,
        masspay_email: data.masspay_email,
        masspay_firstname: data.masspay_firstname,
        masspay_lastname: data.masspay_lastname,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUpdateBillingInfos());
          fulfill();
        } else {
          dispatch(failedToUpdateBillingInfos());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const postChangeBillingInfos = (data) => (dispatch) => {
  dispatch(requestUpdateBillingInfos());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/user/change-billing-infos/?_csrf=${encodeURIComponent(getCookie('XSRF-TOKEN'))}`)
      .withCredentials()
      .send(data)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUpdateBillingInfos());
          fulfill();
        } else {
          dispatch(failedToUpdateBillingInfos());
          dispatch(
            openModal({
              name: 'BillingChangeErrorModal',
              modalProps: {
                dispatch,
                className: 'billing-change-error-modal-styled',
                error: res?.body?.errorMessage,
                intercomError: 'Tried to save billing',
              },
            })
          );
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          reject();
        }
      })
  );
};

export const putChangeBillingInfos = (data) => (dispatch) => {
  dispatch(requestUpdateBillingInfos());
  return new Promise((fulfill, reject) =>
    request
      .put(`${API_URL}/user/change-billing-infos/?_csrf=${encodeURIComponent(getCookie('XSRF-TOKEN'))}`)
      .withCredentials()
      .send(data)
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUpdateBillingInfos());
          fulfill();
        } else {
          dispatch(failedToUpdateBillingInfos());
          dispatch(
            openModal({
              name: 'BillingChangeErrorModal',
              modalProps: {
                dispatch,
                className: 'billing-change-error-modal-styled',
                error: res?.body?.errorMessage,
                intercomError: 'Tried to save billing from email link',
              },
            })
          );
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
        }
      })
  );
};

const Logout = () => (
  <svg height="30" id="logout" viewBox="0 0 40 40" width="30" xmlns="http://www.w3.org/2000/svg">
    <g data-name="Calque 1" id="Calque_1">
      <rect data-name="Rectangle 555" fill="none" height="40" id="Rectangle_555" width="40" />
      <circle cx="20" cy="20" data-name="Ellipse 87" fill="none" id="Ellipse_87" r="20" transform="translate(0 0)" />
      <line data-name="Ligne 211" fill="none" id="Ligne_211" x2="40" y2="40" />
      <line data-name="Ligne 212" fill="none" id="Ligne_212" x1="40" y2="40" />
      <line data-name="Ligne 213" fill="none" id="Ligne_213" transform="translate(0 20)" x2="40" />
      <line data-name="Ligne 214" fill="none" id="Ligne_214" transform="translate(0 9.997)" x2="40" />
      <line data-name="Ligne 215" fill="none" id="Ligne_215" transform="translate(0 30.002)" x2="40" />
      <line data-name="Ligne 216" fill="none" id="Ligne_216" transform="translate(20)" y2="40" />
      <line data-name="Ligne 217" fill="none" id="Ligne_217" transform="translate(9.997)" y2="40" />
      <line data-name="Ligne 218" fill="none" id="Ligne_218" transform="translate(30.002)" y2="40" />
      <rect
        data-name="Rectangle 556"
        fill="none"
        height="28.291"
        id="Rectangle_556"
        transform="translate(5.855 5.855)"
        width="28.291"
      />
      <circle
        cx="14.145"
        cy="14.145"
        data-name="Ellipse 88"
        fill="none"
        id="Ellipse_88"
        r="14.145"
        transform="translate(5.855 5.855)"
      />
      <rect
        data-name="Rectangle 557"
        fill="none"
        height="20.005"
        id="Rectangle_557"
        transform="translate(9.997 9.997)"
        width="20.005"
      />
      <circle
        cx="10.002"
        cy="10.002"
        data-name="Ellipse 89"
        fill="none"
        id="Ellipse_89"
        r="10.002"
        transform="translate(9.998 9.998)"
      />
      <path
        d="M13.261,17.542V10.015a3.256,3.256,0,0,1,3.505-2.93H30.119a3.256,3.256,0,0,1,3.505,2.93V29.992a3.256,3.256,0,0,1-3.505,2.93H16.766a3.256,3.256,0,0,1-3.505-2.93V22.246"
        data-name="Tracé 156"
        fill="none"
        id="Tracé_156"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.019"
      />
      <path
        d="M27.406,20l-7.994-4.616v9.231Z"
        data-name="Tracé 157"
        fill="none"
        id="Tracé_157"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.019"
      />
      <line
        data-name="Ligne 219"
        fill="none"
        id="Ligne_219"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.019"
        transform="translate(6.374 20)"
        x1="12.592"
      />
    </g>
  </svg>
);

export default Logout;

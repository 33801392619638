import { useOffersFilterContext } from './OfferFilterProvider';

const FiltersTags = () => {
  const { activeFilters, activeTags, distpachSearchFormChange, offersIsFetching } = useOffersFilterContext();

  const setFilter = ({ field, value }) => {
    const dropdownFilters = activeFilters?.[field] || [];
    if (offersIsFetching) {
      return;
    }

    let filters = [...dropdownFilters];

    if (!filters.includes(value)) {
      filters.push(value);
    } else {
      filters = dropdownFilters.filter((id) => id !== value);
    }

    distpachSearchFormChange({ field, filters });
  };
  return (
    <div className="tags-list">
      {activeTags.map((tag, index) => (
        <div className="tag" key={index}>
          <span>{tag.name}</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setFilter({ field: tag.key, value: tag.value });
            }}
            type="button"
          >
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M10.0605 9.50015L14.0302 5.53044C14.3235 5.23719 14.3235 4.76319 14.0302 4.46994C13.737 4.17669 13.263 4.17669 12.9697 4.46994L9 8.43965L5.03025 4.46994C4.737 4.17669 4.263 4.17669 3.96975 4.46994C3.6765 4.76319 3.6765 5.23719 3.96975 5.53044L7.9395 9.50015L3.96975 13.47C3.6765 13.7632 3.6765 14.2372 3.96975 14.5304C4.116 14.6767 4.308 14.7502 4.5 14.7502C4.692 14.7502 4.884 14.6767 5.03025 14.5304L9 10.5607L12.9697 14.5304C13.116 14.6767 13.308 14.7502 13.5 14.7502C13.692 14.7502 13.884 14.6767 14.0302 14.5304C14.3235 14.2372 14.3235 13.7632 14.0302 13.47L10.0605 9.50015Z"
                fill="black"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ))}
    </div>
  );
};

export default FiltersTags;

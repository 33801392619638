import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { setActionMessage } from '../../components/Application/actions/application-actions';
import { guid } from '../../utils/helpers';

import Clipboard from 'clipboard';
import CopyDiagonalIcon from './svg/CopyDiagonalIcon.react';

class Copy extends Component {
  constructor(props) {
    super(props);

    this.uuid = `copy-${guid()}`;
  }

  componentDidMount() {
    const { dispatch, intl, onCopy } = this.props;

    if (!!window && typeof window.Clipboard !== 'undefined') {
      this.clipboard = new Clipboard(`#${this.uuid}`);
      this.clipboard
        .on('success', (e) => {
          if (typeof onCopy === 'function') {
            onCopy();
          }

          const text = e.text.length > 170 ? `${e.text.substr(0, 170)}...` : e.text;
          dispatch(
            setActionMessage(
              'success',
              {
                text: intl.formatMessage(messages.genericTextTextCopied, { text }),
              },
              3000
            )
          );
          e.clearSelection();
        })
        .on('error', (e) => {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
        });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { clipboardTarget, disabled } = this.props;

    return disabled !== nextProps.disabled || !_.isEqual(clipboardTarget, nextProps.clipboardTarget);
  }

  componentWillUnmount() {
    if (window.Clipboard !== null && this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    const {
      buttonStyled,
      classNames,
      clipboardTarget,
      disabled,
      btnFlat = true,
      icon = 'link',
      text = <FormattedMessage {...messages.genericTextCopy} />,
      isReferrals,
    } = this.props;

    return (
      <span
        className={[
          'copyBtn',
          btnFlat ? 'btn-flat' : '',
          disabled ? 'disabled' : undefined,
          buttonStyled ? 'copy-btn bg-primary-color' : 'primary-color',
          ...classNames,
        ].join(' ')}
        data-clipboard-target={disabled ? '' : clipboardTarget}
        id={this.uuid}
      >
        {isReferrals ? <CopyDiagonalIcon /> : <i className="material-icons">{icon}</i>}
        {text ? <span className="text">{text}</span> : null}
      </span>
    );
  }
}

Copy.defaultProps = {
  btnFlat: true,
  buttonStyled: false,
  classNames: [],
};

Copy.propTypes = {
  btnFlat: PropTypes.bool,
  buttonStyled: PropTypes.bool,
  classNames: PropTypes.array,
  clipboardTarget: PropTypes.string,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  icon: PropTypes.any,
  intl: intlShape.isRequired,
  onCopy: PropTypes.func,
  text: PropTypes.any,
};

export default injectIntl(Copy);

const Onlyfans = () => (
  <svg height="25" viewBox="0 0 400 400" width="25" xmlns="http://www.w3.org/2000/svg">
    <rect className="a" fill="none" height="400" width="400" />
    <path
      d="M387.5,75H324.73c-53.92,0-82.86,3.09-105.48,30.6a125,125,0,1,0,32.87,144c49.75-3.62,84.25-33.37,95.13-80.87,0,0-37.5,9.14-69.25,0C340.81,156.58,377,121.25,387.5,75ZM100,200a37.38,37.38,0,0,1,57.4-31.69,46.37,46.37,0,0,1,46.85-39.52h.13c-14.37,27.79-22,52.39-31.23,82.27A37.4,37.4,0,0,1,100,200Z"
      fill="#fff"
    />
  </svg>
);

export default Onlyfans;

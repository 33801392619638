import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import messages from '../../i18n/base-en.js';
import { Gtm } from '../../utils/gtm';

import { Table } from '../../components/reusables';

const PaymentHistoryTable = ({ handleInvoiceRequest, handlePaymentRequest, isLoadingPayments, paymentsList }) => {
  const url = new URL(window.location.href);
  const isPrintPaymentEnabled = url.searchParams.get('print_payment') === 'true';

  const table = {
    head: [
      {
        component: (
          <span>
            N<sup>o</sup>
          </span>
        ),
      },
      { component: <FormattedMessage {...messages.genericTextType} /> },
      {
        component: <FormattedMessage {...messages.paymentHistoryColumnDate} />,
      },
      { component: <FormattedMessage {...messages.genericTextStatus} /> },
      {
        component: (
          <span>
            <FormattedMessage {...messages.genericTextAmount} /> ($)
          </span>
        ),
      },
      {
        component: (
          <span>
            <FormattedMessage {...messages.genericInvoice} />
          </span>
        ),
      },
    ],
    body: paymentsList.map((payment) => [
      <div key={payment.id}>{payment.id}</div>,
      <div
        className={`${payment.type.toLowerCase()} ${payment.type.toLowerCase() === 'payment' ? 'primary-color' : ''}`}
        key={payment.id}
      >
        {payment.type}
      </div>,
      <div key={payment.id}>{payment.date}</div>,
      <div className={`status ${payment.status.toLowerCase()}`} key={payment.id}>
        {payment.status}
      </div>,
      <div key={payment.id}>
        <FormattedNumber currency={payment.currency} style="currency" value={parseFloat(payment.amount).toFixed(2)} />
      </div>,
      <span key={payment.id}>
        {payment.type !== 'Payment' && (
          <div className="pdf-invoice" onClick={() => handleInvoiceRequest(payment.id)}>
            <i
              className="material-icons primary-color"
              onClick={() => Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - invoice')}
            >
              print
            </i>
          </div>
        )}
        {isPrintPaymentEnabled && payment.date >= '2024-06-01' && payment.type === 'Payment' ? (
          <i
            className="pdf-invoice material-icons primary-color"
            onClick={() => {
              handlePaymentRequest(payment.id);
              Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - payment');
            }}
          >
            print
          </i>
        ) : null}
      </span>,
    ]),
  };

  return (
    <div className="payment-history-table table-wrapper">
      <Table
        classNames={['payment-table', 'black-header', 'table-container', 'table-responsive']}
        isLoading={isLoadingPayments}
        noData={<FormattedMessage {...messages.paymentHistoryNoHistory} />}
        table={table}
      />
    </div>
  );
};

PaymentHistoryTable.defaultProps = {
  isLoadingPayments: false,
  paymentsList: [],
};

PaymentHistoryTable.propTypes = {
  handleInvoiceRequest: PropTypes.func.isRequired,
  handlePaymentRequest: PropTypes.func.isRequired,
  isLoadingPayments: PropTypes.bool,
  paymentsList: PropTypes.array,
};

export default PaymentHistoryTable;

import { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import store from '../../../create-store';

import messages from '../../../i18n/base-en.js';

import HandleAPIError from '../../../utils/handleAPIError';
import { loggedInFetching } from '../../../utils/initialFetching';
import { setActionMessage } from '../../../components/Application/actions/application-actions';
import { fetchProfile } from '../../../actions/profile-actions';
import { validateUser, setSignupToken } from '../../../components/Login/actions/signup-actions';
class LoginSplashContainer extends Component {
  componentDidMount() {
    const {
      dispatch,
      intl,
      isFetching,
      location: { query, search },
      validateError,
    } = this.props;

    let errorMessageToUse = intl.formatMessage(messages.loginSplashErrorOccured);

    const redirectToSignupForm = (shouldRedirectToNewForm) => {
      const searchParams = new URLSearchParams(search);
      const paramsToKeep = ['regionOverride', 'form'];
      const newSearchParams = new URLSearchParams();

      for (const [key, value] of searchParams.entries()) {
        if (paramsToKeep.includes(key)) {
          newSearchParams.append(key, value);
        }
      }

      let newUrl = shouldRedirectToNewForm ? '/access/about' : '/access/tell-us-about-you';

      if (newSearchParams.toString()) {
        newUrl += `?${newSearchParams.toString()}`;
      }
      browserHistory.push(newUrl);
    };

    if ((typeof query.code !== 'undefined' || typeof query.signup !== 'undefined') && !isFetching) {
      // arrive de quelquepart (email ou google)
      dispatch(validateUser(query)).then(({ account_status, account_status_message, errorMessage }) => {
        switch (account_status) {
          case 'has_to_complete_info': // continue to the questions
            dispatch(setSignupToken(query.signup));
            redirectToSignupForm(query.form === '2');
            break;
          case 'approved': // log in go dashboard
          case 'active': // log in go dashboard
            this.logInDashboard();
            break;
          case 'not_approved': // redirect login avec message not approved
          case 'pending': // redirect login avec message not approved
          case 'blocked': // redirect login avec message not approved
          case 'rejected': // redirect login avec message not approved
          case 'deleted': // redirect login avec message not approved
            browserHistory.push({ pathname: '/login' });
            dispatch(
              setActionMessage('error', {
                text: account_status_message,
              })
            );
            break;
          case 'has_to_redirect': // nope
            browserHistory.push({
              pathname: '/access/create-account',
            });
            dispatch(
              setActionMessage('error', {
                text: errorMessageToUse,
              })
            );
            break;
          case undefined: // error / not ready / link already used
            errorMessageToUse = validateError || errorMessage;
            browserHistory.push({ pathname: '/login' });
            dispatch(
              setActionMessage('error', {
                text: errorMessageToUse,
              })
            );
            break;
          default:
            browserHistory.push({ pathname: '/login' });
            dispatch(
              setActionMessage('error', {
                text: errorMessageToUse,
              })
            );
            break;
        }
      });
    } else if (typeof query.error !== 'undefined') {
      browserHistory.push({ pathname: '/access/create-account' });
      dispatch(setActionMessage('error', { text: errorMessageToUse }));
    }
  }

  logInDashboard() {
    const { dispatch } = this.props;

    dispatch(fetchProfile())
      .then(() => {
        const newState = store.getState();
        const fetchingPromises = [
          loggedInFetching(newState.profile.data, dispatch, {
            path: '/',
            query: {},
          }),
        ];
        Promise.all(fetchingPromises);
      })
      .catch((res) => {
        new HandleAPIError(res, dispatch).handleAll();
      });
  }

  render() {
    const {
      intl,
      location: { query },
    } = this.props;

    let message;
    if (typeof query.signup !== 'undefined') {
      message = intl.formatMessage(messages.loginSplashCreatingAccount);
    } else if (typeof query.code !== 'undefined') {
      message = intl.formatMessage(messages.loginSplashPreparingAccount);
    } else {
      message = intl.formatMessage(messages.loginSplashLoading);
    }

    return (
      <div id="splash-whale">
        <img
          alt="loading gif"
          id="splash-whale-gif"
          src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
        />
        <p className="saving">
          {message}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      </div>
    );
  }
}
LoginSplashContainer.propTypes = {
  dispatch: PropTypes.func,
  intl: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object,
  validateError: PropTypes.string,
};

export default connect((state) => ({
  validateError: state.signup.err,
  isFetching: state.signup.isFetching,
}))(LoginSplashContainer);

const AffiliateResources = () => (
  <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="clipPath">
        <rect
          data-name="Rectangle 201"
          fill="rgba(255,255,255,0.3)"
          height="30"
          id="Rectangle_201"
          transform="translate(23441 12769)"
          width="30"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clipPath)" id="affiliate-resources" transform="translate(-23441 -12769)">
      <g data-name="Calque 2" id="Calque_2" transform="translate(23441 12769)">
        <g data-name="Calque 1" id="Calque_1">
          <rect data-name="Rectangle 198" fill="none" height="30" id="Rectangle_198" width="30" />
          <circle cx="15" cy="15" data-name="Ellipse 130" fill="none" id="Ellipse_130" r="15" transform="translate(0 0)" />
          <line data-name="Ligne 268" fill="none" id="Ligne_268" x2="30" y2="30" />
          <line data-name="Ligne 269" fill="none" id="Ligne_269" x1="30" y2="30" />
          <line data-name="Ligne 270" fill="none" id="Ligne_270" transform="translate(0 15)" x2="30" />
          <line data-name="Ligne 271" fill="none" id="Ligne_271" transform="translate(0 7.497)" x2="30" />
          <line data-name="Ligne 272" fill="none" id="Ligne_272" transform="translate(0 22.501)" x2="30" />
          <line data-name="Ligne 273" fill="none" id="Ligne_273" transform="translate(15)" y2="30" />
          <line data-name="Ligne 274" fill="none" id="Ligne_274" transform="translate(7.497)" y2="30" />
          <line data-name="Ligne 275" fill="none" id="Ligne_275" transform="translate(22.501)" y2="30" />
          <rect
            data-name="Rectangle 199"
            fill="none"
            height="21.218"
            id="Rectangle_199"
            transform="translate(4.391 4.391)"
            width="21.218"
          />
          <circle
            cx="10.609"
            cy="10.609"
            data-name="Ellipse 131"
            fill="none"
            id="Ellipse_131"
            r="10.609"
            transform="translate(4.391 4.391)"
          />
          <rect
            data-name="Rectangle 200"
            fill="none"
            height="15.004"
            id="Rectangle_200"
            transform="translate(7.497 7.497)"
            width="15.004"
          />
          <circle
            cx="7.501"
            cy="7.501"
            data-name="Ellipse 132"
            fill="none"
            id="Ellipse_132"
            r="7.501"
            transform="translate(7.499 7.499)"
          />
          <path
            d="M11.177,10.958,4.236,17.277a2.292,2.292,0,0,0-.113,3.274h0a2.292,2.292,0,0,0,3.3.008l6.27-6.82"
            data-name="Tracé 1532"
            fill="none"
            id="Tracé_1532"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(1.269 3.985)"
          />
          <path
            d="M11.851,11.516l5.9-5.891a.961.961,0,0,0,.255-.461l.127-.539a.956.956,0,0,1,.551-.659l.906-.395a.96.96,0,0,1,1.067.209h0a.96.96,0,0,1,.174,1.091l-.471.964a.96.96,0,0,1-.67.518l-.4.081a.965.965,0,0,0-.485.255L12.96,12.536"
            data-name="Tracé 1533"
            fill="none"
            id="Tracé_1533"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(4.31 1.27)"
          />
          <path
            d="M10.251,12.822l8.865,9.82a2.588,2.588,0,0,0,2.292.826h0a2.587,2.587,0,0,0,2.2-2.284l.02-.2a2.587,2.587,0,0,0-.849-2.208L12.892,9.96a1.23,1.23,0,0,1-.41-.968l.031-.784a4.43,4.43,0,0,0-1.174-3.19h0A4.433,4.433,0,0,0,8.089,3.6H7.741a.365.365,0,0,0-.255.624L9.3,6.042a2.127,2.127,0,0,1,.61,1.7L9.9,7.823a2.125,2.125,0,0,1-1.947,1.91h0a2.122,2.122,0,0,1-1.6-.553L4.373,7.372a.365.365,0,0,0-.612.288l.032.661A4.345,4.345,0,0,0,5.415,11.5h0a4.343,4.343,0,0,0,2.861.952l1.019-.033A1.226,1.226,0,0,1,10.251,12.822Z"
            data-name="Tracé 1534"
            fill="none"
            id="Tracé_1534"
            stroke="#fff"
            strokeWidth="1"
            transform="translate(1.367 1.309)"
          />
          <circle
            cx="0.849"
            cy="0.849"
            data-name="Ellipse 133"
            fill="none"
            id="Ellipse_133"
            r="0.849"
            stroke="#fff"
            strokeWidth="0.7"
            transform="translate(6.192 22.079)"
          />
          <circle
            cx="1.013"
            cy="1.013"
            data-name="Ellipse 134"
            fill="none"
            id="Ellipse_134"
            r="1.013"
            stroke="#fff"
            strokeWidth="0.7"
            transform="translate(21.382 21.181)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AffiliateResources;

import { getBackOffersList } from '../../../utils/helpers';
import consts from '../../../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  offerIsFetching: false,
  offerUrlIsFetching: false,
  offerAcceptTermsIsFetching: false,
  offerTrackingLinkIsFetching: false,
  offerCampaignLinkIsFetching: true,
  offerCreativeLinkIsFetching: false,
  unsubscribeLinksIsFetching: false,
  offer: {
    id: null,
    name: '',
    countries: [],
    terms_and_conditions: '',
    preview_url: '',
    featured: false,
    description: '',
    landing_pages: [],
    agreed_terms_and_conditions: true,
    require_terms_and_conditions: 0,
    config: {},
    conversionTags: [],
    conversionTitle: '',
  },
  trackingLink: '',
  campaignLink: '',
  creativeLink: '',
  impressionPixel: '',
  offerOverlay: {
    isFetching: false,
  },
  offerPopCode: {
    isFetching: false,
  },
  offerUrl: [],
  offerNotFound: false,
  performerPageModelSuggestion: [],
};

export const offerReducer = (state = initialState, action = {}) => {
  const offerState = state.offer;
  const offerAction = action.offer;

  switch (action.type) {
    case ACTIONS.RESET_OFFER:
      return {
        ...initialState,
      };

    case ACTIONS.REQUEST_OFFER:
      return {
        ...state,
        offerIsFetching: true,
      };

    case ACTIONS.RECEIVE_OFFER:
      return {
        ...state,
        offer: {
          backOffersList: getBackOffersList(offerAction).join(','),
          ...offerState,
          ...offerAction,
        },
        offerIsFetching: false,
        offerNotFound: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFER:
      return {
        ...state,
        offerIsFetching: false,
        offerNotFound: true,
      };

    case ACTIONS.REQUEST_TRACKING_LINK:
      return {
        ...state,
        offerTrackingLinkIsFetching: true,
      };

    case ACTIONS.RECEIVE_TRACKING_LINK:
      return {
        ...state,
        trackingLink: action.data.click_url,
        impressionPixel: action.data.impression_pixel,
        offerTrackingLinkIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_TRACKING_LINK:
      return {
        ...state,
        offerTrackingLinkIsFetching: false,
      };

    case ACTIONS.RECEIVE_PERFORMER_PAGE_MODEL_SUGGESTIONS:
      return {
        ...state,
        performerPageModelSuggestion: action.data,
      };
    case ACTIONS.REQUEST_CAMPAIGN_LINK:
      return {
        ...state,
      };

    case ACTIONS.RECEIVE_CAMPAIGN_LINK:
      return {
        ...state,
        campaignLink: action.data.campaign_link,
      };

    case ACTIONS.FAILED_TO_RECEIVE_CAMPAIGN_LINK:
    case ACTIONS.RECEIVE_CAMPAIGN_LINK_DONE:
      return {
        ...state,
        offerCampaignLinkIsFetching: false,
      };
    case ACTIONS.REQUEST_CREATIVE_LINK:
      return {
        ...state,
        offerCreativeLinkIsFetching: true,
      };

    case ACTIONS.RECEIVE_CREATIVE_LINK:
      return {
        ...state,
        creativeLink: action.data,
        offerCreativeLinkIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_CREATIVE_LINK:
      return {
        ...state,
        offerCreativeLinkIsFetching: false,
      };

    case ACTIONS.REQUEST_OFFER_OVERLAY:
      return {
        ...state,
        offerOverlay: {
          isFetching: true,
        },
      };

    case ACTIONS.RECEIVE_OFFER_OVERLAY:
      return {
        ...state,
        offerOverlay: {
          isFetching: false,
          ...action.data,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFER_OVERLAY:
      return {
        ...state,
        offerOverlay: {
          isFetching: false,
        },
      };

    case ACTIONS.REQUEST_OFFER_POPCODE:
      return {
        ...state,
        offerPopCode: {
          isFetching: true,
        },
      };

    case ACTIONS.RECEIVE_OFFER_POPCODE:
      return {
        ...state,
        offerPopCode: {
          isFetching: false,
          ...action.data,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFER_POPCODE:
      return {
        ...state,
        offerPopCode: {
          isFetching: false,
        },
      };

    case ACTIONS.REQUEST_OFFER_URL:
      return {
        ...state,
        offerUrlIsFetching: true,
      };

    case ACTIONS.RECEIVE_OFFER_URL:
      return {
        ...state,
        offerUrl: action.offerUrl,
        offerUrlIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFER_URL:
      return {
        ...state,
        offerUrlIsFetching: false,
      };

    case ACTIONS.REQUEST_UNSUBSCRIBE_LINKS:
      return {
        ...state,
        unsubscribeLinksIsFetching: true,
      };

    case ACTIONS.RECEIVE_UNSUBSCRIBE_LINKS:
      return {
        ...state,
        offer: {
          ...offerState,
          dne_unsubscribe_url: action.data.dne_unsubscribe_url,
          dne_download_url: action.data.dne_download_url,
        },
        unsubscribeLinksIsFetching: false,
      };

    case ACTIONS.FAILED_TO_RECEIVE_UNSUBSCRIBE_LINKS:
      return {
        ...state,
        unsubscribeLinksIsFetching: false,
      };

    default:
      return state;
  }
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Checkbox, SimplifiedInput, SimplifiedTextarea } from '../../../components/reusables';
import { Gtm } from '../../../utils/gtm';

import { default as validate } from './ValidateTellUsAboutYou/validateTellUsAboutYou';
import TellUsAboutYouRoleInput from '../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleInput/TellUsAboutYouRoleInput.react';
import { getCookie } from '../../../utils/helpers';
import provincesList from '../../../utils/list-options/provinces';
import statesList from '../../../utils/list-options/states';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

import consts from '../../../utils/consts';
import { initialFetching } from '../../../utils/initialFetching';

import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper.js';

import 'materialize-css';
import TellUsAboutYouRoleDropdownInputNew from '../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleDropdownInput/TellUsAboutYouRoleDropdownInputNew.react.js';
import SelectCustom from '../../../components/reusables/form/SelectCustom';
const { PROMOTION_METHODS } = consts;
const PromoCode = getCookie('PromoCode');

const TellUsAboutYou = ({
  countriesList,
  fields,
  handleSubmit,
  dispatch,
  intl,
  userInfo,
  values,
  verticals,
  skinConfigurations,
}) => {
  const [previousUrl, setPreviousUrl] = useState(null);
  const [subDivisionShown, setSubdivisionShown] = useState(1);
  const skin = new SkinConfigurationsUtils(skinConfigurations);
  useEffect(() => {
    initialFetching(dispatch);

    const storedUrl = localStorage.getItem('previousUrl');
    if (storedUrl) {
      setPreviousUrl(storedUrl);
    }
  }, []);

  const concurrentCalls = () => {
    if (previousUrl) {
      Gtm.event('referrer', 'trackSignUp', previousUrl);
      window.localStorage.removeItem('previousUrl');
    }
    handleSubmit();
  };
  useEffect(() => {
    if (userInfo.acceptTOS) {
      dispatch(change('TellUsAboutYou', 'acceptTOS', userInfo.acceptTOS));
    }
    if (userInfo.company) {
      dispatch(change('TellUsAboutYou', 'company', userInfo.company));
    }
    if (userInfo.promo_code && !PromoCode) {
      dispatch(change('TellUsAboutYou', 'promo', userInfo.promo_code));
    }
  }, [userInfo.acceptTOS, userInfo.company, userInfo.promo_code]);

  const site = skin.getPropertyValue('Global', 'Site', 'name') || 'CrakRevenue';
  const LinkTerms = (
    <a href="https://www.crakrevenue.com/tos/" rel="noreferrer" target="_blank">
      <FormattedMessage {...messages.createAccountToSLinkToU} />
    </a>
  );
  const LinkPrivacy = (
    <a href="http://www.crakrevenue.com/privacy/" rel="noreferrer" target="_blank">
      <FormattedMessage {...messages.createAccountToSLinkPP} />
    </a>
  );
  messages.createAccountToSLink.values = { LinkTerms, LinkPrivacy };

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countriesList,
    ExcludedCountriesHelper.getSignUpExcludeCountries()
  );

  const addField = () => {
    setSubdivisionShown(subDivisionShown + 1);
  };
  const getRoleSubdivision = (role) => {
    switch (role) {
      case 'Webmaster':
        return (
          <div>
            <p className="custom-label">Your top website URL(s)</p>
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website1}
              hidden={!(subDivisionShown >= 1)}
              placeholder="https://www.mywebsite.com"
              showAdd={fields.website1.value?.length > 0 && fields.website1.valid ? subDivisionShown === 1 : null}
              tabIndex={11}
            />
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website2}
              hidden={!(subDivisionShown >= 2)}
              placeholder="https://www.mywebsite.com"
              showAdd={fields.website2.value?.length > 0 && fields.website2.valid ? subDivisionShown === 2 : null}
              tabIndex={12}
            />
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website3}
              hidden={!(subDivisionShown >= 3)}
              placeholder="https://www.mywebsite.com"
              showAdd={false}
              tabIndex={13}
            />
          </div>
        );
      case 'Social Marketer':
      case 'Influencer / Content Creator':
        return (
          <div>
            <p className="custom-label">Your top social account URL(s)</p>
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled
              fieldInput={fields.handle1}
              fieldSelect={fields.social1}
              hidden={!(subDivisionShown >= 1)}
              inputOptions="socials"
              showAdd={
                fields.social1.value?.length > 0 && fields.handle1.value?.length > 0 && fields.handle1.valid
                  ? subDivisionShown === 1
                  : null
              }
              tabIndex={11}
            />
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled={false}
              fieldInput={fields.handle2}
              fieldSelect={fields.social2}
              hidden={!(subDivisionShown >= 2)}
              inputOptions="socials"
              showAdd={
                fields.social2.value?.length > 0 && fields.handle2.value?.length > 0 && fields.handle2.valid
                  ? subDivisionShown === 2
                  : null
              }
              tabIndex={12}
            />
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled={false}
              fieldInput={fields.handle3}
              fieldSelect={fields.social3}
              hidden={!(subDivisionShown >= 3)}
              inputOptions="socials"
              tabIndex={13}
            />
          </div>
        );
      case 'E-Mailer':
        return;
      case 'Media Buyer':
        return (
          <TellUsAboutYouRoleInput
            field={fields.budget}
            label={messages.tellUsYourRoleMediaBuyer}
            normalizeBudget
            placeholder="$5,000"
            tabIndex={11}
          />
        );
      case 'Affiliate Network':
      case 'Ad Network':
        return (
          <TellUsAboutYouRoleInput
            field={fields.network}
            label={messages.tellUsYourRoleNetwork}
            placeholder="https://www.myadnetwork.com"
            tabIndex={11}
          />
        );
    }
  };

  return (
    <div id="simplified-tellusaboutyou">
      <div className="form-div">
        <div className="form-container col-md-12">
          <div className="row">
            <h1>Affiliate Sign Up</h1>
            <p>Improve your Crakrevenue experience by completing the fields below.</p>
          </div>

          <div className="customSelects">
            <h2>About your business</h2>
            <div className="fields">
              <SelectCustom
                styleBox={true}
                error={fields.accountType.error}
                id="tellUsMore-PromotionMethods"
                label={messages.tellUsYourRole.description}
                onChange={fields.accountType.onChange}
                options={PROMOTION_METHODS.map((promotionMethod) => ({ value: promotionMethod, text: promotionMethod }))}
                touched={fields.accountType.touched}
                value={fields.accountType.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
              <div className="field">{getRoleSubdivision(values.accountType)}</div>
              <SelectCustom
                styleBox={true}
                error={fields.vertical.error}
                id="tellUsMore-VerticalPromotion"
                isMulti
                label="Please pick your top vertical(s)?"
                maxSelected={2}
                onChange={fields.vertical.onChange}
                options={verticals.map((vertical) => ({ value: vertical.name, text: vertical.name }))}
                touched={fields.vertical.touched}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
              <SelectCustom
                styleBox={true}
                error={fields.traffic.error}
                id="tellUsMore-VisitorsRegion"
                isMulti
                label="What are the top countries you promote offers in?"
                maxSelected={3}
                onChange={fields.traffic.onChange}
                options={filteredCountries.map((country) => ({ value: country.code, text: country.name }))}
                touched={fields.traffic.touched}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
              <SimplifiedTextarea
                className="col-md-12"
                field={fields.anythingToAdd}
                followCharNumber
                id="tellUsMore simplified_anythingToAdd"
                key={fields.anythingToAdd.name}
                label={messages.tellUsMore}
                maxLength={1000}
                placeholder="Ex: Experience, more details about your traffic, offers you already promote, additional website URLs, references, etc.)"
                tabIndex={40}
              />
            </div>
            <h2 className="about-you">About you</h2>
            <div className="fields">
              <SelectCustom
                styleBox={true}
                error={fields.country.error}
                id="tellUsMore-Country"
                label={messages.tellUsCountry.description}
                onChange={fields.country.onChange}
                options={filteredCountries.map((country) => ({ value: country.code, text: country.name }))}
                touched={fields.country.touched}
                value={fields.country.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
              {values.country === 'CA' && (
                <SelectCustom
                  styleBox={true}
                  error={fields.province.error}
                  id="tellUsMore-Province"
                  label={messages.genericTextProvince.description}
                  onChange={fields.province.onChange}
                  options={provincesList.map((province) => ({
                    value: province.value,
                    text: intl.formatMessage(messages[province.label.id]),
                  }))}
                  touched={fields.province.touched}
                  value={fields.province.value || ''}
                  valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                />
              )}
              {values.country === 'US' && (
                <SelectCustom
                  styleBox={true}
                  error={fields.state.error}
                  id="tellUsMore-Province"
                  label={messages.genericTextState.description}
                  onChange={fields.state.onChange}
                  options={statesList.map((state) => ({
                    value: state.value,
                    text: intl.formatMessage(messages[state.label.id]),
                  }))}
                  touched={fields.state.touched}
                  value={fields.state.value || ''}
                  valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                />
              )}
              <div className="field">
                <SimplifiedInput
                  autofocus
                  className="simple-signup__input"
                  field={fields.phone}
                  id="tellUsMore simplified_phone-number"
                  label={messages.tellUsPhoneNumber}
                  tabIndex={80}
                  type="text"
                />
              </div>
              <TellUsAboutYouRoleDropdownInputNew
                disabled
                fieldInput={fields.messaging_platform_username}
                fieldSelect={fields.messaging_platform_name}
                inputDisabled={!fields.messaging_platform_name.value}
                inputOptions="messaging_platform_name"
                label="Instant Messaging Platform of Choice"
              />
              <TellUsAboutYouRoleDropdownInputNew
                disabled
                fieldInput={fields.specify}
                fieldSelect={fields.howYouHear}
                inputDisabled={!fields.howYouHear.value}
                inputOptions="references"
                label="How did you hear about us?"
              />
              <div className="field">
                <SimplifiedInput
                  autofocus
                  className="simple-signup__input"
                  field={fields.promo}
                  id="tellUsMore simplified_promo-code"
                  key="promo code"
                  label={messages.tellUsPromoCode}
                  optional
                  tabIndex={110}
                  type="text"
                />
              </div>
              {!userInfo.company && (
                <div className="field">
                  <SimplifiedInput
                    autofocus
                    className="simple-signup__input"
                    field={fields.company}
                    id="tellUsMore simplified_company"
                    label={messages.createAccountCompany}
                    optional
                    tabIndex={120}
                    type="text"
                  />
                </div>
              )}
              {!userInfo.acceptTOS && (
                <div className="row" id="simplified_accept_tos">
                  <Checkbox
                    className="simple-signup__checkbox"
                    escapeHtml
                    field={fields.acceptTOS}
                    id="create-account-accept-terms"
                    label={messages.createAccountToSLink}
                    tabIndex={130}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <button className="simple-signup__im-in waves-effect waves-light btn" onClick={concurrentCalls}>
              <FormattedMessage {...messages.tellUsJoinCrakRevenueNow} values={{ site }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TellUsAboutYou.propTypes = {
  countriesList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  values: PropTypes.object.isRequired,
  verticals: PropTypes.array.isRequired,
};

export default reduxForm({
  form: 'TellUsAboutYou',
  touchOnBlur: true,
  touchOnChange: false,
  fields: [
    'acceptTOS',
    'accountType',
    'budget',
    'network',
    'website1',
    'website2',
    'website3',
    'social1',
    'social2',
    'social3',
    'handle1',
    'handle2',
    'handle3',
    'company',
    'country',
    'offer',
    'phone',
    'promo',
    'province',
    'state',
    'traffic',
    'vertical',
    'anythingToAdd',
    'howYouHear',
    'specify',
    'messaging_platform_name',
    'messaging_platform_username',
  ],
  initialValues: {
    acceptTOS: false,
    promo: PromoCode || '',
    website1: '',
    website2: '',
    website3: '',
    social1: '',
    social2: '',
    social3: '',
    handle1: '',
    handle2: '',
    handle3: '',
    vertical: [],
    traffic: [],
  },
  validate,
})(TellUsAboutYou);

const LinkIcon = () => (
  <svg fill="none" height="17" viewBox="0 0 18 17" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill"
      d="M4.74722 12.664C3.80976 11.7265 3.80976 10.2024 4.74722 9.26498L6.94011 7.07208L5.89849 6.03046L3.70559 8.22335C2.1925 9.73645 2.1925 12.1925 3.70559 13.7056C5.21869 15.2187 7.67473 15.2187 9.18783 13.7056L11.3807 11.5127L10.3391 10.4711L8.14621 12.664C7.20874 13.6014 5.68468 13.6014 4.74722 12.664ZM7.54316 10.9645L11.9289 6.57868L10.8325 5.48224L6.44671 9.86802L7.54316 10.9645ZM9.18783 2.74112L6.99494 4.93401L8.03656 5.97564L10.2295 3.78274C11.1669 2.84528 12.691 2.84528 13.6284 3.78274C14.5659 4.7202 14.5659 6.24427 13.6284 7.18173L11.4355 9.37462L12.4772 10.4162L14.6701 8.22335C16.1832 6.71026 16.1832 4.25421 14.6701 2.74112C13.157 1.22802 10.7009 1.22802 9.18783 2.74112Z"
      fill="white"
    />
  </svg>
);

export default LinkIcon;
